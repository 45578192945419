import React, { useEffect } from 'react';
import { useHistory, useLocation, RouteComponentProps } from 'react-router';

// store
import { useStoreState, useStoreActions, useStoreDispatch } from '../../hooks'

// mui
import { Box, Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// css
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';

// url params
interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
}

export const DeviceConfig: React.FC<Props> = props => {

    let history = useHistory();
    let location = useLocation();

    const { deviceDetail, isLoading } = useStoreState(state => state.device);
    const { getDeviceById } = useStoreActions(state => state.device);

    useEffect(() => {
        console.log('UseEffect: getDeviceById');
        getDeviceById({ deviceId: props.match.params.id });
    }, []);


    return (
        <div>
            <div>LOCATION PATH: {location.pathname}</div>
            <div>LOCATION STATE: {location.key}</div>
            <div>PROPS ID: {props.match.params.id}</div>
            <div>Config: {deviceDetail?.id} {deviceDetail?.gaugeValues}</div>
        </div>)
}

export default DeviceConfig;