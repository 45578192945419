import React, { useEffect, useState } from 'react';

// store

// mui
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper,  Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

// components
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { SoftwareWatcherDto, UserDto } from '../../service/dataContract';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    info: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 360,
    },
    srcList: {
        width: '100%',
        overflow: 'auto',
        height: 360,
        margin: theme.spacing(0),
    },
    dstList: {
        width: '100%',
        height: 360,
        overflow: 'auto',
        marginLeft: theme.spacing(1),
    },
}));

interface Props {
    title: string,
    sourceState: UserDto[],
    currentState?: UserDto[],    
    selectionChanged?: (selection: UserDto[]) => void;
}

interface WatcherState extends UserDto {
    orderNo: number,
}

const buildInitialSet = (source: UserDto[]) => {
    return [...new Set(source)].map<WatcherState>((user, i) => {
        return { ...user, orderNo: i }
    });
}

export const SoftwareWatchersTransferList: React.FC<Props> = props => {

    // styles
    const classes = useStyles();

    const [srcWatchers, setSrcWatchers] = useState<WatcherState[]>([]);
    const [dstWatchers, setDstWatchers] = useState<WatcherState[]>([]);
    const [changed, setChanged] = useState(false);


    const hasAnyWatchers = () => {
        const count = srcWatchers.length + dstWatchers.length;
        return count > 0;
    }

    useEffect(() => {
        if (changed) {
            setChanged(false);
            props.selectionChanged?.(dstWatchers);
        }
    }, [changed]);

    useEffect(() => {

        const findById = (data: WatcherState[] | UserDto[], value: WatcherState) =>
            data.find(self => self.id === value.id);

        const fullSet = buildInitialSet(props.sourceState);
        const currentSet = props.currentState?? [];

        const dst = fullSet
            .filter(item => findById(currentSet, item))
            .map(src => {
                return {...src,}
            });

        const src = fullSet.filter(item => !findById(currentSet, item));

        setSrcWatchers(src);
        setDstWatchers(dst);
        setChanged(false);

    }, [props.sourceState, props.currentState]);

    const include = (value: WatcherState) => () => {
        setSrcWatchers(srcWatchers.filter(c => c.id !== value.id));
        setDstWatchers([...dstWatchers, value]);
        setChanged(true);
    };

    const exclude = (value: WatcherState) => () => {
        setDstWatchers(dstWatchers.filter(c => c.id !== value.id));
        setSrcWatchers([...srcWatchers, value]);
        setChanged(true);
    };

    const renderList = (items: WatcherState[], isSource: boolean, showSettings: boolean, className: string) => (
        <Paper variant='outlined' className={className}>
            <List dense>
                {items.sort((a, b) => a.orderNo > b.orderNo ? +1 : -1).map((value: WatcherState) => (
                    <ListItem key={value.id} button>
                        <ListItemIcon>
                            {isSource && (
                                <IconButton color='primary' onClick={include(value)}>
                                    <AddIcon />
                                </IconButton>
                            )}

                            {!isSource && (
                                <IconButton color='secondary' onClick={exclude(value)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </ListItemIcon>

                        <ListItemText primary={`${value.email}`} />

                    </ListItem>
                ))}
                <ListItem />
            </List>
        </Paper>
    );


    return (
        <div className={classes.root}>

            <Typography
                variant='subtitle2'
                component='span'
                align='left'>{props.title}</Typography>

            {hasAnyWatchers() && <Box display="flex" flexDirection="row">
                {renderList(srcWatchers, true, false, classes.srcList)}
                {renderList(dstWatchers, false, true, classes.dstList)}
            </Box>}

            {!hasAnyWatchers() &&
                <Paper className={classes.info}>
                    <Typography variant='body1' color='textSecondary'>Loading ...</Typography>
                </Paper>
            }

        </div >
    );
}

export default SoftwareWatchersTransferList;

