import { Thunk, thunk } from 'easy-peasy';
import { StoreContext } from './';
import store, { Injections } from '../store';

// dto
import { CreateServiceOperationDto, ServiceOperationDto, DeviceDto, NoteDto, AttachedFileDto } from '../service/dataContract';

// utils
import { Progress } from '../service/httpClient';
import { base64toBlob } from '../utils/fileUtils'

// device store interface
export interface ServiceOperationState {

    // create service record
    addServiceOperation: Thunk<ServiceOperationState, { deviceId: string, service: CreateServiceOperationDto }, Injections, StoreContext>,

    // update existing service record
    updateServiceOperation: Thunk<ServiceOperationState, { deviceId: string, serviceId: string, service: CreateServiceOperationDto }, Injections, StoreContext>,

    // delete existing service record
    deleteServiceOperation: Thunk<ServiceOperationState, { deviceId: string, serviceId: string }, Injections, StoreContext>,

    // create (uploads) a new service file
    uploadServiceFile: Thunk<ServiceOperationState, { deviceId: string, serviceId: string, formData: FormData, uploadProgress: Progress }, Injections, StoreContext>,

    // downloads an existing service file
    downloadServiceFile: Thunk<ServiceOperationState, { deviceId: string, serviceId: string, fileId: string, downloadProgress: Progress }, Injections, StoreContext>,

    // delete an existing service file
    deleteServiceFile: Thunk<ServiceOperationState, { deviceId: string, serviceId: string, fileId: string }, Injections, StoreContext>,
};

const serviceState: ServiceOperationState = {

    addServiceOperation: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;
        getStoreActions().device.setLoading(true);

        return await serviceClient.create(payload)
            .then(sop => {
                getStoreActions().device.storeServiceOperation({ mode: 'insert', serviceOp: sop })
                getStoreActions().audit.notify({ severity: 'info', message: `Service operation has been sucessfully created.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create service operation: ${payload.service.subject}.`, payload: err })
            })
            .finally(() => {
                getStoreActions().device.setLoading(false);
            });
    }),

    updateServiceOperation: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;
        getStoreActions().device.setLoading(true);

        return await serviceClient.update(payload)
            .then(sop => {
                getStoreActions().device.storeServiceOperation({ mode: 'update', serviceOp: sop })
                getStoreActions().audit.notify({ severity: 'info', message: `Service operation has been sucessfully updated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update service operation: ${payload.service.subject}.`, payload: err })
            })
            .finally(() => {
                getStoreActions().device.setLoading(false);
            });
    }),

    deleteServiceOperation: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { serviceClient } = injections;
        getStoreActions().device.setLoading(true);

        return await serviceClient.delete(payload)
            .then(_ => {
                getStoreActions().device.storeServiceOperation({ mode: 'delete', serviceOpId: payload.serviceId })
                getStoreActions().audit.notify({ severity: 'info', message: `Service operation has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete service operation.`, payload: err })
            })
            .finally(() => {
                getStoreActions().device.setLoading(false);
            });
    }),

    uploadServiceFile: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;

        await serviceClient.uploadFile(payload)
            .then(file => {
                getStoreActions().device.storeServiceFile({ mode: "insert", serviceOpId: payload.serviceId, file: file });
                getStoreActions().audit.notify({ severity: 'info', message: `Service file has been sucessfully uploaded.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not uplaod service file.`, payload: err });
            })
    }),

    downloadServiceFile: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;

        await serviceClient.downloadFile(payload)
            .then(file => {
                // get data (extract file name, convert blob from base64 to utf8)
                const fname = file.fileName ?? 'sw_downloaded_file.unknown';
                const blob = base64toBlob(file.fileContent ?? '');

                // create download link
                let tempLink = document.createElement('a');
                tempLink.href = window.URL.createObjectURL(blob);
                tempLink.setAttribute('download', fname);
                tempLink.click();

                getStoreActions().audit.notify({ severity: 'info', message: `Downloading service file has been initiated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Download service file error.`, payload: err });
            });
    }),

    deleteServiceFile: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;

        return await serviceClient.deleteFile(payload)
            .then(() => {
                getStoreActions().device.storeServiceFile({ mode: "delete", serviceOpId: payload.serviceId, fileId: payload.fileId, });
                getStoreActions().audit.notify({ severity: 'info', message: `Service file has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete service file.`, payload: err });
            });
    }),
}

export default serviceState;
