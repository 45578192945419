import { useEffect, useState } from "react";

// validation result for material table
export type ValidationResult = { isValid: boolean; helperText?: string };

// validation fields (partial of T)
export type ValidationFields<T> = { [P in keyof T]?: ValidationResult };

// validation rules fields (partial of T)
export type ValidationRule<T> = ((value: T[keyof T], originalState: T) => ValidationResult)

// set of validation rules
export type ValidationRules<T> = { [P in keyof T]?: ValidationRule<T> };

// validation hook
export const useStateValidator = <T extends {}>(rules: ValidationRules<T>, initialState: T) => {

    // set initial state
    const { error, fields } = validateState(rules, initialState);
    const [state, setState] = useState(initialState);
    const [hasError, setHasError] = useState(error);
    const [validations, setValidations] = useState(fields);

    useEffect(() => {
        const { error, fields } = validateState(rules, state);
        setValidations(fields);
        setHasError(error);
    }, [state])

    return [hasError, validations, state, setState] as const;
}

// validates state against rules
function validateState<T>(rules: ValidationRules<T>, state: T) {

    let fields: ValidationFields<T> = {}
    let error = false;
    for (let [prop, rule] of Object.entries(rules)) {
        if (isValidationRule<T>(rule)) {
            const vr = rule(state[prop as keyof T], state);
            error = error || !vr.isValid;
            fields[prop as keyof T] = vr;
        }
    }

    return { error, fields };
}

// validation rule guard
function isValidationRule<T>(vr: any): vr is ValidationRule<T> {
    return (vr as ValidationRule<T>) !== undefined
}
