import React, { useEffect, useRef } from 'react';

// mui
import { Box, Tab, Tabs, Typography, Checkbox, useTheme, Chip } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';

import { LicenseListCard } from '../software/LicenseListCard';
import { SoftwareListCard } from '../software/SoftwareListCard';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    detailPanel: {
        background: '#EEEE',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'left',
        minHeight: 100,

    },
    paper: {
        flexGrow: 1,
        margin: theme.spacing(0.5, 0, 2, 0.5),
    },
    row: {
        display: 'flex',
    },
    module: {
        margin: theme.spacing(0.0, 0.0, 0.0, 1.0),
    }
}));

interface Props {
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export const Software: React.FC<Props> = props => {

    // styles
    const classes = useStyles();
    const theme = useTheme();

    // state
    const [tabPageIndex, setTabPageIndex] = React.useState(0);

    // tab page handlers

    const handleTabPageChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabPageIndex(newValue);
    };

    const handleTabPageChangeIndex = (index: number) => {
        setTabPageIndex(index);
    };

    return (
        <div>
            <Tabs
                value={tabPageIndex}
                onChange={handleTabPageChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
            >
                <Tab label="License Management" {...a11yProps(0)} />
                <Tab label="Software Management" {...a11yProps(1)} />
            </Tabs>

            <SwipeableViews
                index={tabPageIndex}
                onChangeIndex={handleTabPageChangeIndex}
            >
                <TabPanel value={tabPageIndex} index={0} dir={theme.direction}>
                    <LicenseListCard filtering={true} />
                </TabPanel>

                <TabPanel value={tabPageIndex} index={1} dir={theme.direction}>
                    <SoftwareListCard />
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}

export default Software;

