import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// material ui
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blue, red, green } from '@material-ui/core/colors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// components
import { Home } from './pages/Home';
import { Software } from './pages/Software';
import { CreateLicenseCard } from './software/CreateLicenseCard';
import { DeviceDetail } from './device/DeviceDetail';
import { DeviceService } from './device/DeviceService';
import { ComponentAdmin } from './ComponentAdmin';
import { Users } from './pages/Users';
import { DeviceConfig } from './device/DeviceConfig';
import { DeviceCreate } from './device/DeviceCreate';
import { DeviceClone } from './device/DeviceClone';
import { VgnImport } from './pages/VgnImport';
import { Login } from './pages/Login';
import { MainLayout } from './pages/MainLayout';
import { NotFound } from './pages/NotFound';
import { Test } from './pages/Test';
import PrivateRoute from './PrivateRoute';

// routes
import routes from '../routes';
import Admin from './pages/Admin';

function App() {

    // get theme
    const theme = createMuiTheme({
        typography:
        {
            fontSize: 14,
            fontFamily: 'roboto',
        },
        palette: {
            background: {
                default: '#FAFAFA',
            },
            primary: {
                main: process.env.NODE_ENV === 'development' ? green[500] : blue[500]
            },
            error: {
                main: red[500]
            }
        },
    });

    return (
        
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <MainLayout>
                        <Switch>
                            <Route path={routes.login} exact component={Login} />
                            <PrivateRoute path={routes.home} component={Home} />
                            <PrivateRoute path={routes.deleted} exact component={Home} />
                            <PrivateRoute path={routes.components} component={ComponentAdmin} />
                            <PrivateRoute path={routes.users} component={Users} />
                            <PrivateRoute path={routes.softwate} exact component={Software} />
                            <PrivateRoute path={routes.licenseCreate} exact component={CreateLicenseCard} />
                            <PrivateRoute path={routes.deviceCreate} component={DeviceCreate} />
                            <PrivateRoute path={routes.deviceDetail.path} exact component={DeviceDetail} />
                            <PrivateRoute path={routes.deviceService.path} exact component={DeviceService} />
                            <PrivateRoute path={routes.deviceClone.path} exact component={DeviceClone} />
                            <PrivateRoute path={routes.deviceCfg.path} exact component={DeviceConfig} />
                            <PrivateRoute path={routes.vgnImport} component={VgnImport} />
                            <PrivateRoute path={routes.admin} component={() => (<Admin />)} />
                            <PrivateRoute path={routes.test} component={() => (<Test></Test>)} />
                            <PrivateRoute path='/' exact component={Home}></PrivateRoute>
                            <Route path='/' component={NotFound}></Route>
                        </Switch>
                    </MainLayout>
                </BrowserRouter>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        
    )
}

export default App;