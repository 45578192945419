export function isType<T>(obj: any): obj is T {
    return (obj as T) !== undefined
}

export function isNullOrWhitespace(value: any) : boolean {
    
    if (value === null) {
        return true;
    }
    else if (value.trim().length === 0) {
        return true;
    }

    return false;
}
