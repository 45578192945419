import { fileIOTimeout, http } from './httpClient';
import { DeviceFileDto, DeviceFileBriefDto } from './dataContract'

const baseRoute = (serialNumber: string) => `/devices/${serialNumber}/files`;

const routes = {
    fetch: (serialNumber: string) => `${baseRoute(serialNumber)}`,
    uploadFile: (serialNumber: string) => `${baseRoute(serialNumber)}`,
    downloadFile: (serialNumber: string, fileId: string) => `${baseRoute(serialNumber)}/${fileId}`,
    deleteFile: (serialNumber: string, fileId: string) => `${baseRoute(serialNumber)}/${fileId}`,
}

export interface DeviceFileClient {
    fetch(payload: { serialNumber: string }): Promise<DeviceFileBriefDto[]>,
    uploadFile(payload: { serialNumber: string, formData: FormData, uploadProgress?: (event: ProgressEvent) => void }): Promise<DeviceFileBriefDto>,
    downloadFile(payload: { serialNumber: string, fileId: string, downloadProgress?: (event: ProgressEvent) => void }): Promise<DeviceFileDto>,
    deleteFile(payload: { serialNumber: string, fileId: string }): Promise<boolean>,
}

const deviceFileClient: DeviceFileClient = {

    fetch: (payload) => http.get(routes.fetch(payload.serialNumber)),

    uploadFile: (payload) => http.post(
        routes.uploadFile(payload.serialNumber),
        payload.formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: payload.uploadProgress,
            timeout: fileIOTimeout,
        }),

    downloadFile: (payload) => http.get(
        routes.downloadFile(payload.serialNumber, payload.fileId),
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: payload.downloadProgress,
            timeout: fileIOTimeout,
        }),

    deleteFile: (payload) => http.delete(routes.deleteFile(payload.serialNumber, payload.fileId)),

}

export default deviceFileClient;