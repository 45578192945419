import React, { useState } from 'react';

// mui
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Badge, Button, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

// dtos
import { useStoreActions, useStoreState } from '../hooks';
import AttachedFileList from './AttachedFileList';
import { Progress } from '../service/httpClient';
import { AttachedFileBriefDto } from '../service/dataContract';


// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
        },
        contentBlock: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            margin: theme.spacing(2),
        },
        margin: {
            margin: theme.spacing(1),
        },
        marginLeft: {
            marginLeft: theme.spacing(1),
        },
        actions: {
            marginRight: theme.spacing(3),
        },
        heading: {
            flexBasis: '33%',
            flexShrink: 0,
        },
        headerContent: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 0,
            marginLeft: theme.spacing(2),
        },

    }),
);

interface HeaderProps {
    attachedFiles: AttachedFileBriefDto[],
    downloadCallback?: (fileId: string, downloadProgress: Progress) => void,
}

interface Props { }

export const FilesCard: React.FC<Props> = (props) => {

    const classes = useStyles();

    const { uploadFile, downloadFile, deleteFile } = useStoreActions(state => state.device);
    const { deviceDetail } = useStoreState(state => state.device);

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleDetailChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleUploadFile = (data: FormData, uploadProgress: Progress) => {
        uploadFile({
            deviceId: deviceDetail?.id ?? '',
            formData: data,
            uploadProgress: uploadProgress
        });
    }

    const handleDownloadFile = (fileId: string, downloadProgress: Progress) => {
        downloadFile({
            deviceId: deviceDetail?.id ?? '',
            fileId: fileId,
            downloadProgress: downloadProgress,
        });
    }

    const handleDeleteFile = (fileId: string) => {
        deleteFile({
            deviceId: deviceDetail?.id ?? '',
            fileId: fileId,
        });
    }

    return (
        <div className={classes.root}>

            <Accordion
                expanded={expanded === 'cardpanel'}
                onChange={handleDetailChange('cardpanel')}
                TransitionProps={{ unmountOnExit: true }}>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="cardpanel-header">
                    <Typography
                        variant='h6'
                        className={classes.heading}>
                        Attachments:
                    </Typography>

                    <Badge
                        color='secondary'
                        variant="standard"
                        badgeContent={deviceDetail?.attachedFiles?.length}  >
                        <AttachmentIcon color='primary' />
                    </Badge>

                </AccordionSummary>

                <AccordionDetails>

                    <div className={classes.content}>
                        <AttachedFileList
                            readonly={false}
                            attachedFiles={deviceDetail?.attachedFiles ?? []}
                            uploadCallback={handleUploadFile}
                            downloadCallback={handleDownloadFile}
                            deleteCallback={handleDeleteFile}
                        >
                        </AttachedFileList>
                    </div>

                </AccordionDetails>

                <AccordionActions>
                </AccordionActions>

            </Accordion>

        </div >
    )
}

export default FilesCard;