import React, { useEffect, useState } from 'react';

// mui
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';

// dtos
import { useStoreActions, useStoreState } from '../../hooks';
import { countries, Country, resolveCountry } from '../../model/codelist';
import { CreateContactDto } from '../../service/dataContract';
import { ValidationRules } from '../../utils/validations';


// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
        },
        contentBlock: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            margin: theme.spacing(2),
        },
        margin: {
            margin: theme.spacing(1),
        },
        actions: {
            marginRight: theme.spacing(3),
        },
        heading: {
            flexBasis: '33%',
            flexShrink: 0,
        },
    }),
);

export interface Props {
    readonly: boolean,
}

interface State extends CreateContactDto {
}

// setup validation rules
const rules: ValidationRules<State> = {
}

export const ContactCard: React.FC<Props> = (props) => {

    const classes = useStyles();

    const { updateDeviceContact } = useStoreActions(state => state.device);
    const { deviceDetail } = useStoreState(state => state.device);

    const [expanded, setExpanded] = useState<string | false>(false);
    const [changed, setChanged] = useState(false);
    const [values, setValues] = useState<State>({ ...deviceDetail?.contact });
    const [country, setCountry] = useState<Country | null>(null);

    useEffect(() => {
        setValues({ ...deviceDetail?.contact });
        setCountry(countries.find(country => country.code === deviceDetail?.contact?.countryCode) ?? null);        
    }, [deviceDetail?.contact])


    // accordition expander
    const handleDetailChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    // data changed
    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
        setChanged(true);
    };

    const handleCountryChange = (country: Country | null) => {
        
        setValues({ ...values, countryCode: country?.code });
        setCountry(country);
        setChanged(true);
    };

    // contact save 
    const handleContactSave = () => {
        updateDeviceContact({
            contactId: deviceDetail?.contact?.id ?? '',
            contact: { ...values },
        });
        setChanged(false);
    }


    function buildAdressLabel() {
        const city = values.city;
        const countryName = country?.name;
        const email = values.email;

        return [countryName, city, email].filter(str => str).join(', ');
    }

    return (
        <div className={classes.root}>

            <Accordion expanded={expanded === 'cardpanel'} onChange={handleDetailChange('cardpanel')}>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="cardpanel-header">
                    <Typography variant='h6' className={classes.heading}>
                        Owner: {values.companyName}</Typography>
                    <Typography variant='subtitle1'>
                        Address: {buildAdressLabel()}
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>

                    <div className={classes.content}>

                        <div className={classes.contentBlock}>
                            <TextField
                                label="Company"
                                id="contact-company"
                                value={values.companyName || ''}
                                onChange={handleInputChange('companyName')}
                                className={classes.margin}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: props.readonly }}
                            />

                            <TextField
                                label="Contact Person"
                                id="contact-person"
                                value={values.personName || ''}
                                onChange={handleInputChange('personName')}
                                className={classes.margin}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: props.readonly }}
                            />

                            <TextField
                                label="Phone"
                                id="contact-phone"
                                value={values.phoneNumber || ''}
                                onChange={handleInputChange('phoneNumber')}
                                className={classes.margin}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: props.readonly }}
                            />

                            <TextField
                                label="Email"
                                id="contact-email"
                                value={values.email || ''}
                                onChange={handleInputChange('email')}
                                className={classes.margin}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: props.readonly }}
                            />
                        </div>


                        <div className={classes.contentBlock}>
                            <TextField
                                label="City"
                                id="contact-city"
                                value={values.city || ''}
                                onChange={handleInputChange('city')}
                                className={classes.margin}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: props.readonly }}
                            />

                            <TextField
                                label="Street"
                                id="addr-stret"
                                value={values.street || ''}
                                onChange={handleInputChange('street')}
                                className={classes.margin}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: props.readonly }}
                            />

                            <Autocomplete
                                id='country-name'
                                className={classes.margin}
                                options={countries}
                                value={country}
                                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label='Country'
                                        variant='standard' />}
                                onChange={(_, selectedCountry) => handleCountryChange(selectedCountry)}
                            />

                        </div>
                    </div>



                </AccordionDetails>

                {!props.readonly && (
                    <AccordionActions>
                        <div className={classes.actions}>
                            <Button
                                autoFocus
                                color='primary'
                                startIcon={<DoneIcon />}
                                disabled={!changed}
                                onClick={() => { handleContactSave() }}>
                                Save
                                </Button>
                        </div>
                    </AccordionActions>
                )}

            </Accordion>


        </div>
    )
}

export default ContactCard;