import { http, fileIOTimeout } from './httpClient';
import { LicenseQueryDto, LicenseDto, LicenseBriefDto } from './dataContract'
import { buildQuery } from '../utils/query'

const baseRoute = '/licenses';

const routes = {
    fetch: (query: LicenseQueryDto) => `${baseRoute}/?${buildQuery(query, 'Query.')}`,
    get: (licenseId: string) => `${baseRoute}/${licenseId}`,
    create: () => `${baseRoute}`,
    update: (licenseId: string) => `${baseRoute}/${licenseId}`,
    delete: (licenseId: string) => `${baseRoute}/${licenseId}`,
}

export interface LicenseClient {
    fetch(payload: { query: LicenseQueryDto }): Promise<LicenseBriefDto[]>,
    get(payload: { licenseId: string, progress?: (event: ProgressEvent) => void }): Promise<LicenseDto>,
    create(payload: { formData: FormData, progress?: (event: ProgressEvent) => void }): Promise<LicenseBriefDto>,
    update(payload: { licenseId: string, formData: FormData, progress?: (event: ProgressEvent) => void }): Promise<LicenseBriefDto>,    
    delete(payload: { licenseId: string }): Promise<boolean>,
}

const licenseClient: LicenseClient = {

    fetch: (payload) => http.get(routes.fetch(payload.query)),

    get: (payload) => http.get(
        routes.get(payload.licenseId),
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onDownloadProgress: payload.progress,
            timeout: fileIOTimeout,
        }),

    create: (payload) => http.post(
        routes.create(),
        payload.formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: payload.progress,
            timeout: fileIOTimeout,
        }),

    update: (payload) => http.put(
        routes.update(payload.licenseId),
        payload.formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: payload.progress,
            timeout: fileIOTimeout,
        }),

    delete: (payload) => http.delete(routes.delete(payload.licenseId)),
}

export default licenseClient;