import { http } from './httpClient';
import { ContactDto, CreateContactDto } from './dataContract'

const baseRoute = '/contacts';

const routes = {
    create: baseRoute,
    get: (contactId: string) => `${baseRoute}/${contactId}`,
    update: (contactId: string) => `${baseRoute}/${contactId}`,
    delete: (contactId: string) => `${baseRoute}/${contactId}`,
}

export interface ContactClient {
    get(payload: { contactId: string }): Promise<ContactDto>,
    create(payload: { contact: CreateContactDto }): Promise<ContactDto>,
    update(payload: { contactId: string, contact: CreateContactDto }): Promise<ContactDto>,
    delete(payload: { contactId: string }): Promise<boolean>,
}

const contactClient: ContactClient = {
    get: (payload) => http.get(routes.get(payload.contactId)),
    create: (payload) => http.post(routes.create, payload.contact),
    update: (payload) => http.put(routes.update(payload.contactId), payload.contact),
    delete: (payload) => http.delete(routes.delete(payload.contactId)),
}

export default contactClient;