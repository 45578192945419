import React, { useEffect, useState } from 'react';

// mui
import { CardContent, Card, CardActions, List, ListItem, TextField, IconButton, CardHeader, Avatar, Tooltip, Box } from '@material-ui/core';
import MaterialTable, { Column, MTableAction } from 'material-table';
import { Theme, makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

// store
import { useStoreState, useStoreActions, useStoreDispatch } from '../../hooks'
import { CreateSoftwareModuleDto, SoftwareBriefDto, SoftwareModuleDto } from '../../service/dataContract';

import { validateRx, semverRxPattern, validateInput } from '../../utils/validationUtils';
import { useStateValidator, ValidationResult } from '../../utils/validationHook';

import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    }
}));

interface Props {
    software?: SoftwareBriefDto;
    className?: string;
}

interface ModuleStateDto extends SoftwareModuleDto {
    state: 'created' | 'new' | 'changed',
    validation: ValidationResult,
}

const columns: Column<SoftwareModuleDto>[] = [
    {
        title: 'Name',
        field: 'name',
        validate: rowData => validateInput(rowData.name, 2),
    },
    {
        title: 'Subsystem',
        field: 'subsystem',   
    },
    {
        title: 'Display order',
        field: 'order',   
        sorting: true,
        defaultSort: 'asc'
    },
    {
        title: 'Group Description',
        field: 'groupDescription',   
    },
];

const ModuleListEditor: React.FC<Props> = props => {

    const classes = useStyles();

    const { addSoftwareModule, updateSoftwareModule, deleteSoftwareModule} = useStoreActions(state => state.software);

    const propsToState = (props: Props) => (props.software?.softwareModules?? []).map<ModuleStateDto>(module => {
        return {
            ...module,
            state: 'created',
            validation: { isValid: true }
        }
    });

    const [state, setState] = useState<ModuleStateDto[]>(propsToState(props));

    useEffect(() => {
        setState(propsToState(props));
    }, [props.software]);

  
    const handleModuleCreate = (newData: CreateSoftwareModuleDto): Promise<void> =>
        new Promise((resolve, reject) => {
            addSoftwareModule({ softwareId: props.software?.id?? '',  module: newData});
            resolve();
        });

    const handleModuleUpdate = (newData: SoftwareModuleDto, oldData?: SoftwareModuleDto): Promise<void> =>
        new Promise((resolve) => {
            updateSoftwareModule({ module: newData });
            resolve();
        });

    const handleModuleDelete = (oldData: SoftwareModuleDto): Promise<void> =>
        new Promise((resolve) => {
            deleteSoftwareModule({ module: oldData });
            resolve();
        });


    return (

        <Card className={props.className}>

            <CardHeader
                avatar={
                    <Avatar>M</Avatar>
                }               
                title={`Manage software modules for ${props.software?.name}`}
                subheader={'List of available software modules.'}
            />

            <CardContent>

                <MaterialTable
                    title="Modules"
                    isLoading={false}
                    columns={columns}
                    data={state}
                    options={{
                        addRowPosition: 'first',
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20],
                    }}

                    actions={[ ]}
                
                    editable={{

                        isDeletable: () => true,
                        isEditable: (rowData) => true,

                        onRowAdd: handleModuleCreate,
                        onRowUpdate: handleModuleUpdate,
                        onRowDelete: handleModuleDelete,
                    }}
                />              

            </CardContent>

        </Card >
    );
}



export default ModuleListEditor;