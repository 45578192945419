// routes definitions


type Route = {
    path: string,
    fomatPath(params: string[] | string | undefined): string
}

export interface Routes {
    home: Route,
    login: Route,
    components: Route,
    users: Route,
    test: Route,
    software: Route,
    deviceCreate: Route,
    deviceClone: Route,
    deviceService: Route,
    deviceCfg: Route,
    deviceDetail: Route,
}

const routes = {
    home: '/home',
    deleted: '/home/deleted',
    login: '/login',
    components: '/components',
    vgnImport: '/vgnimport',
    users: '/users',
    test: '/test',
    admin: '/superadmin',
    softwate: '/software',
    licenseCreate: '/software/license/createwizard',
    deviceCreate: '/device/create',
    deviceClone: {
        path: '/device/clone/:id',
        formatPath: (id: string) => `/device/clone/${id}`
    },
    deviceService: {
        path: '/device/service/:id',
        formatPath: (id: string) => `/device/service/${id}`
    },
    deviceCfg: {
        path: '/device/cfg/:id',
        formatPath: (id: string) => `/device/cfg/${id}`
    },
    deviceDetail: {
        path: '/device/detail/:id',
        formatPath: (id: string) => `/device/detail/${id}`
    },
}

export default routes;