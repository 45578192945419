import { http } from './httpClient';
import { SoftwareDto, SoftwareBriefDto, SoftwareQueryDto, CreateSoftwareDto, CreateSoftwareVersionDto, SoftwareVersionDto, CreateSoftwareModuleDto, SoftwareModuleDto, UserDto } from './dataContract'
import { buildQuery } from '../utils/query'

const baseRoute = '/software';

const routes = {
    fetch: (query: SoftwareQueryDto) => `${baseRoute}/?${buildQuery(query, 'Query.')}`,
    get: (softwareId: string) => `${baseRoute}/${softwareId}`,
    create: `${baseRoute}`,
    update: (softwareId: string) => `${baseRoute}/${softwareId}`,
    delete: (softwareId: string) => `${baseRoute}/${softwareId}`,

    createVersion: (softwareId: string) => `${baseRoute}/${softwareId}/versions`,
    updateVersion: (versionId: string) => `${baseRoute}/versions/${versionId}`,
    deleteVersion: (versionId: string) => `${baseRoute}/versions/${versionId}`,

    createModule: (softwareId: string) => `${baseRoute}/${softwareId}/modules`,
    updateModule: (moduleId: string) => `${baseRoute}/modules/${moduleId}`,
    deleteModule: (moduleId: string) => `${baseRoute}/modules/${moduleId}`,

    createWatcher: (softwareId: string) => `${baseRoute}/${softwareId}/watchers`,
    updateWatchers: (softwareId: string) => `${baseRoute}/${softwareId}/watchers`,
    deleteWatcher: (userId: string) => `${baseRoute}/watchers/${userId}`,
    
}

export interface SoftwareClient {

    fetch(payload: { query: SoftwareQueryDto }): Promise<SoftwareBriefDto[]>,
    get(payload: { softwareId: string }): Promise<SoftwareDto>,
    create(payload: { software: CreateSoftwareDto }): Promise<SoftwareDto>,
    update(payload: { softwareId: string, software: CreateSoftwareDto }): Promise<SoftwareDto>,
    delete(payload: { softwareId: string }): Promise<boolean>,

    createVersion(payload: { softwareId: string, version: CreateSoftwareVersionDto }): Promise<SoftwareVersionDto>,
    updateVersion(payload: { versionId: string, version: CreateSoftwareVersionDto }): Promise<SoftwareVersionDto>,
    deleteVersion(payload: { versionId: string }): Promise<boolean>,

    createModule(payload: { softwareId: string, module: CreateSoftwareModuleDto }): Promise<SoftwareModuleDto>,
    updateModule(payload: { moduleId: string, module: CreateSoftwareModuleDto }): Promise<SoftwareModuleDto>,
    deleteModule(payload: { moduleId: string }): Promise<boolean>,

    createWatcher(payload: { softwareId: string, watcher: UserDto }): Promise<UserDto>,
    deleteWatcher(payload: { userId: string }): Promise<boolean>,
    updateWatchers(payload: { softwareId: string, watchers: UserDto[] }): Promise<SoftwareBriefDto>,
}

const softwareClient: SoftwareClient = {

    fetch: (payload) => http.get(routes.fetch(payload.query)),
    get: (payload) => http.get(routes.get(payload.softwareId)),
    create: (payload) => http.post(routes.create, payload.software),
    update: (payload) => http.put(routes.update(payload.softwareId), payload.software),
    delete: (payload) => http.delete(routes.delete(payload.softwareId)),

    createVersion: (payload) => http.post(routes.createVersion(payload.softwareId), payload.version),
    updateVersion: (payload) => http.put(routes.updateVersion(payload.versionId), payload.version),
    deleteVersion: (payload) => http.delete(routes.deleteVersion(payload.versionId)),

    createModule: (payload) => http.post(routes.createModule(payload.softwareId), payload.module),
    updateModule: (payload) => http.put(routes.updateModule(payload.moduleId), payload.module),
    deleteModule: (payload) => http.delete(routes.deleteModule(payload.moduleId)),

    createWatcher: (payload) => http.post(routes.createWatcher(payload.softwareId), payload.watcher),
    deleteWatcher: (payload) => http.delete(routes.deleteWatcher(payload.userId)),
    updateWatchers: (payload) => http.put(routes.updateWatchers(payload.softwareId), payload.watchers),

}

export default softwareClient;