import React, { useEffect, useState } from 'react';

// mui
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Avatar, Box, Button, Chip, Divider, List, ListItem, TextField, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// components
import ComponentParams from './ComponentParams';
import DeviceDetailPrint from './device/DeviceDetailPrint';

// store
import { useStoreActions, useStoreState } from '../hooks';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            background: '#EEEE',
        },
        contentBlock: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginBottom: theme.spacing(2),
        },
        parameters: {
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'row',
        },
        paramMargin: {
            marginRight: theme.spacing(1),
        },

        textField: {
            marginLeft: theme.spacing(1),
        },
        margin: {
            margin: theme.spacing(1),
        },
        actions: {
            marginRight: theme.spacing(3),
        },
        heading: {
            flexBasis: '33%',
            flexShrink: 0,
        },

    }),
);

export interface Props {
}

export const ComponentCard: React.FC<Props> = (props) => {

    const classes = useStyles();
    const [expanded, setExpanded] = useState<string | false>(false);

    const { fetchPrototypes } = useStoreActions(state => state.component);
    const { componentPrototypes } = useStoreState(state => state.component);
    const { notify } = useStoreActions(state => state.audit);

    useEffect(() => {

        const fetchData = async () => {
            try {
                await fetchPrototypes();
            } catch (err) {
                notify({severity: 'error', message: 'Error fetching componnets details'})
            }
        };
    
        fetchData();

    }, [fetchPrototypes]);

    // accordition expander
    const handleDetailChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>

            <Accordion
                expanded={expanded === 'cardpanel'}
                onChange={handleDetailChange('cardpanel')}
                TransitionProps={{ unmountOnExit: true }}>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="cardpanel-header">

                    <Typography variant='h6' className={classes.heading}>
                        Components:
                    </Typography>

                    <DeviceDetailPrint />

                </AccordionSummary>

                <AccordionDetails>

                    <div className={classes.content}>

                        <ComponentParams
                            title='Sensors'
                            category='Sensor'
                            componentPrototypes={componentPrototypes}
                            categoryNoteKey='sensorNote' />

                        <ComponentParams
                            title='Electronics'
                            category='Electronic'
                            componentPrototypes={componentPrototypes}
                            categoryNoteKey='electronicNote' />

                        <ComponentParams
                            title='Mechanics'
                            category='Mechanic'
                            componentPrototypes={componentPrototypes}
                            categoryNoteKey='mechanicNote' />

                        <ComponentParams
                            title='Software'
                            category='Software'
                            componentPrototypes={componentPrototypes}
                            categoryNoteKey='sotfwareNote' />
                    </div>

                </AccordionDetails>

            </Accordion>

        </div>
    )
}

export default ComponentCard;