import { Action, action, computed, Computed, Thunk, thunk, persist } from 'easy-peasy';
import { StoreContext } from './index';
import { Injections } from '../store';
import authStore from '../store/authStore';
import { AuthRequest, UserRoleKey, UserRoleValue } from '../service/dataContract';
import { userRoleLookup } from './codelist';

function isRole(key: any): key is UserRoleKey {
    return (key as UserRoleKey) !== undefined;
}

// auth store inteface
export interface AuthModel {

    isLogged: Computed<AuthModel, boolean>,
    userName: Computed<AuthModel, string | undefined>,
    fullName: Computed<AuthModel, string | undefined>,
    role: Computed<AuthModel, UserRoleValue>,
    isAdmin: Computed<AuthModel, boolean>,
    isSuperAdmin: Computed<AuthModel, boolean>,
    isKzv: Computed<AuthModel, boolean>,
    isExtern: Computed<AuthModel, boolean>,
    isOwner: Computed<AuthModel, (userName?: string) => boolean>,

    login: Thunk<AuthModel, { login: AuthRequest }, Injections, StoreContext>,
    logout: Thunk<AuthModel, void, Injections, StoreContext>,

    refreshAuthInfo: Action<AuthModel, void>,
};

const authModel: AuthModel = {

    isLogged: computed(() => authStore.token() !== undefined),
    userName: computed(() => authStore.userName()),
    fullName: computed(() => authStore.fullName()),
    
    role: computed(() => {
        let roleKeyValue = authStore.userRole() ?? '';
        return (isRole(roleKeyValue))
            ? userRoleLookup[roleKeyValue]
            : 'NotSet'
    }),

    isAdmin: computed(state => ['Admin'].includes(state.role)),
    isSuperAdmin: computed(state => ['Admin'].includes(state.role) && state.userName === "admin@admin.com"),
    isKzv: computed(state => ['KZV', 'Admin'].includes(state.role)),
    isExtern: computed(state => ['Extern'].includes(state.role)),
    isOwner: computed(state => (userName) => state.isAdmin || state.userName === userName),

    login: thunk(async (actions, payload, { injections, getStoreActions }): Promise<void> => {
        const { authClient } = injections;
        return await authClient.authenticate(payload)
            .then((response) => {
                authStore.store(response);
                getStoreActions().audit.notify({ severity: 'info', message: `User ${response.userName} has been sucesfully logged in.` })
            }).catch(err => {
                authStore.clear();
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to login user: ${payload.login.userName}, wrong user name or password.` })
            }).finally(() => {
                actions.refreshAuthInfo();
            });
    }),

    logout: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { authClient } = injections;
        return await authClient.logout()
            .then(() => {
                getStoreActions().audit.notify({ severity: 'info', message: `User has been sucesfully logged out.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to logout user.`, payload: err })
            }).finally(() => {
                authStore.clear();
                actions.refreshAuthInfo();
            });
    }),

    refreshAuthInfo: action((store, payload) => {
        store.isLogged = authStore.token() !== undefined;
        store.userName = authStore.userName();
        store.fullName = authStore.fullName();
        let roleKeyValue = authStore.userRole() ?? '';
        store.role = (isRole(roleKeyValue))
            ? userRoleLookup[roleKeyValue]
            : 'NotSet'
    }),
}

export default authModel;

