// react
import React, { useEffect, useState } from 'react';

// mui
import { Button } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MaterialTable, { Column, MTableAction } from 'material-table';
import LockOpenIcon from '@material-ui/icons/LockOpen';

// css
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';

// store
import { useStoreState, useStoreActions } from '../../hooks';

// dto
import { UserDto, CreateUserDto } from '../../service/dataContract';
import { userRoleLookup } from '../../model/codelist';

// components
import PasswordDialog from '../PasswordDialog'

// utils
import { validateEmail, validatePassword, validateInput, validateDefined} from '../../utils/validationUtils';
import { useValidator, ValidationRules } from '../../utils/validations';
import { formatDate } from '../../utils/datetime';

const useStyles = makeStyles((theme: Theme) => createStyles({
    search: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
    },
    table: {
        minWidth: 200,
    },
    content: {
        flexGrow: 0,
        padding: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(0.5),
    },
    toolbutton: {
        margin: theme.spacing(0.25),
    },

}));


interface PasswordDialogState {
    isOpen: boolean,
    userName: string,
    userId: string,
}

export interface Props {
}

export const Users: React.FC<Props> = props => {

    const classes = useStyles();
    const { users, isLoading } = useStoreState(state => state.user);
    const { role, isAdmin, isKzv, isOwner } = useStoreState(state => state.auth);
    const { fetchUsers, addUser, updateUser, deleteUser } = useStoreActions(state => state.user);

    const addActionRef = React.useRef<HTMLDivElement>(null);
    const [showPasswordColumn, setShowPasswordColumn] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const [passDlgState, setPassDlgState] = useState<PasswordDialogState>({
        isOpen: false,
        userName: '',
        userId: '',
    });

    const columns: Column<UserDto>[] = [
        {
            title: 'First Name',
            width: 150,
            field: 'firstName',
            validate: rowData => validateInput(rowData.firstName, 2),
        },
        {
            title: 'Last Name',
            width: 150,
            field: 'lastName',
            validate: rowData => validateInput(rowData.lastName, 2),
        },
        {
            title: 'email / (User Name)',
            field: 'email',
            initialEditValue:'@kzv.cz',
            editable: () => isAdmin,
            validate: rowData => validateEmail(rowData.email)
        },
        {
            title: 'Role',
            width: 150,
            field: 'role',
            lookup: userRoleLookup,
            editable: () => isAdmin,
            validate: rowData => validateDefined(rowData.role),
        },
        {
            title: 'Phone',
            field: 'phoneNumber',
            width: 150,
        },
        {
            title: 'Password',
            field: 'password',
            width: 150,
            hidden: !showPasswordColumn,
            validate: rowData => showPasswordColumn && validatePassword(rowData.password)
        },

        { title: 'Modified by', width: 150, field: 'lastModifiedBy', editable: 'never' },
        { title: 'Modified at', width: 150, field: 'lastModifiedAt', render: dto => formatDate(dto.lastModifiedAt), editable: 'never' },
        { title: 'Created by', width: 150, field: 'createdBy', editable: 'never', hidden: true },
        { title: 'Created at', width: 150, field: 'createdAt', type: 'date', editable: 'never', hidden: true },
    ];


    const handleUserCreate = (newData: UserDto): Promise<void> =>
        new Promise((resolve, reject) => {
            setShowPasswordColumn(false);
            addUser({
                user: {
                    firstName: newData.firstName,
                    lastName: newData.lastName,
                    phoneNumber: newData.phoneNumber,
                    email: newData.email,
                    role: newData.role,
                    password: newData.password,
                }
            })
            resolve();
        });

    const handleUserDelete = (oldData: UserDto): Promise<void> =>
        new Promise((resolve, reject) => {
            deleteUser({ userId: oldData.id })
            resolve();
        });

    const handleUserUpdate = (newData: UserDto, oldData?: UserDto): Promise<void> =>
        new Promise((resolve, reject) => {
            updateUser({
                userId: oldData?.id ?? '',
                user: {
                    firstName: newData.firstName,
                    lastName: newData.lastName,
                    phoneNumber: newData.phoneNumber,
                    email: isAdmin ? newData.email : undefined,
                    role: isAdmin ? newData.role : undefined,
                    password: isOwner(oldData?.email) ? newData.password : undefined,
                }
            })
            resolve();
        });

    const handleResetPassword = (dto: UserDto) => {
        console.log(dto);
        setPassDlgState({
            ...passDlgState,
            isOpen: true,
            userName: dto.email ?? '',
            userId: dto.id
        });
    }

    const handleSavePassword = (userId: string, newPassword: string) => {
        updateUser({
            userId: userId,
            user: {
                password: newPassword
            }
        })

        handleClosePasswordDialog();
    }

    const handleClosePasswordDialog = () => {
        setPassDlgState({ ...passDlgState, isOpen: false, });
    }

    return (
        <React.Fragment>

            <PasswordDialog
                {...passDlgState}
                onClose={handleClosePasswordDialog}
                onConfirm={handleSavePassword} />

            <MaterialTable
                title="User Administration"
                isLoading={isLoading}
                columns={columns}
                data={users}
                options={{
                    addRowPosition: 'first',
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 20],
                }}

                actions={[
                    {
                        icon: 'add_box',
                        tooltip: 'Add User',
                        isFreeAction: true,
                        disabled: !isAdmin,
                        onClick: (event) => {
                            if (addActionRef?.current) {
                                addActionRef.current.click();
                                setShowPasswordColumn(true);
                            }
                        }
                    },

                    dto => ({
                        icon: () => (<LockOpenIcon color={isOwner(dto.email) ? 'secondary' : 'disabled'} />),
                        tooltip: 'Reset Password',
                        onClick: () => handleResetPassword(dto),
                        disabled: !isOwner(dto.email),
                    }),
                ]}

                components={{
                    Action: (props) => {
                        if (typeof props.action === typeof Function || props.action.tooltip !== "Add") {
                            return <MTableAction {...props} />;
                        } else {
                            return <div ref={addActionRef} onClick={props.action.onClick} />;
                        }
                    }
                }}

                editable={{

                    isDeletable: () => isAdmin,
                    isEditable: (rowData) => isOwner(rowData.email),

                    onRowAdd: handleUserCreate,
                    onRowUpdate: handleUserUpdate,
                    onRowDelete: handleUserDelete,

                    onRowAddCancelled: () => setShowPasswordColumn(false),
                    onRowUpdateCancelled: () => setShowPasswordColumn(false),
                }}

            />
        </React.Fragment>
    );
}