// lookup / loose object
export interface LookupObject {
    [key: string]: any
}

// builds a lookup from array
export function buildLookup<T, K extends keyof T>(propKey: K, valueKey: K, data: T[]): LookupObject {

    let lookup: LookupObject = {};

    data.map(item => {
        lookup[`${item[propKey]}`] = item[valueKey];
    });

    return lookup;
}