// axios
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from 'axios';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';

// auth
import authClient from './authClient'
import authStore from '../store/authStore';
import routes from '../routes';

// progress callback
export type Progress = (progress: ProgressEvent) => void;

// file upload download timeout (in msec)
export const fileIOTimeout = 10 * 60 * 1000; // (1 minutes)


const resolveServiceUrl = (): string => {
    
    if (process.env.NODE_ENV === 'development') {
        console.assert(process.env.REACT_APP_DEV_URL, 'Undefined REACT_APP_DEV_URL');
        return process.env.REACT_APP_DEV_URL;
    }

    return 'https://kzv.gwh.cz/';
}

/* ******************************************************************** */
// axios client
const getAxiosClient = (): AxiosInstance => {
    
    const serviceURL = resolveServiceUrl();
    console.log("ServiceUrl: ", serviceURL);

    // initialize client instance
    const client = axios.create({
        baseURL: serviceURL,
        timeout: 150000,
    });

    // default headers
    client.defaults.headers.common['Content-Type'] = 'application/json';
    client.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';    

    /* ******************************************************************** */
    // interceptors

    // function that will be called to refresh authorization
    const refreshAuthLogic = async (failedRequest: AxiosError<any>) => {

        if (failedRequest.response?.status !== 401) {
            authStore.clear();
            console.error('AUTH refresh token error:', failedRequest);
            return Promise.reject({ error: failedRequest, description: 'AUTH refresh token error' });
        }

        return await authClient.refresh({ refreshToken: authStore.refreshToken() })
            .then(tokenRefreshResponse => {

                authStore.store(tokenRefreshResponse);

                // update failed request header
                if (failedRequest?.response?.config?.headers !== undefined) {
                    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.jwtToken;
                }

                console.log('AUTH: Token has been refreshed...');

                return Promise.resolve();
            })
            .catch(err => {
                authStore.clear();
                console.error('AUTH refresh token error, (redirecting to login page):', err);
                window.location.href = routes.login;
                return Promise.reject({ error: err, description: 'AUTH refresh token error' });
            });
    }

    // instantiate the interceptor (you can chain it as it returns the axios instance)
    createAuthRefreshInterceptor(
        client, 
        refreshAuthLogic, {
        pauseInstanceWhileRefreshing: true
    });

    // instantiate defauyt interceptor for auth
    client.interceptors.request.use(request => {

        if (request?.headers?.common !== undefined) {
            request.headers['Authorization'] = `Bearer ${authStore.token() ?? 'UNDEFINED TOKEN'}`;
        }

        return request;
    })

    client.interceptors.response.use(response => {
        console.log('RESPONSE ', `status: [${response.status}]`, `url: [${response.config.url}]`);
        return response;
    }, async (error: AxiosError) => {

        console.error('Axios Error: ', error.code, error.response?.status, error.response?.statusText);

        if (error.response?.status === 404) {
            console.error('Not found: ', error.response.status, error.response.statusText);
            return Promise.reject(error);
        }

        if (error.response?.status === 415) {
            console.error('Unsupported Media Type: ', error.response.status, error.response.statusText);
            return Promise.reject(error);
        }

        return Promise.reject(error);
    });

    return client;
}

/* ******************************************************************** */
// axios client base request handlers
export interface Http {

    // client instance
    client: AxiosInstance,

    // client interface
    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R>;
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>,
    post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R>,
    put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R>,
    delete(url: string, config?: AxiosRequestConfig): Promise<boolean>,
}

export const http: Http = {

    client: getAxiosClient(),

    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
        console.log(`REQUEST: ${config.url}:`, config);
        return this.client.request(config)
            .then(response => {
                return Promise.resolve(response.data);
            })
            .catch(error => {
                console.error('http client request error: ', error);
                return Promise.reject({ error: error, descripton: 'http client request error' });
            });
    },

    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        console.log(`GET: ${url}:`);
        return this.client.get(url, config)
            .then(response => {
                return Promise.resolve(response.data);
            })
            .catch(error => {
                console.error('http client get error: ', error);
                return Promise.reject({ error: error, descripton: 'http client get error' });
            });
    },

    post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
        console.log(`POST: ${url}:`, data);
        return this.client.post(url, data, config)
            .then(response => {
                return new Promise<R>((resolve, reject) => {
                    if (!response)
                        reject({ descripton: `http client post error: data not present from endpoint ${url}` });
                    else
                        resolve(response.data);
                });
            })
            .catch(error => {
                console.error('http client post error: ', error);
                return Promise.reject({ error: error, descripton: 'http client post error' });
            });
    },

    put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
        console.log(`PUT: ${url}:`, data);
        return this.client.put(url, data, config)
            .then(response => {
                const status = response.status;
                return new Promise<R>((resolve, reject) => {
                    if (response.data == undefined)
                        reject({ descripton: `http client put error: data not present from endpoint ${url}` });
                    else
                        resolve(response.data);
                });
            })
            .catch(error => {
                console.error('http client put error: ', error);
                return Promise.reject({ error: error, descripton: 'http client put error' });
            });
    },

    delete(url: string, config?: AxiosRequestConfig): Promise<boolean> {
        console.log(`DELETE: ${url}:`);
        return this.client.delete(url, config)
            .then(response => {
                console.log(typeof (response));
                return Promise.resolve(true);
            })
            .catch(error => {
                console.error('http client delete error: ', error);
                return Promise.reject({ error: error, descripton: 'http client delete error' });
            });
    },
}

export default http;