import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// mui
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, TextField, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';

// utils
import { formatDate, DateFormat, DateType } from '../../utils/datetime';

// dto
import { CreateDeviceDto } from '../../service/dataContract';
import { useStoreActions, useStoreState } from '../../hooks';
import { DatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        verticalContent: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        horizontalContent: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
        },
        margin: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
        },
        separator: {
            marginTop: theme.spacing(2),
        },
        actions: {
            marginRight: theme.spacing(2),
        },
        heading: {
            flexBasis: '33%',
            flexShrink: 0,
        },
    }),
);

export interface Props {
    readonly: boolean,
}

interface State extends CreateDeviceDto {

}

export const DeviceInfoCard: React.FC<Props> = (props) => {

    const classes = useStyles();
    let history = useHistory();

    const { updateDevice, fetchMaintainers } = useStoreActions(state => state.device);
    const { deviceDetail, maintainers, isLoading } = useStoreState(state => state.device);
    const { isExtern, isOwner } = useStoreState(state => state.auth);

    const [expanded, setExpanded] = useState<string | false>(false);
    const [changed, setChanged] = useState(false);
    const [values, setValues] = useState<State>({ ...deviceDetail });

    useEffect(() => {
        setValues({ ...deviceDetail });
    }, [deviceDetail])

    useEffect(() => {
        fetchMaintainers();
    }, [fetchMaintainers])

    const handleDetailChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    // data changed
    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
        setChanged(true);
    };

    // op since date changed (we dont allow null at dto level - will be not updated, null | undefined means not update)
    const handleDateChange = (date: DateType) => {
        setValues({ ...values, operatingSince: date ?? undefined });
        setChanged(true);
    };

    const handleValueChange = (prop: keyof State, value?: any) => {
        setValues({ ...values, [prop]: value });
        setChanged(true);
    };

    // contact save 
    const handleDeviceInfoSave = () => {
        updateDevice({
            deviceId: deviceDetail?.id ?? '',
            device: { ...values },
        });
        setChanged(false);
    }
    return (
        <div className={classes.root}>

            <Accordion
                expanded={expanded === 'cardpanel'}
                onChange={handleDetailChange('cardpanel')}>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="cardpanel-header">

                    <Typography
                        display='inline'
                        variant='h6'
                        className={classes.heading}>
                        Device: {deviceDetail?.deviceType?.name || ''}
                    </Typography>

                    <Typography
                        variant='h6'
                        className={classes.heading}>
                        SN: {deviceDetail?.serialNumber || ''}
                    </Typography>

                    <Typography variant='subtitle1'>
                        Gauges: {values.gaugeValues || 'N/A'},
                        Operating since: {formatDate(values.operatingSince)}
                    </Typography>

                </AccordionSummary>

                <AccordionDetails>

                    <div className={classes.form}>

                        <div className={classes.horizontalContent}>
                            <div className={classes.verticalContent}>

                                <TextField
                                    label="Serial Number"
                                    id="dev-serial"
                                    size='small'
                                    value={deviceDetail?.serialNumber || ''}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: props.readonly }}
                                />

                                <TextField
                                    label="Device Type"
                                    id="dev-type"
                                    size='small'
                                    value={deviceDetail?.deviceType?.name || ''}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true, }}
                                />

                                <TextField
                                    label="Gauges mm"
                                    id="dev-gauges"
                                    size='small'
                                    value={values.gaugeValues || ''}
                                    onChange={handleInputChange('gaugeValues')}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: props.readonly }}
                                />

                            </div>

                            <div className={classes.verticalContent}>

                                <Autocomplete
                                    id='maintainer-name'
                                    className={classes.margin}
                                    options={maintainers}
                                    disabled={isExtern}
                                    value={maintainers.find(m => m.id == values.externalUserId) ?? null}
                                    getOptionLabel={(option) => `${option.email}`}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label='Maintainer'
                                            variant='standard' />}
                                    onChange={(evt, selected) => { handleValueChange('externalUserId', selected?.id ?? ''); }}
                                />

                                <TextField
                                    label="Sales Representative"
                                    id="dev-salesrepresentative"
                                    size='small'
                                    value={values.salesRepresentative || ''}
                                    onChange={handleInputChange('salesRepresentative')}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: props.readonly }}
                                />

                                <DatePicker
                                    label="Operating since"
                                    id="dev-opsince"
                                    size='small'
                                    format={DateFormat}
                                    value={values.operatingSince}
                                    onChange={(date) => handleDateChange(date)}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: props.readonly }}
                                />

                                <TextField
                                    label="Operating since (note)"
                                    id="dev-opsincenote"
                                    size='small'
                                    value={values.operatingSinceNote || ''}
                                    onChange={handleInputChange('operatingSinceNote')}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: props.readonly }}
                                />

                            </div>

                            <div className={classes.verticalContent}>

                                <TextField
                                    label="Created at"
                                    id="dev-created"
                                    size='small'
                                    value={formatDate(deviceDetail?.createdAt)}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true, }}
                                />

                                <TextField
                                    label="Created by"
                                    id="dev-modifiedby"
                                    size='small'
                                    value={deviceDetail?.createdBy || 'migration'}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true, }}
                                />

                                <TextField
                                    label="Modified at"
                                    id="dev-modified"
                                    size='small'
                                    value={formatDate(deviceDetail?.lastModifiedAt)}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true }}
                                />

                                <TextField
                                    label="Modified by"
                                    id="dev-modifiedby"
                                    size='small'
                                    value={deviceDetail?.lastModifiedBy || ''}
                                    className={classes.margin}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true, }}
                                />

                            </div>
                        </div>

                        <div className={classes.verticalContent}>

                            <div className={classes.separator} />

                            <TextField
                                label="Note"
                                id="dev-note"
                                variant='outlined'
                                value={values.note || ''}
                                onChange={handleInputChange('note')}
                                className={classes.margin}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: props.readonly }}
                            />

                        </div>

                    </div>

                </AccordionDetails>

                {!props.readonly && (
                    <AccordionActions>
                        <div className={classes.actions}>
                            <Button
                                autoFocus
                                color='primary'
                                startIcon={<DoneIcon />}
                                disabled={!changed}
                                onClick={() => { handleDeviceInfoSave() }}>
                                Save
                            </Button>
                        </div>
                    </AccordionActions>
                )}

            </Accordion>


        </div >
    )
}

export default DeviceInfoCard;