import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, createStyles, Divider, Fab, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Paper, TextField, Theme } from '@material-ui/core';
import { AttachedFileBriefDto, AttachedFileDto } from '../service/dataContract';

// mui
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

// http
import { Progress } from '../service/httpClient';

// utils
import { formatDate } from '../utils/datetime';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        downloadContent: {
            width: '50%',
            background: '#FCFCFC'
        },
        uploadContent: {
            width: '50%',
            background: '#FCFCFC',
            marginRight: theme.spacing(1),
        },
        descriptionField: {
            marginLeft: theme.spacing(1),
        },
        fileField: {
            width: '40%',
            marginRight: theme.spacing(1),
            verticalAlign: 'center',
        },
        chooseButton: {
            flexGrow: 0,
            width: '30%',
            margin: theme.spacing(0, 0, 0, 0),
        },
        uploadButton: {
            flexGrow: 1,
            marginLeft: theme.spacing(1),
        },
        listItem: {
            display: 'flex',
            flexDirection: 'row',

        },
    }),
);

interface UploadFileDescriptor {
    file: File,
    description?: string,
    uploadProgress: number,
    uploaded: boolean,
    uploading: boolean,
}

export interface Props {
    readonly: boolean,
    attachedFiles: AttachedFileBriefDto[],
    deleteCallback?: (fileId: string) => void,
    uploadCallback?: (data: FormData, uploadProgress: Progress) => void,
    downloadCallback?: (fileId: string, downloadProgress: Progress) => void,
}

export const AttachedFileList: React.FC<Props> = (props) => {

    const classes = useStyles();
    const [uploadFiles, setUploadFiles] = useState<UploadFileDescriptor[]>([]);
    const id = Math.random()

    // handles the file remove from upload list
    function handleRemove(descriptor: UploadFileDescriptor) {
        setUploadFiles([...uploadFiles.filter(sf => sf.file.name != descriptor.file.name)]);
    }

    // handles input file selection
     const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        // get list from evt
        const fileList = event.target.files;

        if (fileList) {

            // get fles not in already selected list
            // tricky one: we know only name, not full path
            let newFiles = Array.from(fileList)
                .filter(newFile => !uploadFiles.find(desc => desc.file.name === newFile.name));

            // map to descriptors
            const newDescriptors = newFiles.map<UploadFileDescriptor>(file => ({
                file: file,
                uploadProgress: 0,
                description: '',
                uploaded: false,
                uploading: false,
            }));

            setUploadFiles([...uploadFiles, ...newDescriptors]);

            // clear previously selected
            event.target.value = '';
        }
    }

    // handles the file upload - progress
    const handleUploadProgress = (key: UploadFileDescriptor) => (progress: ProgressEvent) => {
        let fd = uploadFiles.find(fd => fd.file.name === key.file.name);

        if (fd) {
            fd.uploadProgress = progress.loaded / progress.total * 100;
            fd.uploaded = fd.uploadProgress >= 100;
            fd.uploading = !fd.uploaded;
            setUploadFiles([...uploadFiles]);
        }
    }

    const handleDownloadProgress = (key: string) => (progress: ProgressEvent) => {
    }

    // handles the file upload
    function handleUpload() {
        uploadFiles.filter(fd => !fd.uploaded && !fd.uploading).forEach((fd) => {
            let formData = new FormData();
            formData.append('Description', fd.description ?? '');
            formData.append('File', fd.file);
            if (props.uploadCallback) {
                props.uploadCallback(formData, handleUploadProgress(fd));
            }
        });
    }

    // handles the file upload - progress
    const handledescriptionChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        uploadFiles[index].description = event.target.value;
        setUploadFiles([...uploadFiles])
    }

    // formats file info into a subtitlle
    function formatFileSubtitle(file: AttachedFileBriefDto): string {
        const desc = file.description ? `: ${file.description}` : '';
        const size = `${Math.trunc((file.fileSize ?? 0) / 1024)} Kb`;
        const date = formatDate(file.createdAt);
        const author = file.lastModifiedBy ?? '';
        return `${author}, ${date}, ${size} ${desc}`;
    }

    return (
        <div className={classes.root}>

            <Paper className={classes.uploadContent}>

                <form>
                    <div className={classes.root}>
                        <label className={classes.chooseButton}>
                            <input
                                style={{ display: 'none' }}                                
                                name="upload-file"
                                type="file"
                                multiple                                
                                onChange={handleInputChange}
                            />

                            <Button
                                fullWidth
                                color="secondary"
                                variant="contained"
                                component="span">
                                Choose files...
                            </Button>
                        </label>

                        <Button
                            className={classes.uploadButton}
                            color='primary'
                            variant="contained"
                            onClick={handleUpload}
                            startIcon={<CloudUploadIcon />}>
                            Upload
                        </Button>
                    </div>
                </form>

                <List dense>
                    {uploadFiles?.map((desc, index) => (
                        <>
                            <ListItem
                                className={classes.listItem}
                                key={desc.file.name}>

                                <ListItemIcon>
                                    <IconButton onClick={() => handleRemove(desc)} color='secondary'>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemIcon>

                                <ListItemText
                                    id={desc.file.name}
                                    className={classes.fileField}
                                    primary={desc.file.name} />

                                <ListItemText
                                    className={classes.descriptionField}
                                    primary={
                                        <TextField
                                            label='Description'
                                            size='small'
                                            variant='outlined'
                                            color='primary'
                                            className={classes.descriptionField}
                                            key={desc.file.name}
                                            value={desc.description}
                                            onChange={handledescriptionChange(index)} />
                                    }>
                                </ListItemText>


                                <ListItemSecondaryAction>
                                    {!desc.uploaded && (
                                        <CircularProgress variant="static" value={desc.uploadProgress} />
                                    )}

                                    {desc.uploaded && (
                                        <CheckIcon color='secondary' fontSize='large' />
                                    )}
                                </ListItemSecondaryAction>

                            </ListItem>

                            <Divider variant='inset' />
                        </>
                    ))}
                </List>

            </Paper>

            <Paper className={classes.downloadContent}>
                <List dense={true}>
                    {props.attachedFiles?.map((file) => (

                        <>
                            <ListItem key={file.id}>

                                <ListItemIcon>
                                    <IconButton onClick={() => { if (props.downloadCallback) props.downloadCallback(file.id, handleDownloadProgress(file.fileName ?? '')) }} color='primary'>
                                        <CloudDownloadIcon />
                                    </IconButton>
                                </ListItemIcon>

                                <ListItemText
                                    primary={file.fileName}
                                    secondary={formatFileSubtitle(file)}
                                />

                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => { if (props.deleteCallback) props.deleteCallback(file.id) }} color='secondary'>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>

                            </ListItem>

                            <Divider variant='inset' component='li' />
                        </>

                    ))}
                </List>
            </Paper>

        </div>
    );
}

export default AttachedFileList;