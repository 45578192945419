
export const base64ToUTF8 = (base64Content: string) => {
    return decodeURIComponent(
        atob(base64Content)
            .split('')
            .map(c => { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); })
            .join(''));
}

export const base64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}

export function getFileName(path?: string): string {
    return path?.replace(/^.*[\\\/]/, '') ?? '';
}

export function getFileNameWithoutExtension(path?: string): string {
    return path?.replace(/\.[^/.]+$/, "") ?? '';
}

export function normalizeFileName(path: string): string {
    return path.replace(/[^a-zA-Z0-9-_\.]/g, '');
}