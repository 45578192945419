import React, { useEffect } from 'react';

// mui
import { Button, Card, CardContent, CardHeader, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

// store
import { useStoreActions, useStoreState } from '../../hooks';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}));

interface Props {
}

interface State {
}


export const Admin: React.FC<Props> = props => {

  const classes = useStyles();
  const { } = useStoreActions(state => state.device);
  const { } = useStoreState(state => state.device);

  const { isSuperAdmin } = useStoreState(state => state.auth);
  const { isLoading } = useStoreState(state => state.license);

  useEffect(() => {

  }, []);


  return (

    <div className={classes.root} >

      <Typography variant='h3'>Admin Tools</Typography>

      {isSuperAdmin &&
        <Card>
          <CardHeader title='License Utilities' />
          <CardContent>
            <Button
              disabled={isLoading}
              color='secondary'
              variant='outlined'
              onClick={() => {}}>None</Button>
          </CardContent>
        </Card>
      }

    </div>
  );
}

export default Admin;