import { http } from './httpClient';
import { AuthRefreshRequest, AuthRequest, AuthResponse } from './dataContract';

const baseRoute = '/users';

const routes = {
    auth: `${baseRoute}/authenticate`,
    refresh: `${baseRoute}/refresh-token`,
    revoke: `${baseRoute}/revoke-token`,
    logout: `${baseRoute}/logout`
}

export interface AuthClient {
    authenticate(payload: { login: AuthRequest }): Promise<AuthResponse>,
    refresh(payload: { refreshToken: string | undefined }): Promise<AuthResponse>,
    logout(): Promise<boolean>,
}

const authClient: AuthClient = {

    authenticate: (payload) => {

        return http.post<AuthRequest, AuthResponse>(routes.auth, payload.login)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(err => {
                return Promise.reject({ err: err, desc: 'Authentication has failed.' });
            });
    },

    refresh: (payload) => {

        // check if refresh token is defined 
        if (payload.refreshToken === undefined) {
            return Promise.reject('Can not refresh using undefined token.');
        }

        // post and store
        return http.post<AuthRefreshRequest, AuthResponse>(routes.refresh, { refreshToken: payload.refreshToken })
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(err => {
                return Promise.reject({ error: err, description: 'refresh-token invalid response' });
            });
    },

    logout: () => http.delete(routes.logout),

}

export default authClient;