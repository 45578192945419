import authModel, { AuthModel } from './auth';
import deviceModel, { DeviceModel } from './device';
import deviceFilter, { DeviceFilterModel } from './deviceFilter';
import componentState, { DeviceComponentState } from './deviceComponent';
import contactState, { ContactState } from './contact';
import servState, { ServiceOperationState } from './serviceOperation';
import softwareModel, { SoftwareModel } from './software';
import licenseModel, { LicenseModel } from './license';
import userModel, { UserModel } from './user';
import auditModel, { AuditModel } from './audit';
import vgnModel, { VgnModel } from './vgn';



// global store interface
export interface StoreContext {
    auth: AuthModel,
    device: DeviceModel,
    deviceFilter: DeviceFilterModel,
    software: SoftwareModel,
    license: LicenseModel,
    component: DeviceComponentState,
    service: ServiceOperationState,
    contact: ContactState,
    user: UserModel,
    audit: AuditModel,
    vgn: VgnModel,
    // .. add interfaces
};

const storeContext: StoreContext = {
    auth: authModel,
    user: userModel,
    device: deviceModel,
    deviceFilter: deviceFilter,
    software: softwareModel,
    license: licenseModel,
    component: componentState,
    service: servState,
    contact: contactState,
    audit: auditModel,
    vgn: vgnModel,
    // .. add models
}

export default storeContext;