import { http } from './httpClient';
import { ComponentDto, CreateComponentDto } from './dataContract'

const baseRoute = (deviceId: string) => `/devices/${deviceId}/components`;

const routes = {
    fetch: (deviceId: string) => `${baseRoute(deviceId)}`,
    get: (deviceId: string, componentId: string) => `${baseRoute(deviceId)}/${componentId}`,
    create: (deviceId: string) => `${baseRoute(deviceId)}`,
    update: (deviceId: string, componentId: string) => `${baseRoute(deviceId)}/${componentId}`,
    delete: (deviceId: string, componentId: string) => `${baseRoute(deviceId)}/${componentId}`,
}

export interface DeviceComponentClient {
    fetch(payload: { deviceId: string }): Promise<ComponentDto[]>,
    get(payload: { deviceId: string, compoentId: string }): Promise<ComponentDto>,
    create(payload: { deviceId: string, component: CreateComponentDto }): Promise<ComponentDto>,
    update(payload: { deviceId: string, componentId: string, component: CreateComponentDto }): Promise<ComponentDto>,
    delete(payload: { deviceId: string, componentId: string }): Promise<boolean>,
}

const componentClient: DeviceComponentClient = {
    fetch: (payload) => http.get(routes.fetch(payload.deviceId)),
    get: (payload) => http.get(routes.get(payload.deviceId, payload.compoentId)),
    create: (payload) => http.post(routes.create(payload.deviceId), payload.component),
    update: (payload) => http.put(routes.update(payload.deviceId, payload.componentId), payload.component),
    delete: (payload) => http.delete(routes.delete(payload.deviceId, payload.componentId)),
}

export default componentClient;