import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, FormControlLabel, makeStyles, Radio, RadioGroup, TextField, Theme } from '@material-ui/core';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: 500,
            flexGrow: 1,
        },
        radioGroup: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
        },
        margin: {
            marginTop: theme.spacing(1),
        },
    }),
);
interface Props {
    isOpen: boolean,
    title: string,
    message: string,
    onCancel?: () => void,
    onConfirm?: (payload: { isNew: boolean, message: string }) => void,
}

interface State {
    text: string,
    licenseType: 'new' | 'upgrade' | 'none',
}

export const LicenseRequestDialog: React.FC<Props> = (props) => {

    const classes = useStyles();

    const handleCancel = () => {
        if (props.onCancel)
            props.onCancel();
    };

    const handleConfirm = () => {
        if (props.onConfirm)
            props.onConfirm({ isNew: state.licenseType === 'new', message: state.text });

        setState({ text: '', licenseType: 'none' })
    };

    const [state, setState] = useState<State>({
        text: '',
        licenseType: 'none',
    });

    // data changed
    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [prop]: event.target.value });
    };

    const handleCheckedChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [prop]: event.target.checked });
    };


    return (
        <Dialog
            open={props.isOpen}
            onClose={handleCancel}>

            <DialogTitle>{props.title}</DialogTitle>

            <DialogContent className={classes.content}>

                <DialogContentText>
                    {props.message}
                </DialogContentText>

                <RadioGroup
                    className={classes.radioGroup}
                    value={state.licenseType}
                    onChange={handleInputChange('licenseType')}>
                    <div>
                        <FormControlLabel value={'new'} control={<Radio />} label="New" />
                        <FormControlLabel value={'upgrade'} control={<Radio />} label="Upgrade" />
                    </div>
                </RadioGroup>

                <TextField
                    className={classes.margin}
                    multiline
                    fullWidth
                    label='License notes'
                    variant='outlined'
                    value={state.text}
                    onChange={handleInputChange('text')}
                    InputLabelProps={{ shrink: true }}>
                </TextField>

            </DialogContent>

            <DialogActions>

                <Button
                    onClick={handleCancel}
                    color="primary"
                    autoFocus>
                    Cancel
                </Button>

                <Button
                    onClick={handleConfirm}
                    color="primary"
                    disabled={state.licenseType === 'none'}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LicenseRequestDialog;