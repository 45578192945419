import { http } from './httpClient';
import { UserDto, CreateUserDto } from './dataContract'

const baseRoute = '/users';

const routes = {
    fetch: `${baseRoute}`,
    get: (userId: string) => `${baseRoute}/${userId}`,
    create: `${baseRoute}`,
    update: (userId: string) => `${baseRoute}/${userId}`,
    delete: (userId: string) => `${baseRoute}/${userId}`,
}

export interface UserClient {
    fetch(): Promise<UserDto[]>,
    get(payload: { userId: string }): Promise<UserDto>,
    create(payload: { user: CreateUserDto }): Promise<UserDto>,
    update(payload: { userId: string, user: CreateUserDto }): Promise<UserDto>,
    delete(payload: { userId: string }): Promise<boolean>,
}

const userClient: UserClient = {
    fetch: () => http.get(routes.fetch),
    get: (payload) => http.get(routes.get(payload.userId)),
    create: (payload) => http.post(routes.create, payload.user),
    update: (payload) => http.put(routes.update(payload.userId), payload.user),
    delete: (payload) => http.delete(routes.delete(payload.userId)),
}

export default userClient;