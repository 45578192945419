import { Action, action, Thunk, thunk } from 'easy-peasy';
import { StoreContext } from '.';
import { Injections } from '../store';
import { VgnDto } from '../service/dataContract';
import { StoreMode, storeValue } from '../utils/storageUtils';
import { Progress } from '../service/httpClient';
import { base64toBlob } from '../utils/fileUtils';
import { promises } from 'dns';

// device store interface
export interface VgnModel {

    // loading indnpm startication
    isLoading: boolean,
    vgnBoards: VgnDto[],

    // fetch all vgn devices
    fetchVgn: Thunk<VgnModel, void, Injections, StoreContext, Promise<VgnDto[]>>,

    // creates a new device
    importVgn: Thunk<VgnModel, { formData: FormData, uploadProgress: Progress}, Injections, StoreContext, Promise<VgnDto[]>>,
   
    storeVgnBoards: Action<VgnModel, { boards: VgnDto[] }>,

    // set loading flag action
    setLoading: Action<VgnModel, boolean>,
};

const vgnModel: VgnModel = {

    /* data */

    isLoading: false,
    vgnBoards : [],

    /* thunks */

    setLoading: action((store, payload) => { store.isLoading = payload; }),

    // fetch

    fetchVgn: thunk(async (actions, payload, { injections, getStoreActions }) => {

        actions.setLoading(true);
        const { vgnClient } = injections;

        return vgnClient.fetch()
            .then(boards => {
                actions.storeVgnBoards({ boards: [ {id:'', deviceName: 'Any', serialNumber:'*', requiredAuth: 'None'}, ...boards ]});
                return boards;
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to load list of VGN devices.`, payload: err })
                return Promise.reject({ severity: 'error', message: `Unable to load list of VGN devices.`, payload: err });
            })
            .finally(()=>{
                actions.setLoading(false);
            });
    }),

    // import

    importVgn: thunk(async (actions, payload, { injections, getStoreActions }) => {

        actions.setLoading(true);        
        const { vgnClient } = injections;

        return await vgnClient.import(payload)
            .then(devices => {
                actions.storeVgnBoards({ boards: devices });
                getStoreActions().audit.notify({ severity: 'info', message: `VGN database has been sucessfully uploaded.` })
                return devices;
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not uplaod VGN database file.`, payload: err });
                return Promise.reject({ severity: 'error', message: `Can not uplaod VGN database file.`, payload: err });
            })
            .finally(()=>{
                actions.setLoading(false);
            });

    }),

    /* actions | device */

    storeVgnBoards: action((store, payload) => {
        store.vgnBoards = payload.boards;
    }),

}

export default vgnModel;
