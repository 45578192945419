import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

// store
import { useStoreState, useStoreActions } from '../../hooks'

// mui
import { Badge } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MaterialTable, { Column } from 'material-table';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CommentIcon from '@material-ui/icons/Comment';

// components
import ContactCard from './ContactCard';
import DeviceInfoCard from './DeviceInfoCard';
import NoteCard from '../NoteCard';
import AttachedFileList from '../AttachedFileList';
import DeviceToolbar from './DeviceToolbar';

// dtos
import { ServiceOperationDto } from '../../service/dataContract';
import { getLookupKey, serviceStateLookup } from '../../model/codelist';
import { Progress } from '../../service/httpClient';

// utils
import { validateInput, validateDefined } from '../../utils/validationUtils';
import { formatDate } from '../../utils/datetime';


// styles
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    detailPanel: {
        background: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'left',
        margin: theme.spacing(1),
    },
}));

// constants

const finisheStatedKey = getLookupKey(serviceStateLookup, 'Finished');
const plannedStatedKey = getLookupKey(serviceStateLookup, 'Planned');

// url params
interface MatchParams {
    id: string;
}

// props with expected route params
interface Props extends RouteComponentProps<MatchParams> { }

// device service tool
export const DeviceService: React.FC<Props> = props => {

    const classes = useStyles();

    const { deviceDetail, isLoading } = useStoreState(state => state.device);
    const { getDeviceById } = useStoreActions(state => state.device);
    const { addServiceOperation,
        updateServiceOperation,
        deleteServiceOperation,
        uploadServiceFile,
        downloadServiceFile,
        deleteServiceFile } = useStoreActions(state => state.service);

    const { isAdmin } = useStoreState(state => state.auth);

    useEffect(() => {
        console.log('UseEffect: getDeviceById');
        getDeviceById({ deviceId: props.match.params.id });
    }, []);


    const canEditSopDetail = (data: ServiceOperationDto): boolean => {
        //                           != compares string/number value
        return isAdmin || data.state != finisheStatedKey;
    }

    const columns: Column<ServiceOperationDto>[] = [
        {
            title: 'Subject',
            field: 'subject',
            width: '100%',
            validate: rowData => validateInput(rowData.subject, 2)
        },
        {
            title: 'Status',
            field: 'state',
            width: 200,
            lookup: serviceStateLookup,
            initialEditValue: plannedStatedKey,
            validate: rowData => validateDefined(rowData.state)
        },
        { title: 'Order', field: 'order', width: 150 },
        { title: 'Technician', width: 150, render: sop => sop.createdBy },
        {
            title: 'Accepted',
            width: 150,
            field: 'acceptedAt',
            type: 'date',
            initialEditValue: new Date(),
            render: dto => formatDate(dto.acceptedAt),
            validate: rowData => validateDefined(rowData.acceptedAt)
        },
        {
            title: 'Handout',
            width: 150,
            field: 'releasedAt',
            type: 'date',
            render: dto => formatDate(dto.releasedAt)
        },
        { title: 'Modified by', width: 200, field: 'lastModifiedBy', editable: 'never' },
        { title: 'Modified at', width: 200, field: 'lastModifiedAt', render: dto => formatDate(dto.lastModifiedAt), editable: 'never' },
        { title: 'Created by', field: 'createdBy', editable: 'never', hidden: true },
        { title: 'Created at', field: 'createdAt', type: 'date', editable: 'never', hidden: true },
    ];

    // updates or creates the service op note
    const handleUploadServiceFile = (sop: ServiceOperationDto) => (data: FormData, uploadProgress: Progress) => {
        const payload = {
            deviceId: props.match.params.id,
            serviceId: sop.id,
            formData: data,
            uploadProgress: uploadProgress,
        };

        uploadServiceFile(payload);
    }

    // updates or creates the service op note
    const handleDownloadServiceFile = (sop: ServiceOperationDto) => (fileId: string, downloadProgress: Progress) => {
        const payload = {
            deviceId: props.match.params.id,
            serviceId: sop.id,
            fileId: fileId,
            downloadProgress: downloadProgress,
        };

        downloadServiceFile(payload);
    }

    // updates or creates the service op note
    const handleDeleteServiceFile = (sop: ServiceOperationDto) => (fileId: string) => {
        const payload = {
            deviceId: props.match.params.id,
            serviceId: sop.id,
            fileId: fileId,
        };

        deleteServiceFile(payload);
    }

    const handleServiceOpCreate = (newData: ServiceOperationDto): Promise<void> =>
        new Promise((resolve, reject) => {
            addServiceOperation({
                deviceId: props.match.params.id,
                service: {
                    subject: newData.subject ?? '',
                    state: newData.state,
                    order: newData.order,
                    acceptedAt: newData.acceptedAt,
                    releasedAt: newData.releasedAt,
                }
            });
            resolve();
        });

    const handleServiceOpDelete = (oldData: ServiceOperationDto): Promise<void> =>
        new Promise((resolve, reject) => {
            deleteServiceOperation({
                deviceId: props.match.params.id,
                serviceId: oldData?.id ?? ''
            });
            resolve();
        });

    const handleServiceOpUpdate = (newData: ServiceOperationDto, oldData?: ServiceOperationDto): Promise<void> =>
        new Promise((resolve, reject) => {
            updateServiceOperation({
                deviceId: props.match.params.id,
                serviceId: oldData?.id ?? '',
                service: {
                    subject: newData.subject ?? '',
                    order: newData.order,
                    state: newData.state,
                    note: newData.note,
                    acceptedAt: newData.acceptedAt,
                    releasedAt: newData.state === 2 && newData.releasedAt === null
                        ? new Date()
                        : newData.releasedAt
                }
            });

            resolve();
        });

    const handleServiceOpNoteUpdate = (data: ServiceOperationDto, note: string): Promise<void> =>
        new Promise((resolve, reject) => {
            updateServiceOperation({
                deviceId: props.match.params.id,
                serviceId: data?.id ?? '',
                service: { note }
            });
            resolve();
        });

    return (
        <div className={classes.root}>

            <DeviceToolbar />

            <div className={classes.card}>
                <DeviceInfoCard readonly={false} />
            </div>

            <div className={classes.card}>
                <ContactCard readonly={false} />
            </div>

            <div className={classes.card}>
                <MaterialTable
                    title="Service Operations"
                    isLoading={isLoading}
                    columns={columns}
                    data={deviceDetail?.serviceOperations ?? []}
                    options={{
                        paging: false,
                    }}

                    editable={{
                        onRowAdd: handleServiceOpCreate,
                        onRowUpdate: handleServiceOpUpdate,
                        onRowDelete: handleServiceOpDelete,

                        isEditable: (rowData) => canEditSopDetail(rowData),
                        isDeletable: (rowData) => isAdmin,
                    }}

                    actions={[]}

                    detailPanel={[
                        // detail panel for service notes    
                        rowData => ({
                            tooltip: 'Service Notes',
                            icon: () => (<Badge color='secondary' variant="dot" invisible={!rowData.note} ><CommentIcon color='primary' /></Badge>),
                            render: (rowData) => (
                                <div className={classes.detailPanel}>
                                    <NoteCard
                                        key={rowData.id}
                                        text={rowData.note}
                                        readOnly={!canEditSopDetail(rowData)}
                                        onSave={(text) => handleServiceOpNoteUpdate(rowData, text)}
                                    />
                                </div>
                            ),
                        }),

                        // detail panel for service attached files
                        rowData => ({
                            tooltip: 'Atached Files',
                            icon: () => (<Badge color='secondary' badgeContent={rowData.attachedFiles?.length} variant='standard'><AttachmentIcon color='primary' /></Badge>),
                            render: (rowData) => (
                                <div className={classes.detailPanel}>
                                    <AttachedFileList
                                        key={rowData.id}
                                        readonly={false}
                                        attachedFiles={rowData.attachedFiles ?? []}
                                        uploadCallback={handleUploadServiceFile(rowData)}
                                        downloadCallback={handleDownloadServiceFile(rowData)}
                                        deleteCallback={handleDeleteServiceFile(rowData)}>
                                    </AttachedFileList>
                                </div>
                            ),
                        })
                    ]}
                />

            </div>
        </div>
    )
}

export default DeviceService;