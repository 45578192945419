import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
    isOpen: boolean,
    title: string,
    message: string,
    onCancel?: () => void,
    onConfirm?: () => void,
}

export const ConfirmDialog: React.FC<Props> = (props) => {

    const handleCancel = () => {
        if (props.onCancel)
            props.onCancel();
    };

    const handleConfirm = () => {
        if (props.onConfirm)
            props.onConfirm();
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleCancel}>

            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCancel} color="primary" autoFocus>
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;