import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import store from './store'
import { StoreProvider } from 'easy-peasy'

// disable console log out of development
if (process.env.NODE_ENV !== 'development') {
    console.log = () => {}
}

ReactDOM.render(
    <StoreProvider store={store}>
      <App />
    </StoreProvider>,
  document.getElementById('root')
);