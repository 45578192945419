export enum Key {
    UserId = 'USER_ID',
    UserName = 'USER_NAME',
    FullName = 'USER_FULL_NAME',
    UserRole = 'USER_ROLE',
    Token = 'JWT_TOKEN',
    RefteshToken = 'REFRESH_TOKEN',    
}

function getValue(key: Key) : string|undefined {
    const value = localStorage.getItem(key);
    return value ?? undefined;
}

function setValue(key: Key, value: string|undefined) {
    
    if (value === undefined) {
        localStorage.removeItem(key);
    }
    else {
        localStorage.setItem(key, value);
    }
}

function resetValue(key: Key) {
    setValue(key, undefined);
}

function clear() {
    localStorage.clear();
}

export interface PersistentStore {
    clear: () => void;
    reset: (key: Key) => void;
    set: (key: Key, value: string|undefined) => void;
    get: (key: Key) => string|undefined;
}

const persistentStore : PersistentStore = {
    clear: clear,
    reset: resetValue,
    set: setValue,
    get: getValue

}

export default persistentStore;