import React, { useEffect, useState } from 'react';

// mui
import { CardContent, Card, CardActions, List, ListItem, TextField, IconButton, CardHeader, Avatar, Tooltip, Box, Button } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';

// store
import { useStoreState, useStoreActions, useStoreDispatch } from '../../hooks'
import { SoftwareBriefDto, UserDto, UserRole } from '../../service/dataContract';

import SoftwareWatchersTransferList from './SoftwareWatchersTransferList';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    actions: {
        marginRight: theme.spacing(2),
    },
}));

interface Props {
    software?: SoftwareBriefDto;
    className?: string;
}

const SoftwareWatcherListEditor: React.FC<Props> = props => {

    const classes = useStyles();

    const { users, isLoading } = useStoreState(state => state.user);
    const { fetchUsers } = useStoreActions(state => state.user);
    const { updateSoftwareWatchers } = useStoreActions(state => state.software);

    const [changed, setChanged] = useState(false);
    const [watchers, setWatchers] = useState<UserDto[]>([]);

    useEffect(()=>{
        fetchUsers();
    }, [fetchUsers])


   useEffect (()=>{
        setWatchers(props.software?.softwareWatchers?? [])
   }, [props.software])


    return (

        <Card className={props.className}>

            <CardHeader            
                avatar={
                    <Avatar>W</Avatar>
                }
                title={`Manage software watchers for ${props.software?.name}`}
                subheader={'List of watchers to be notified when a software license is updated.'}
            />

            <CardContent>

            <SoftwareWatchersTransferList 
                title='Watchers'
                sourceState={users}
                currentState={watchers}
                selectionChanged={(watchers)=>{
                    setChanged(true);
                    setWatchers(watchers);
                }}
            />

            </CardContent>

            <CardActions>
                <div className={classes.actions}>
                    <Button
                        autoFocus
                        color='primary'
                        startIcon={<DoneIcon />}
                        disabled={!changed}
                        onClick={() => { 
                            updateSoftwareWatchers( { softwareId: props.software?.id?? '', watchers: watchers});
                            setChanged(false);
                            }}>
                        Save
                        </Button>
                </div>
            </CardActions>

        </Card >
    );
}

export default SoftwareWatcherListEditor;