import React, { useState } from 'react';

// mui
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Badge, Button, IconButton, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';

// dtos
import { useStoreActions, useStoreState } from '../../hooks';
import AttachedFileList from '../AttachedFileList';
import { Progress } from '../../service/httpClient';
import { DeviceFileBriefDto } from '../../service/dataContract';
import MaterialTable, { Column } from 'material-table';

// tools
import { getFileName } from '../../utils/fileUtils';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        margin: {
            margin: theme.spacing(1),
        },
        marginLeft: {
            marginLeft: theme.spacing(3),
        },
        heading: {
            flexBasis: '33%',
            flexShrink: 0,
        },
        headerContent: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 0,
            marginLeft: theme.spacing(2),
        },
    }),
);

interface Props { }

export const CfgFilesCard: React.FC<Props> = (props) => {

    const classes = useStyles();

    const { downloadCfgFile, deleteCfgFile } = useStoreActions(state => state.device);
    const { deviceDetail } = useStoreState(state => state.device);

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleDetailChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDownloadFile = (fileId: string, downloadProgress: Progress) => {
        downloadCfgFile({
            serialNumber: deviceDetail?.serialNumber ?? '',
            fileId: fileId,
            downloadProgress: downloadProgress,
        });
    }

    const handleDeleteFile = (file: DeviceFileBriefDto) : Promise<any> => 
        new Promise((resolve, reject) => {
            deleteCfgFile({
                serialNumber: deviceDetail?.serialNumber ?? '',
                fileId: file.id});
            resolve(true);
    });

    const columns: Column<DeviceFileBriefDto>[] = [
        { title: 'File Name', width: 150, 
            render: (rowData) => (getFileName(rowData.fileName?? ''))
        },
        { title: 'Version', width: 150, field: 'version', },
        { title: 'Label', width: 150, field: 'label', },
        {
            title: 'Size', width: 150,
            render: (rowData) => (<span>{((rowData.fileSize ?? 0) / 1024).toFixed(2)} Kb</span>)
        },
        {
            title: 'Download', width: 150,
            render: (rowData) => (
                <IconButton onClick={() => handleDownloadFile(rowData.id, () => { })}>
                    <CloudDownloadIcon color='primary' />
                </IconButton>
            )
        },
        { title: 'Device Path', width: 150, field: 'fileName', },
        { title: 'Created by', width: 150, field: 'createdBy', editable: 'never', hidden: true },
        { title: 'Created at', width: 150, field: 'createdAt', type: 'date', editable: 'never', hidden: true },
    ];

    return (
        <div className={classes.root}>

            <Accordion
                expanded={expanded === 'cardpanel'}
                onChange={handleDetailChange('cardpanel')}
                TransitionProps={{ unmountOnExit: true }}>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="cardpanel-header">
                    <Typography
                        variant='h6'
                        className={classes.heading}>
                        HUB Configuration Files:
                    </Typography>

                    <Badge
                        color='secondary'
                        variant="standard"
                        badgeContent={deviceDetail?.deviceFiles?.length}  >
                        <AttachmentIcon color='primary' />
                    </Badge>

                </AccordionSummary>

                <AccordionDetails>
                    <div className={classes.content}>
                        <MaterialTable
                            columns={columns}
                            data={deviceDetail?.deviceFiles ?? []}
                            options={{
                                search: false,
                                showTitle: false,
                                toolbar: false,
                                pageSize: 10,
                                pageSizeOptions: [5, 10, 20, 50],
                            }}
                            editable={{
                                onRowDelete: handleDeleteFile,
                            }
                            }>
                        </MaterialTable>
                    </div>
                </AccordionDetails>

            </Accordion>

        </div >
    )
}

export default CfgFilesCard;