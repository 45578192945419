import React, { useEffect, useState } from 'react';

// markdown
import ReactMarkdown from 'react-markdown';

// mui
import { Card, CardActions, CardContent, CardHeader, IconButton, InputBase, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import HelpIcon from '@material-ui/icons/Help';
import VisibilityIcon from '@material-ui/icons/Visibility';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
        },
        textField: {
            width: '100%',
            height: '100%',
            margin: theme.spacing(0),
            flexGrow: 1,
        },
        actionRightAlign: {
            marginLeft: 'auto',
        },
        cardHeader: {
            marginLeft: theme.spacing(0.5),
            padding: theme.spacing(0),
        },
        cardAction: {
            margin: theme.spacing(0),
            padding: theme.spacing(0),
        },
        cardContent: {
            display: 'flex',
            padding: theme.spacing(1),
            background: theme.palette.background.default
        },
        contentMargin: {
            margin: theme.spacing(0, 2, 0, 2),
            width: '100%',            
            minHeight: 30,
        }
    }),
);

interface State {
    readOnly: boolean,
    preview: boolean,
    changed: boolean,
    text: string,
}

export interface Props {
    readOnly: boolean,
    title?: string,
    text?: string,
    onSave?: (text: string) => void,
}

export const NoteCard: React.FC<Props> = (props) => {

    const classes = useStyles();

    const [values, setValues] = useState<State>({
        readOnly: props.readOnly,
        preview: true,
        changed: false,
        text: props.text ?? ''
    });

    useEffect(() => {
        setValues({ ...values, text: props.text ?? values.text });
    }, [props])

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value, changed: event.target.value !== props.text });
    };

    const handleValueChange = (prop: keyof State, value: any) => {
        setValues({ ...values, [prop]: value });
    };

    function setPreview() {
        handleValueChange('preview', true);
    }

    function setEdit() {
        if (!props.readOnly) {
            handleValueChange('preview', false);
        }
    }

    function handleCancel() {
        setValues({ ...values, changed: false, text: props.text ?? '', preview: true });
    }

    function handleSave() {
        if (props.onSave) {
            props.onSave(values.text);
        }
        setValues({ ...values, changed: false, preview: true });
    }

    function headerAvatar() {
        return (
            <>
                {values.preview && (
                    <Tooltip title='Edit'>
                        <span>
                            <IconButton
                                onClick={() => setEdit()}
                                color='primary'
                                disabled={props.readOnly}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}

                {!values.preview && (
                    <Tooltip title='Preview'>
                        <span>
                            <IconButton
                                onClick={() => setPreview()}
                                color='primary'
                                disabled={props.readOnly}>
                                <VisibilityIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </>
        )
    }

    function headerActions() {
        return (
            <>
                <Tooltip title='Help'>
                    <span>
                        <IconButton
                            onClick={() => {
                                let tempLink = document.createElement('a');
                                tempLink.href = `https://www.markdownguide.org/basic-syntax/`
                                tempLink.target = '_blank';
                                tempLink.click();
                            }}
                            color='primary'
                            disabled={props.readOnly}>
                            <HelpIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </>
        )
    }

    return (
        <Card variant='elevation' className={classes.root}>

            <CardHeader
                className={classes.cardHeader}
                title={props.title ?? 'Notes (markdown)'}
                avatar={headerAvatar()}
                action={headerActions()}>
            </CardHeader>

            <CardContent className={classes.cardContent} >

                <div
                    onClick={() => setEdit()}
                    className={classes.contentMargin}>

                    {!values.preview && (
                        <InputBase
                            className={classes.textField}
                            fullWidth
                            multiline
                            autoFocus
                            value={values.text}
                            onChange={handleChange('text')}
                            inputProps={{
                                readOnly: values.readOnly,
                                "aria-label": 'naked'
                            }}
                        />
                    )}

                    {values.preview && (
                        <ReactMarkdown children={values.text} />
                    )}

                </div>

            </CardContent>

            <CardActions disableSpacing className={classes.cardAction}>

                {!props.readOnly && (
                    <>
                        <Tooltip title='Cancel changes'>
                            <span>
                                <IconButton onClick={() => handleCancel()} color='primary' disabled={!values.changed}>
                                    <ClearIcon />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip title='Save changes'>
                            <span>
                                <IconButton onClick={() => handleSave()} color='primary' disabled={!values.changed} >
                                    <DoneIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                )}

            </CardActions>

        </Card >
    )
}

export default NoteCard;