import { fileIOTimeout, http } from './httpClient';
import { EmailMessageDto } from './dataContract'

const baseRoute = '/email/send/';

const routes = {
    send: `${baseRoute}`,
}

export interface EmailClient {
    send(payload: {message: EmailMessageDto}): Promise<boolean>,
}

const emailClient: EmailClient = {
    send: (payload) => http.post(routes.send, payload.message),
}

export default emailClient;