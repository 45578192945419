import React, { useEffect, useState } from 'react';

// mui
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Divider, Link, Paper, TextField, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable, { Column } from 'material-table';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// dtos
import { useStoreActions, useStoreState } from '../hooks';
import { ComponentCategoryValue, ComponentDto, ComponentPrototypeDto, CreateComponentDto, CreateDeviceDto, DeviceDto } from '../service/dataContract';
import { getLookupKey, componentCategoryLookup } from '../model/codelist';
import { enumerateComponentParams, enumerateParameters, getIsTemplate, getIsVgn, getParamsDictionary, getVgnParamsDictionary } from '../utils/componnetParametrs'
import { NoteCard } from './NoteCard';
import { ComponentParamsEditor } from './ComponentParamsEditor'


// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            background: 'gray',
        },
        contentBlock: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginBottom: theme.spacing(2),
        },
        parameters: {
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'row',
        },
        paramMargin: {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(0.25),
        },
        textField: {
            marginLeft: theme.spacing(1),
        },
        margin: {
            margin: theme.spacing(1),
        },
        vgnParams: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(3),
            marginBottom: theme.spacing(1),
        },
        vgnParamMargin: {
            marginTop: theme.spacing(1),
        },
        noteDetailPanel: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            marginLeft: theme.spacing(3),
        },
        detailPanel: {
            display: 'flex',
            flexDirection: 'row',
            margin: theme.spacing(1),
        },
        notePanel: {
            display: 'flex',
            flexDirection: 'row',
            margin: theme.spacing(0),
            padding: theme.spacing(1),
        },
        noteIconCollapsed: {
            margin: theme.spacing(0, 3, 0, 0.5),
            transform: 'rotate(0deg);'
        },
        noteIconExpanded: {
            margin: theme.spacing(0, 3, 0, 0.5),
            transform: 'rotate(90deg);'
        },
        actions: {
            marginRight: theme.spacing(3),
        },
    }),
);


interface State {
    prototype?: ComponentPrototypeDto,
    categoryNote?: string,
}

type DeviceNoteKeys = Pick<CreateDeviceDto, 'electronicNote' | 'sotfwareNote' | 'mechanicNote' | 'sensorNote'>

interface Props {
    title: string,
    category: ComponentCategoryValue,
    componentPrototypes: ComponentPrototypeDto[],
    categoryNoteKey: keyof DeviceNoteKeys,
}

export const ComponentParams: React.FC<Props> = (props) => {

    // styles
    const classes = useStyles();

    // data source
    const { deviceDetail, isLoading } = useStoreState(state => state.device);
    const { addDeviceComponent, deleteDeviceComponent, updateDeviceComponent } = useStoreActions(state => state.device);
    const { updateDevice } = useStoreActions(state => state.device);

    const [values, setValues] = useState<State>({
        prototype: undefined,
        categoryNote: ''
    });

    const [isNoteExpanded, setIsNoteExpanded] = useState(false);

    useEffect(() => {
        if (deviceDetail) {
            setValues({ ...values, categoryNote: deviceDetail[props.categoryNoteKey] })
        }
    }, [])


    // filter data
    const categoryKey = getLookupKey(componentCategoryLookup, props.category);
    const filteredComponents = deviceDetail?.components?.filter(dc => dc.componentPrototype?.componentType?.componentCategory == categoryKey) ?? [];
    const filteredPrototyopes = props.componentPrototypes?.filter(pc => pc.componentType?.componentCategory == categoryKey) ?? [];

    function getNotePreview(note?: string) {

        const maxLength = 50;
        const dots = '...';

        if (note) {
            const trimmedPreview = note.replace('#', '');
            const tail = (trimmedPreview.length > maxLength) ? dots : '';
            return trimmedPreview.substring(0, Math.min(trimmedPreview.length, maxLength)) + tail;
        }
        else {
            return dots;
        }
    }

    // parameters rendering
    function renderParameters(component: ComponentDto) {

        const color = getIsTemplate(component) ? 'secondary' : 'default';
        let protoParams = enumerateComponentParams(component, {includeVgn: true})

        return (
            <div className={classes.parameters}>
                {protoParams.map(param => (
                    <Box className={classes.paramMargin}>
                        <div>
                            {param.keyName &&
                                <Chip
                                    color={color}
                                    label={<Typography variant='caption'>{param.keyName}: {param.value} {param.keyUnit} </Typography>}
                                    variant="outlined"
                                />
                            }
                            {!param.keyName &&
                                <Chip
                                    color={color}
                                    label={<Typography variant='caption'>{param.key}: {param.value}</Typography>}
                                    variant="outlined"
                                />
                            }
                        </div>
                    </Box>
                ))}
            </div>
        )
    }


    function renderDetailPanel(component: ComponentDto) {

        const isTemplate = getIsTemplate(component);
        const isVgn = getIsVgn(component);

        return (

            <div className={classes.detailPanel}>

                <Paper>

                    <ComponentParamsEditor
                        readOnly={!isTemplate}
                        isVgn={isVgn}
                        dictionary={getParamsDictionary(component)}
                        onSave={(paramsDictionary) => {
                            if (isTemplate) {
                                updateDeviceComponent({
                                    deviceId: deviceDetail?.id ?? '',
                                    componentId: component.id,
                                    component: { payload: paramsDictionary }
                                })
                            }
                        }} />

                    {isVgn && (

                        <div className={classes.vgnParams}>

                            {enumerateComponentParams(component, {includeVgn: true}).map((item, index) => (
                                <TextField
                                    className={classes.vgnParamMargin}
                                    variant='outlined'
                                    id={item.key}
                                    label={item.key}
                                    value={item.value}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true }}>
                                </TextField>
                            ))}

                        </div>
                    )}

                </Paper>

                <div className={classes.noteDetailPanel}>

                    <Typography
                        className={classes.textField}
                        style={{ marginBottom: 10 }}
                        variant='subtitle1'>
                        Manufacturer: {component.componentPrototype?.manufacturer ?? 'N/A'}
                        {component.componentPrototype?.manufacturerUrl &&
                            <Link
                                className={classes.textField}
                                target='_blank'
                                rel='noopener'
                                href={`${component.componentPrototype?.manufacturerUrl}`}>
                                ({component.componentPrototype?.manufacturerUrl ?? 'N/A'})
                                </Link>}
                    </Typography>

                    <NoteCard
                        readOnly={false}
                        text={component.note}
                        onSave={(note) => updateDeviceComponent({
                            deviceId: deviceDetail?.id ?? '',
                            componentId: component.id,
                            component: { note }
                        })}
                    />


                </div>

            </div>
        )
    }

    // input changes
    const handlePrototypeChange = (prototype?: ComponentPrototypeDto) => {
        setValues({ ...values, prototype: prototype });
    };


    const handleAddComponent = (newData: ComponentDto): Promise<void> =>
        new Promise((resolve, reject) => {
            if (values.prototype) {

                const isTemplate = values.prototype.isTemplate ?? false;

                addDeviceComponent({
                    deviceId: deviceDetail?.id ?? '',
                    component: {
                        componentPrototypeId: values.prototype?.id,
                        note: newData.note,
                        serialNumber: newData.serialNumber,

                        // copy prototypes from prototype
                        payload: values.prototype?.payload
                    }
                });

                resolve();
            }
            else {
                reject('Component prototype must be selected...');
            }
        });

    const handleUpdateComponent = (newData: ComponentDto, oldData?: ComponentDto): Promise<void> =>
        new Promise((resolve, reject) => {
            updateDeviceComponent({
                deviceId: deviceDetail?.id ?? '',
                componentId: oldData?.id ?? '',
                component: {
                    note: newData.note,
                    serialNumber: newData.serialNumber,
                }
            });
            resolve();
        });

    const handleDeleteComponent = (oldData: ComponentDto): Promise<void> =>
        new Promise((resolve, reject) => {
            deleteDeviceComponent({
                deviceId: deviceDetail?.id ?? '',
                componentId: oldData.id,
            });
            resolve();
        });

    const handleUpdateComponentNote = (prop: keyof CreateDeviceDto, value: string) => {
        setValues({ ...values, categoryNote: value });
        updateDevice({
            deviceId: deviceDetail?.id ?? '',
            device: { [prop]: value }
        })
    };


    // define columns
    const columns: Column<ComponentDto>[] = [
        {
            title: 'Type',
            width: 200,
            field: 'componentPrototype.componentType.name',
            editable: 'never',
        },
        {
            title: 'Name',
            width: 300,
            field: 'componentPrototype.name',
            editable: 'onAdd',
            editComponent: props => (
                <Autocomplete
                    id='device-type'
                    style={{ width: 600 }}
                    options={filteredPrototyopes}
                    getOptionLabel={(option) => [option.isTemplate ? 'Template' : 'Prototype', option.name, option.componentType?.name].join(' - ')}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label='Select'
                            variant='standard' />}
                    value={values.prototype ?? null}
                    onChange={(evt, selected) => handlePrototypeChange(selected ?? undefined)}
                />
            )
        },
        {
            title: 'SN',
            width: 100,
            field: 'serialNumber',
            editable: 'onUpdate'
        },
        {
            title: 'Parameters',
            width: '100%',
            render: (component) => renderParameters(component)
        },
    ];


    // render
    return (
        <div className={classes.contentBlock}>

            <MaterialTable
                title={props.title}
                isLoading={isLoading}
                columns={columns}
                data={filteredComponents}
                options={{
                    filtering: false,
                    search: false,
                    paging: false,
                    addRowPosition: 'first',
                }}
                detailPanel={[
                    rowData => ({
                        tooltip: 'Detail info',
                        icon: () => (<ExpandMoreIcon color={rowData.note ? 'secondary' : 'disabled'} />),
                        render: (rowData) => renderDetailPanel(rowData),
                    }),
                ]}
                editable={{
                    onRowAdd: handleAddComponent,
                    onRowUpdate: handleUpdateComponent,
                    onRowDelete: handleDeleteComponent,
                }}
                onRowClick={(event, rowData, togglePanel) => { if (togglePanel) togglePanel() }}
            >
            </MaterialTable>

            <Accordion expanded={isNoteExpanded} onChange={(e, expanded) => setIsNoteExpanded(expanded)}>

                <AccordionSummary className={classes.notePanel}>
                    <ExpandMoreIcon
                        className={isNoteExpanded ? classes.noteIconExpanded : classes.noteIconCollapsed}
                        color={values.categoryNote ? 'secondary' : 'disabled'} />
                    <Typography variant='body1'>{`${props.title} notes: ${getNotePreview(values.categoryNote)}`}</Typography>
                </AccordionSummary>

                <AccordionDetails className={classes.notePanel}>
                    <NoteCard
                        readOnly={false}
                        text={values.categoryNote}
                        onSave={(note) => { handleUpdateComponentNote(props.categoryNoteKey, note) }}
                    />
                </AccordionDetails>

            </Accordion>


        </div >
    )
}

export default ComponentParams;