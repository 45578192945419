import { http } from './httpClient';
import { DeviceTypeDto, CreateDeviceTypeDto } from './dataContract'

const baseRoute = '/devices/types';

const routes = {
    fetch: `${baseRoute}`,
    create: baseRoute,    
    update: (typeId: string) => `${baseRoute}/${typeId}`,
    delete: (typeId: string) => `${baseRoute}/${typeId}`,
}

export interface DeviceTypeClient {
    fetch(): Promise<DeviceTypeDto[]>,
    create(payload: { deviceType: CreateDeviceTypeDto }): Promise<DeviceTypeDto>,
    update(payload: { typeId: string, deviceType: CreateDeviceTypeDto }): Promise<DeviceTypeDto>,
    delete(payload: { typeId: string }): Promise<boolean>,
}

const deviceTypeClient: DeviceTypeClient = {
    fetch: () => http.get(routes.fetch),
    create: (payload) => http.post(routes.create, payload.deviceType),
    update: (payload) => http.put(routes.update(payload.typeId), payload.deviceType),
    delete: (payload) => http.delete(routes.delete(payload.typeId)),
}

export default deviceTypeClient;