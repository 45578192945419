import React from 'react';
import { RouteComponentProps, Route, Redirect } from "react-router";
import pstore, { Key } from '../store/localStore';
import routes from '../routes';

const PrivateRoute: React.FC<{
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    path: string;
    exact?: boolean;
}> = (props) => {

    return pstore.get(Key.Token) !== undefined
        ? (<Route path={props.path} exact={props.exact} component={props.component} />)
        : (<Redirect to={routes.login} />);
}

export default PrivateRoute;
