import { http } from './httpClient';
import { ComponentTypeDto, CreateComponentTypeDto } from './dataContract'

const baseRoute = `/devices/components/types`;

const routes = {
    fetch: baseRoute,
    create: baseRoute,
    update: (typeId: string) => `${baseRoute}/${typeId}`,
    delete: (typeId: string) => `${baseRoute}/${typeId}`,
}

export interface ComponentTypeClient {
    fetch(): Promise<ComponentTypeDto[]>,
    create(payload: { componentType: CreateComponentTypeDto }): Promise<ComponentTypeDto>
    update(payload: { typeId: string, componentType: CreateComponentTypeDto }): Promise<ComponentTypeDto>
    delete(payload: { typeId: string }): Promise<boolean>
}

const componentTypeClient: ComponentTypeClient = {
    fetch: () => http.get(routes.fetch),
    create: (payload) => http.post(routes.create, payload.componentType),
    update: (payload) => http.put(routes.update(payload.typeId), payload.componentType),
    delete: (payload) => http.delete(routes.delete(payload.typeId)),
}

export default componentTypeClient;