import { action, Action, Thunk, thunk } from 'easy-peasy';
import { StoreContext } from './';
import store, { Injections } from '../store';

// dto
import { CreateSoftwareDto, SoftwareBriefDto, SoftwareQueryDto, CreateSoftwareVersionDto, SoftwareVersionDto, SoftwareModuleDto, CreateSoftwareModuleDto, UserDto, SoftwareWatcherDto } from '../service/dataContract';
import { StoreMode, storeValue } from '../utils/storageUtils';

// device store interface
export interface SoftwareModel {

    isLoading: boolean,
    softwares: SoftwareBriefDto[],

    setLoading: Action<SoftwareModel, boolean>,

    fetchSoftwares: Thunk<SoftwareModel, { query: SoftwareQueryDto }, Injections, StoreContext>,
    getSoftware: Thunk<SoftwareModel, { softwareId: string }, Injections, StoreContext, Promise<SoftwareBriefDto>>,
    addSoftware: Thunk<SoftwareModel, { software: CreateSoftwareDto }, Injections, StoreContext>,
    updateSoftware: Thunk<SoftwareModel, { softwareId: string, software: CreateSoftwareDto }, Injections, StoreContext>,
    deleteSoftware: Thunk<SoftwareModel, { softwareId: string }, Injections, StoreContext>,

    addSoftwareVersion: Thunk<SoftwareModel, { softwareId: string, version: CreateSoftwareVersionDto }, Injections, StoreContext>,
    updateSoftwareVersion: Thunk<SoftwareModel, { version: SoftwareVersionDto }, Injections, StoreContext>,
    deleteSoftwareVersion: Thunk<SoftwareModel, { version: SoftwareVersionDto }, Injections, StoreContext>,

    addSoftwareModule: Thunk<SoftwareModel, { softwareId: string, module: CreateSoftwareModuleDto }, Injections, StoreContext>,
    updateSoftwareModule: Thunk<SoftwareModel, { module: SoftwareModuleDto }, Injections, StoreContext>,
    deleteSoftwareModule: Thunk<SoftwareModel, { module: SoftwareModuleDto }, Injections, StoreContext>,

    updateSoftwareWatchers: Thunk<SoftwareModel, { softwareId: string, watchers: UserDto[] }, Injections, StoreContext>,

    clear: Action<SoftwareModel>,
    storeList: Action<SoftwareModel, { softwares: SoftwareBriefDto[] }>,
    storeSoftware: Action<SoftwareModel, { mode: StoreMode, software?: SoftwareBriefDto, softwareId?: string }>,
    storeSoftwareVersion: Action<SoftwareModel, { mode: StoreMode, version?: SoftwareVersionDto, softwareId?: string }>,
    storeSoftwareModule: Action<SoftwareModel, { mode: StoreMode, module?: SoftwareModuleDto, softwareId?: string }>,
    storeSoftwareWatcher: Action<SoftwareModel, { mode: StoreMode, watcher?: SoftwareWatcherDto, softwareId?: string }>,
};

const softwareModel: SoftwareModel = {

    isLoading: false,
    softwares: [],

    fetchSoftwares: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { softwareClient } = injections;
        actions.setLoading(true);

        await softwareClient.fetch({ query: payload.query })
            .then(softwares => {
                actions.storeList({ softwares });
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to fetch software list.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    getSoftware: thunk(async (actions, payload, { injections }) => {

        const { softwareClient } = injections;
        return await softwareClient.get(payload)
    }),

    // software CRUD
    addSoftware: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { softwareClient } = injections;
        actions.setLoading(true);

        return await softwareClient.create(payload)
            .then(software => {
                actions.storeSoftware({ mode: 'insert', softwareId: software.id, software });
                getStoreActions().audit.notify({ severity: 'info', message: `Software ${payload.software.name} has been sucessfully created.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create software: ${payload.software.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

    updateSoftware: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { softwareClient } = injections;
        actions.setLoading(true);

        return await softwareClient.update(payload)
            .then(software => {
                actions.storeSoftware({ mode: 'update', softwareId: payload.softwareId, software });
                getStoreActions().audit.notify({ severity: 'info', message: `Software has been sucessfully updated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update software: ${payload.software.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

    deleteSoftware: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { softwareClient } = injections;
        actions.setLoading(true);

        return await softwareClient.delete(payload)
            .then(_ => {
                actions.storeSoftware({ mode: 'delete', softwareId: payload.softwareId });
                getStoreActions().audit.notify({ severity: 'info', message: `Software has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete software.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

    // version CRUD  
    addSoftwareVersion: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { softwareClient } = injections;
        actions.setLoading(true);

        return await softwareClient.createVersion(payload)
            .then(version => {
                actions.storeSoftwareVersion({ mode: 'insert', softwareId: payload.softwareId, version });
                getStoreActions().audit.notify({ severity: 'info', message: `Software version ${payload.version.version} has been sucessfully created.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create software version: ${payload.version.version}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

    updateSoftwareVersion: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { softwareClient } = injections;
        actions.setLoading(true);

        return await softwareClient.updateVersion({ versionId: payload.version.id, version: payload.version })
            .then(version => {
                actions.storeSoftwareVersion({ mode: 'update', version });
                getStoreActions().audit.notify({ severity: 'info', message: `Software version ${payload.version.version} has been sucessfully updated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update software version: ${payload.version.version}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

    deleteSoftwareVersion: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { softwareClient } = injections;
        actions.setLoading(true);

        return await softwareClient.deleteVersion({ versionId: payload.version.id ?? '' })
            .then(_ => {
                actions.storeSoftwareVersion({mode: 'delete',version: payload.version });
                getStoreActions().audit.notify({ severity: 'info', message: `Software version has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete software version: ${payload.version.version}`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

    // modules CRUD  
    addSoftwareModule: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { softwareClient } = injections;
        actions.setLoading(true);
            return await softwareClient.createModule(payload)
            .then(module => {
                actions.storeSoftwareModule({ mode: 'insert', softwareId: payload.softwareId, module });
                getStoreActions().audit.notify({ severity: 'info', message: `Software module ${payload.module.name} has been sucessfully created.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create software module: ${payload.module.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),
    
    updateSoftwareModule: thunk(async (actions, payload, { injections, getStoreActions }) => {
    
        const { softwareClient } = injections;
        actions.setLoading(true);
    
        return await softwareClient.updateModule({ moduleId: payload.module.id, module: payload.module })
            .then(module => {
                actions.storeSoftwareModule({ mode: 'update', module });
                getStoreActions().audit.notify({ severity: 'info', message: `Software module ${payload.module.name} has been sucessfully updated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update software module: ${payload.module.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),
    
    deleteSoftwareModule: thunk(async (actions, payload, { injections, getStoreActions }) => {
    
        const { softwareClient } = injections;
        actions.setLoading(true);
    
        return await softwareClient.deleteModule({ moduleId: payload.module.id ?? '' })
            .then(_ => {
                actions.storeSoftwareModule({mode: 'delete', module: payload.module });
                getStoreActions().audit.notify({ severity: 'info', message: `Software module has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete software module: ${payload.module.name}`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

    // watchers
    updateSoftwareWatchers: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { softwareClient } = injections;
        actions.setLoading(true);
            return await softwareClient.updateWatchers({...payload})
            .then(software => {
                actions.storeSoftware({ mode: 'update', softwareId: payload.softwareId, software });
                getStoreActions().audit.notify({ severity: 'info', message: `Software watchers has been sucessfully configured.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not configure software watchers.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),        
   
    setLoading: action((store, payload) => { store.isLoading = payload; }),

    // software store actions
    clear: action((store) => { store.softwares = []; }),

    storeSoftware: action((store, payload) => {
        store.softwares = storeValue(store.softwares, 'id', { mode: payload.mode, id: payload.softwareId, item: payload.software });
    }),

    storeList: action((store, payload) => {
        store.softwares = payload.softwares ?? [];
    }),

    storeSoftwareVersion: action((store, payload) => {
        const swId = store.softwares.find(sw => sw.softwareVersions?.some(item => item.id === payload.version?.id))?.id ?? payload.softwareId;
        var software = store.softwares.find(sw => sw.id == swId)
        if (software) {
            software.softwareVersions = storeValue(software.softwareVersions ?? [], 'id', { mode: payload.mode, id: payload.version?.id, item: payload.version });
        }
    }),

    storeSoftwareModule: action((store, payload) => {
        const swId = store.softwares.find(sw => sw.softwareModules?.some(item => item.id === payload.module?.id))?.id ?? payload.softwareId;
        var software = store.softwares.find(sw => sw.id == swId)
        if (software) {
            software.softwareModules = storeValue(software.softwareModules ?? [], 'id', { mode: payload.mode, id: payload.module?.id, item: payload.module });
        }
    }),

    storeSoftwareWatcher: action((store, payload) => {
        const swId = store.softwares.find(sw => sw.softwareWatchers?.some(item => item.id === payload.watcher?.id))?.id ?? payload.softwareId;
        var software = store.softwares.find(sw => sw.id == swId)
        if (software) {
            software.softwareWatchers = storeValue(software.softwareWatchers ?? [], 'id', { mode: payload.mode, id: payload.watcher?.id, item: payload.watcher });
        }
    }),

}

export default softwareModel;
