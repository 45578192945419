import { http, fileIOTimeout } from './httpClient';
import { ServiceOperationDto, CreateServiceOperationDto, NoteDto, CreateNoteDto, AttachedFileDto, AttachedFileBriefDto } from './dataContract'

const baseRoute = (deviceId: string) => `/devices/${deviceId}/serviceOperations`;

const routes = {
    create: (deviceId: string) => `${baseRoute(deviceId)}`,
    update: (deviceId: string, serviceId: string) => `${baseRoute(deviceId)}/${serviceId}`,
    delete: (deviceId: string, serviceId: string) => `${baseRoute(deviceId)}/${serviceId}`,

    createNote: (deviceId: string, serviceId: string) => `${baseRoute(deviceId)}/${serviceId}/notes`,
    updateNote: (deviceId: string, serviceId: string, noteId: string) => `${baseRoute(deviceId)}/${serviceId}/notes/${noteId}`,
    deleteNote: (deviceId: string, serviceId: string, noteId: string) => `${baseRoute(deviceId)}/${serviceId}/notes/${noteId}`,

    uploadFile: (deviceId: string, serviceId: string) => `${baseRoute(deviceId)}/${serviceId}/attachedFiles`,
    downloadFile: (deviceId: string, serviceId: string, fileId: string) => `${baseRoute(deviceId)}/${serviceId}/attachedFiles/${fileId}`,
    deleteFile: (deviceId: string, serviceId: string, fileId: string) => `${baseRoute(deviceId)}/${serviceId}/attachedFiles/${fileId}`,
}

export interface ServiceOperationClient {
    create(payload: { deviceId: string, service: CreateServiceOperationDto }): Promise<ServiceOperationDto>,
    update(payload: { deviceId: string, serviceId: string, service: CreateServiceOperationDto }): Promise<ServiceOperationDto>,
    delete(payload: { deviceId: string, serviceId: string }): Promise<boolean>,

    createNote(payload: { deviceId: string, serviceId: string, note: CreateNoteDto }): Promise<NoteDto>,
    updateNote(payload: { deviceId: string, serviceId: string, noteId: string, note: CreateNoteDto }): Promise<NoteDto>,
    deleteNote(payload: { deviceId: string, serviceId: string, noteId: string }): Promise<boolean>,

    uploadFile(payload: { deviceId: string, serviceId: string, formData: FormData, uploadProgress?: (event: ProgressEvent) => void }): Promise<AttachedFileBriefDto>,
    downloadFile(payload: { deviceId: string, serviceId: string, fileId: string, downloadProgress?: (event: ProgressEvent) => void }): Promise<AttachedFileDto>,
    deleteFile(payload: { deviceId: string, serviceId: string, fileId: string }): Promise<boolean>,
}

const serviceOperationClient: ServiceOperationClient = {

    create: (payload) => http.post(routes.create(payload.deviceId), payload.service),
    update: (payload) => http.put(routes.update(payload.deviceId, payload.serviceId), payload.service),
    delete: (payload) => http.delete(routes.delete(payload.deviceId, payload.serviceId)),

    createNote: (payload) => http.post(routes.createNote(payload.deviceId, payload.serviceId), payload.note),
    updateNote: (payload) => http.put(routes.updateNote(payload.deviceId, payload.serviceId, payload.noteId), payload.note),
    deleteNote: (payload) => http.delete(routes.deleteNote(payload.deviceId, payload.serviceId, payload.noteId)),


    uploadFile: (payload) => http.post(
        routes.uploadFile(payload.deviceId, payload.serviceId),
        payload.formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: payload.uploadProgress,
            timeout: fileIOTimeout,
        }),

    downloadFile: (payload) => http.get(
        routes.downloadFile(payload.deviceId, payload.serviceId, payload.fileId),
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onDownloadProgress: payload.downloadProgress,
            timeout: fileIOTimeout,
        }),

    deleteFile: (payload) => http.delete(routes.deleteFile(payload.deviceId, payload.serviceId, payload.fileId)),

}

export default serviceOperationClient;