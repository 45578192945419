import React from 'react';
import { useHistory } from 'react-router';

// store
import { useStoreActions } from '../../hooks'

// mui
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, FormControl, InputLabel, Input, InputAdornment, Container, Grid } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// css
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import routes from '../../routes';

// validations
import { validateEmail, validatePassword } from '../../utils/validationUtils';
import { useValidator, ValidationRules } from '../../utils/validations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
    },
    error: {
      color: theme.palette.error.main,
      width: '100%',
      textAlign: 'center',
      margin: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

interface Props {
}

interface State {
  password: string;
  userName: string;
  showPassword: boolean;
}

const rules: ValidationRules<State> = {
  userName: (value) => validateEmail(value, 'enter a valid email address'),
}


export const Login: React.FC<Props> = (props: Props) => {

  // common
  let history = useHistory();
  const classes = useStyles();

  // store | global state
  const { login, logout } = useStoreActions(state => state.auth)

  // local state
  const [values, setValues] = React.useState<State>({
    userName: '',
    password: '',
    showPassword: false,
  });

  const [isValid, validation, setValidation] = useValidator(rules, values);

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    setValidation(values, prop, event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  async function handleLogin() {
    await login({
      login: {
        password: values.password,
        userName: values.userName
      }
    });

    history.push(routes.home);
  }

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key == 'Enter' && isValid) {
      handleLogin();
    }
  }


  return (

    <Container component="main" maxWidth="xs">

      <CssBaseline />

      <div className={classes.paper}>

        <form className={classes.form} noValidate autoComplete="on" >

          <Grid container>

            <Grid item xs={12} sm={6} >
              <FormControl className={clsx(classes.margin, classes.textField)} >
                <InputLabel htmlFor="userName">Email</InputLabel>
                <Input
                  autoComplete='userName'
                  id='userName'
                  type='text'
                  error={!validation.userName?.isValid}
                  value={values.userName}
                  onChange={handleChange('userName')}
                  onKeyDown={handleEnter}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl className={clsx(classes.margin, classes.textField)}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  onKeyDown={handleEnter}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>

            <div className={classes.error}>
              {!isValid && (validation.password?.helperText ?? validation.userName?.helperText)}
            </div>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!isValid}
              onClick={() => { handleLogin() }}>Login
            </Button>

          </Grid>

        </form>

      </div>
    </Container>
  )
}
