import React, { useEffect, useState } from 'react';

// mui
import { Fab, IconButton, List, ListItem, TextField, Typography, } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

// dto
import { KeyValuesDto } from '../service/dataContract';
import { useStoreActions, useStoreState } from '../hooks';
import { buildDictionary, enumerateParameters } from '../utils/componnetParametrs'
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.paper,
    },
    parameter: {
        display: 'flex',
        flexDirection: 'row',
    },
    textField: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: 'relative',
        margin: theme.spacing(1),
    },
    help: {
        margin: theme.spacing(1, 0, 1, 3),
        width: '50%'
    }
}));

export interface Props {
    prototypeId?: string,
    isVgn: boolean,
}

interface Parameter {
    id: number,
    key: string,
    value: string,
}

interface State {
    changed: boolean,
    paramList: Parameter[],
}

export const ComponentAdminParams: React.FC<Props> = props => {

    const classes = useStyles();
    const vgnMandatoryField = 'Radio MAC';
    const { componentPrototypes } = useStoreState(state => state.component);
    const { updateComponentPrototype } = useStoreActions(state => state.component);

    const [values, setValues] = useState<State>({
        changed: false,
        paramList: getPrototypeList(props.prototypeId),
    });

    function getPrototypeList(prototypeId?: string) {
        let dictionary = componentPrototypes.find(p => p.id === prototypeId)?.payload;
        return enumerateParameters(dictionary);
    }

    function isVgnMandatory(parameter: Parameter) : boolean {
        return props.isVgn && parameter.key === vgnMandatoryField;
    }

    const addEntry = (key: string, value: string) => {
        const paramList = values.paramList;
        const someEmpty = paramList.find(p => p.key == '');
        if (!someEmpty) {
            paramList.push({
                id: paramList.length + 1,
                key: '',
                value: '',
            });
            setValues({ ...values, paramList: paramList, changed: true });
        }
    }

    const removeEntry = (id: number) => {
        let paramList = values.paramList.filter(p => p.id !== id);
        setValues({ ...values, paramList: paramList, changed: true });
    }

    const cancelChange = (id: number) => {
        const paramList = values.paramList;
        let item = paramList.find(p => p.id == id);
        let original = getPrototypeList(props.prototypeId).find(p => p.id == id);

        if (item && original) {
            item.key = original.key;
            item.value = original.value;
            setValues({ ...values, paramList: paramList });
        }
    }

    const changeParameter = (id: number, prop: keyof Parameter) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const paramList = values.paramList;
        let item = paramList.find(p => p.id == id);
        if (item) {
            // @ts-ignore (allow access by prop key)
            item[prop] = event.target.value;
            setValues({ ...values, paramList: paramList, changed: true });
        }
    }    

    const saveChanges = () => {

        updateComponentPrototype({
            prototypeId: props.prototypeId ?? '',
            prototype: {
                payload: buildDictionary(values.paramList)
            }
        });

        setValues({ ...values, changed: false });
    }

    const markdownHelp = `
### Template  
if the template is checked, then defined parameters are used as a local copy for the concrete device.  

### Prototype  
If Template is **not** checked, then defined parameters are treated as immutable and can be shared across any devices.
Parameter modification is possible in *Components Administration*.

**TIP:** Use [ unit ] in parameter for unit definition.`;

    return (
        <div className={classes.root}>

            <Fab className={classes.fab}
                onClick={() => { addEntry('', '') }}
                size='small'
                color='primary'>
                <AddIcon />
            </Fab>

            <Fab className={classes.fab}
                onClick={() => { saveChanges() }}
                size='small'
                color='secondary'
                disabled={!values.changed}>
                <DoneIcon />
            </Fab>

            <List>
                {values.paramList.map(item => (
                    <ListItem>
                        <div key={item.id} className={classes.parameter} >

                            <TextField
                                size='small'
                                className={classes.textField}
                                onChange={changeParameter(item.id, 'key')}
                                variant='outlined'
                                label='Parameter'
                                autoFocus
                                disabled={isVgnMandatory(item)}
                                value={item.key} />

                            <TextField
                                size='small'
                                className={classes.textField}
                                onChange={changeParameter(item.id, 'value')}
                                variant='outlined'
                                label='Default Value'
                                value={item.value} />

                            <IconButton
                                size='small'
                                color='secondary'
                                disabled={isVgnMandatory(item)}
                                onClick={() => cancelChange(item.id)}>
                                <ClearIcon />
                            </IconButton>

                            <IconButton
                                size='small'
                                color='secondary'
                                disabled={isVgnMandatory(item)}
                                onClick={() => removeEntry(item.id)}>
                                <DeleteIcon />
                            </IconButton>

                        </div>
                    </ListItem>
                ))}
            </List>

            <div className={classes.help}>
                <ReactMarkdown children={markdownHelp} />
            </div>
        </div>
    )
}

export default ComponentAdminParams;