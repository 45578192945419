
import { VgnDto } from './dataContract'
import { http, fileIOTimeout } from './httpClient';

const baseRoute = '/devices/components/vgn';

const routes = {
    fetch: () => `${baseRoute}`,
    import: () => `${baseRoute}/import`
}

export interface VgnClient {
    fetch(): Promise<VgnDto[]>,
    import(payload: { formData: FormData, uploadProgress?: (event: ProgressEvent) => void }): Promise<VgnDto[]>,
}

const vgnClient: VgnClient = {
    fetch: () => http.get(routes.fetch()),
    import: (payload) => http.post(
        routes.import(),
        payload.formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: payload.uploadProgress,
            timeout: fileIOTimeout,
        }),
}

export default vgnClient;