import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// store
import { useStoreActions, useStoreState } from '../../hooks';
import { Brand, CreateLicenseDto, DeviceBriefDto, LicenseType, SoftwareBriefDto, LicenseModule, LicencePrefferedVersion, VgnBoardDto, CodeDto } from '../../service/dataContract';

// mui
import { Box, Button, FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

// dtos
import { Language, Country, resolveCountry } from '../../model/codelist';

// components
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import { DateFormat } from '../../utils/datetime';
import { useStateValidator, ValidationResult } from '../../utils/validationHook';
import { ValidationRules } from '../../utils/validations';
import { validateDefined, validateInput } from '../../utils/validationUtils';

import CountryInput from '../CountryInput';
import MultipleLanguageInput from '../MultipleLanguageInput';
import MultipleCountryInput from '../MultipleCountryInput';
import MultipleVgnBoardInput from '../MultipleVgnBoardInput';
import LicenseModuleTransferList from './LicenseModuleTransferList';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 360,
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
    },
    card: {
        marginTop: theme.spacing(2),
    },
    inputField: {
        margin: theme.spacing(2),
    },
}));

interface Props { }

interface State extends CreateLicenseDto {
    licenseFile?: File,
    device?: DeviceBriefDto,
    software?: SoftwareBriefDto,
}

// setup validation rules
const rules: ValidationRules<State> = {
    software: (value) => validateDefined(value, 'Software name must be defined'),
    ownerName: (value) => validateInput(value, 1, 'Owner name must be defined.'),
    ownerCountryCode: (value) => validateInput(value, 2, 'Owner country must be defined'),
}

const validateLicenseFile = (state: State): ValidationResult => {
    switch (state.licenseType) {
        case LicenseType.KZV:
            return { isValid: true };
        default:
            if (state.licenseFile)
                return { isValid: true };
            else
                return { isValid: false, helperText: 'License file must be attached' };
            break;
    }
}

export const CreateLicenseCard: React.FC<Props> = props => {

    // styles
    const classes = useStyles();
    const fileRef = useRef<HTMLInputElement>(null);
    const history = useHistory();
    const location = useLocation();

    // store
    const { addLicense } = useStoreActions(state => state.license);
    const { isLoading } = useStoreState(state => state.license);
    const { isKzv } = useStoreState(state => state.auth);
    const { devices } = useStoreState(state => state.deviceFilter);
    const { fetchDevicesChange } = useStoreActions(state => state.deviceFilter);
    const { softwares } = useStoreState(state => state.software);
    const { fetchSoftwares } = useStoreActions(state => state.software);

    const [hasError, validation, state, setState] = useStateValidator<State>(
        rules,
        {
            id: '',
            version: LicencePrefferedVersion,
            licenseType: LicenseType.KZV,
            expiresAt: null,
            notes: '',
            internalDescription: '',

            trialDays: 30,
            brand: Brand.KZV,

            ownerName: '',
            ownerCountryCode: '',
            ownerCountry: '',

            allowedLanguages: [],
            allowedRegions: [],
            allowedVgnBoards: [],

            moduleValues: [],
        }
    );

    const [allowedLanguages, setAllowedLanguages] = useState<Language[]>([]);
    const [allowedRegions, setAllowedRegions] = useState<Country[]>([]);
    const [allowedVgns, setAllowedVgns] = useState<VgnBoardDto[]>([]);

    const isFileLicense = () => state.licenseType === LicenseType.File;
    const isKzvLicense = () => state.licenseType === LicenseType.KZV;
    const isKzvLicense5AndAbove = () => state.version >= '5.0';
    const allowVgnsConfig = () => isKzvLicense5AndAbove() && state.software?.allowVgnsConfig;
    const allowRegionsConfig = () => isKzvLicense5AndAbove() && state.software?.allowRegionsConfig;

    useEffect(() => {
        fetchDevicesChange();
    }, [fetchDevicesChange]);

    useEffect(() => {
        fetchSoftwares({ query: {} });
    }, [fetchSoftwares]);

    useEffect(() => {
        const forDevice = devices.find(dev => dev.serialNumber == location.state);
        handleDeviceChange(forDevice);
    }, [devices]);

    const handleStateChange = (prop: keyof State) => (event: React.ChangeEvent<{ value: HTMLInputElement | unknown }>) => {
        setState({ ...state, [prop]: event.target.value })
    };

    const handleSelectionChange = (prop: keyof State) => (event: React.ChangeEvent<{ value: unknown }>) => {
        //@ts-ignore
        setState({ ...state, [prop]: event.target.value })
    };

    const handleExpirationDateChange = (date: Date | null) => setState({ ...state, expiresAt: date });

    const handleDeviceChange = (value?: DeviceBriefDto) => {
        setState({
            ...state,
            device: value,
            ownerName: value?.contact?.companyName ?? '',
            ownerCountryCode: value?.contact?.countrySymbol ?? '',
            ownerCountry: resolveCountry(value?.contact?.countryCode).name
        });
    };

    const handleCountryChange = (country: Country | null) => {
        setState({
            ...state,
            ownerCountryCode: country?.code ?? '',
            ownerCountry: country?.name ?? ''
        })
    };

    const handleSoftwareChange = (value?: SoftwareBriefDto) => {
        setState({ ...state, software: value, moduleValues: [] });
    };

    const handleSelectLicenseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = (event.target.files ?? [])[0];
        if (selectedFile) {
            event.target.value = '';
            setState({ ...state, licenseFile: selectedFile });
        }
    }

    const handleRemoveLicenseFile = () => {
        setState({ ...state, licenseFile: undefined });
    }

    const handleLicenseModuleChange = (selection: LicenseModule[]) => {
        setState({ ...state, moduleValues: selection });
    };

    const handleAllowedLanguagesChange = (selection: Language[]) => {
        setState({ ...state, allowedLanguages: selection.map<CodeDto>(lang => { return { ...lang } }) });
        setAllowedLanguages(selection);
    }

    const handleAllowedRegionsChange = (selection: Country[]) => {
        setState({ ...state, allowedRegions: selection.map<CodeDto>(region => { return { ...region } }) });
        setAllowedRegions(selection);
    }

    const handleAllowedVgnsChange = (selection: VgnBoardDto[]) => {
        setState({ ...state, allowedVgnBoards: selection });
        setAllowedVgns(selection);
    }

    // event handlers
    const handleCreate = () => {
        const license: CreateLicenseDto = {
            ...state,
            version: isFileLicense() ? 'file' : state.version,
            softwareId: state.software?.id,
            deviceId: state.device?.id,
        };

        addLicense({ license }).finally(() => {
            history.goBack();
        });
    }


    return (
        <div className={classes.root}>

            <Paper className={classes.paper}>

                <Typography
                    className={classes.title}
                    variant='h6'
                    align='center'>NEW LICENSE</Typography>

                <Box display='flex' flexDirection='row'>

                    <FormControl className={classes.formControl}>
                        <InputLabel id="license-type-label">License Type</InputLabel>
                        <Select
                            labelId="license-type-label"
                            value={state.licenseType}
                            onChange={handleSelectionChange('licenseType')}
                        >
                            <MenuItem value={LicenseType.File}>{LicenseType[LicenseType.File]}: (deprecated krab license) </MenuItem>
                            <MenuItem value={LicenseType.KZV}>{LicenseType[LicenseType.KZV]}</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            fullWidth
                            id='software-identification'
                            options={softwares}
                            getOptionLabel={(option) => `${option.name}`}
                            value={state.software ?? null}
                            onChange={(_, value) => handleSoftwareChange(value ?? undefined)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={validation.software?.helperText}
                                    error={!validation.software?.isValid}
                                    label='Software Name'
                                    fullWidth
                                    variant='standard'
                                    InputLabelProps={{ shrink: true }} />}
                        />
                    </FormControl>
                </Box>


                <Box display='flex' flexDirection='row'>

                    <FormControl className={classes.formControl}>
                        <Autocomplete
                            fullWidth
                            options={devices}
                            getOptionLabel={(option) => `${option.serialNumber} - ${option.deviceType?.name}`}
                            value={state.device ?? null}
                            onChange={(evt, value) => handleDeviceChange(value ?? undefined)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Device Serial number'
                                    fullWidth
                                    variant='standard'
                                    InputLabelProps={{ shrink: true }} />}
                        />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel shrink>Brand</InputLabel>
                        <Select
                            displayEmpty
                            value={state.brand}
                            onChange={handleSelectionChange('brand')}
                        >
                            <MenuItem value={Brand.NotSet}>Not set (treated as KZV)</MenuItem>
                            <MenuItem value={Brand.KZV}>KZV</MenuItem>
                            <MenuItem value={Brand.NCCI}>NCCI</MenuItem>
                        </Select>
                    </FormControl>


                    {isKzvLicense() && (
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink>Version</InputLabel>
                            <Select
                                displayEmpty
                                value={state.version}
                                onChange={handleStateChange('version')}
                            >
                                <MenuItem value={'5.0'}>5.0 Latest</MenuItem>
                                <MenuItem value={'4.0'}>4.0</MenuItem>                                
                                <MenuItem value={'3.0'}>3.0</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </Box>

                <Box display='flex' flexDirection='row'>

                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Owner Name"
                            fullWidth
                            error={!validation.ownerName?.isValid}
                            helperText={validation.ownerName?.helperText}
                            value={state.ownerName}
                            onChange={handleStateChange('ownerName')}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ readOnly: false }} />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <CountryInput
                            label="Owner Country"
                            error={!validation.ownerCountryCode?.isValid}
                            helperText={validation.ownerCountryCode?.helperText}
                            isoCode={state.ownerCountryCode ?? ''}
                            onChange={handleCountryChange}
                        />
                    </FormControl>

                </Box>

                <FormControl className={classes.formControl}>
                    <TextField
                        fullWidth
                        label='License Notes (part of the license)'
                        variant='standard'
                        value={state.notes}
                        onChange={handleStateChange('notes')}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>

                <FormControl className={classes.formControl}>
                    <TextField
                        fullWidth
                        label='Internal Notes (service web only)'
                        variant='standard'
                        value={state.internalDescription}
                        onChange={handleStateChange('internalDescription')}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>


                {isKzvLicense() && (
                    <Box display='flex' flexDirection='row'>
                        <FormControl className={classes.formControl}>
                            <DatePicker
                                label="Expiration Date"
                                size='small'
                                clearable={true}
                                emptyLabel='never'
                                format={DateFormat}
                                value={state.expiresAt || null}
                                onChange={(date) => handleExpirationDateChange(date)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: false }} />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel>Trial Days</InputLabel>
                            <Select
                                value={state.trialDays}
                                onChange={handleSelectionChange('trialDays')}
                            >
                                <MenuItem value="0">Activated</MenuItem>
                                <MenuItem value="10">10 days</MenuItem>
                                <MenuItem value="30">30 days</MenuItem>
                                <MenuItem value="90">90 days</MenuItem>
                                <MenuItem value="120">120 days</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {isKzvLicense() && (
                    <FormControl className={classes.formControl}>
                        <LicenseModuleTransferList
                            title={`${state.software?.name ?? ''} License Modules`}
                            sourceState={state.software?.softwareModules ?? []}
                            currentState={state.moduleValues}
                            selectionChanged={handleLicenseModuleChange} />
                    </FormControl>
                )}

                {isKzvLicense5AndAbove() && (

                    <Box display="flex" flexDirection="row">

                        <FormControl className={classes.formControl}>
                            <MultipleLanguageInput
                                label='Allowed Languages'
                                selection={allowedLanguages}
                                onChange={handleAllowedLanguagesChange} />
                        </FormControl>

                        {allowRegionsConfig() && (
                            <FormControl className={classes.formControl}>
                                <MultipleCountryInput
                                    label='Allowed Regions'
                                    selection={allowedRegions}
                                    onChange={handleAllowedRegionsChange} />
                            </FormControl>
                        )}

                        {allowVgnsConfig() && (
                            <FormControl className={classes.formControl}>
                                <MultipleVgnBoardInput
                                    label='Allowed Vgns'
                                    selection={allowedVgns}
                                    onChange={handleAllowedVgnsChange} />
                            </FormControl>
                        )}
                    </Box>
                )}

                {isFileLicense() && (
                    <FormControl className={classes.formControl}>
                        <input
                            style={{ display: 'none' }}
                            name="upload-file"
                            type="file"
                            ref={fileRef}
                            onChange={handleSelectLicenseFile}
                        />

                        <InputLabel shrink>License File Attachment</InputLabel>
                        <Input
                            type='text'
                            onClick={() => { fileRef?.current?.click() }}
                            value={state.licenseFile?.name ?? ''}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={e => {
                                            handleRemoveLicenseFile();
                                            e.stopPropagation();
                                        }}                                        >
                                        <DeleteIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText error={!validateLicenseFile(state).isValid}>{validateLicenseFile(state)?.helperText}</FormHelperText>
                    </FormControl>
                )}

                {isKzv && (
                    <FormControl className={classes.formControl}>
                        <Button
                            onClick={handleCreate}
                            disabled={hasError || !validateLicenseFile(state).isValid || isLoading}
                            color='primary'
                            variant='contained'>
                            Create
                        </Button>
                    </FormControl>
                )}

            </Paper>
        </div >
    );
}

export default CreateLicenseCard;

