import React, { useEffect } from 'react';

// mui
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { FormControl, Input, InputAdornment, InputLabel, makeStyles, TextField, useTheme } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

// validations
import { validatePassword } from '../utils/validationUtils';
import { useValidator, ValidationRules } from '../utils/validations';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignContent: 'center',
            minWidth: 500,
        },
        title: {
        },
        userName: {
        },
        error: {
            color: theme.palette.error.main,
            textAlign: 'center',
            margin: theme.spacing(1),
        },
        margin: {
            margin: theme.spacing(1),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        textField: {
            width: '25ch',
        },
    }),
);

export interface Props {
    isOpen: boolean,
    userName: string,
    userId: string,
    onClose?: () => void,
    onConfirm?: (userId: string, newPassword: string) => void,
}

interface State {
    password1: string,
    showPass1: boolean,
    password2: string,
    showPass2: boolean,
    errorMessage: string,
}

const rules: ValidationRules<State> = {
    password1: (value) => validatePassword(value),
    password2: (value, state) => {
        return (value === state.password1 && validatePassword(value).isValid)
            ? { isValid: true }
            : { isValid: false, helperText: 'passwords does not match...' }
    },
}


export const PasswordDialog: React.FC<Props> = props => {

    const classes = useStyles();

    const [values, setValues] = React.useState<State>({
        errorMessage: '',
        password1: '',
        password2: '',
        showPass1: false,
        showPass2: false,
    });

    const [isValid, validation, setValidation] = useValidator(rules, values);

    const handleStateChange = (prop: keyof State, value: any) => {
        setValues({ ...values, [prop]: value });
        setValidation(values, prop, value);
    };

    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
        setValidation(values, prop, event.target.value);
    };

    function handleClose() {
        if (props.onClose) {
            props.onClose();
        }

        clear();
    }

    function handleConfirm() {
        if (props.onConfirm) {
            props.onConfirm(props.userId, values.password1);
        }
        clear();
    }

    function clear() {
        setValues({ ...values, password1: '', password2: '', showPass1: false, showPass2: false, errorMessage: '' })
    }

    return (
        <div>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.isOpen}>

                <DialogTitle className={classes.title}>
                    <div>
                        Reset Password ({props.userName})
                        <IconButton className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent dividers>

                    <form className={classes.root} autoComplete="off">

                        <FormControl className={clsx(classes.margin, classes.textField)}>
                            <InputLabel htmlFor="password1">New Password</InputLabel>
                            <Input
                                id="password1"
                                error={!validation.password1?.isValid}
                                type={values.showPass1 ? 'text' : 'password'}
                                value={values.password1}
                                onChange={handleInputChange('password1')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleStateChange('showPass1', true)}
                                            onMouseDown={() => handleStateChange('showPass1', false)}
                                        >
                                            {values.showPass1 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <FormControl className={clsx(classes.margin, classes.textField)}>
                            <InputLabel htmlFor="password2">Password Check</InputLabel>
                            <Input
                                id="password2"
                                error={!validation.password2?.isValid}
                                type={values.showPass2 ? 'text' : 'password'}
                                value={values.password2}
                                onChange={handleInputChange('password2')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleStateChange('showPass2', true)}
                                            onMouseDown={() => handleStateChange('showPass2', false)}
                                        >
                                            {values.showPass2 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        {!(isValid) && (
                            <div className={classes.error}>
                                {validation.password1?.helperText ?? validation.password2?.helperText}
                            </div>
                        )}

                    </form>
                </DialogContent>

                <DialogActions>

                    <Button
                        autoFocus
                        color='primary'
                        startIcon={<DoneIcon />}
                        disabled={!isValid} onClick={handleConfirm}>
                        Save
                    </Button>

                </DialogActions>

            </Dialog>
        </div>
    );
}

export default PasswordDialog;