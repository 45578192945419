import { Action, action, Thunk, thunk } from 'easy-peasy';
import { StoreContext } from './index';
import { CreateUserDto, UserDto } from '../service/dataContract';
import { Injections } from '../store';
import { StoreMode, storeValue } from '../utils/storageUtils';

// auth store inteface
export interface UserModel {

    // loading indication
    isLoading: boolean,

    // user list
    users: UserDto[],

    // thunks

    fetchUsers: Thunk<UserModel, void, Injections, StoreContext>,

    // creates a new user
    addUser: Thunk<UserModel, { user: CreateUserDto }, Injections, StoreContext>,

    // updates an existing user
    updateUser: Thunk<UserModel, { userId: string, user: CreateUserDto }, Injections, StoreContext>,

    // deletes an existing user
    deleteUser: Thunk<UserModel, { userId: string }, Injections, StoreContext>,

    // actions

    // stores user data
    storeUser: Action<UserModel, { mode: StoreMode, user?: UserDto, userId?: string }>
    storeUserList: Action<UserModel, { users: UserDto[] }>

    // set loading flag action
    setLoading: Action<UserModel, boolean>,

};

const userModel: UserModel = {

    isLoading: false,
    users: [],

    fetchUsers: thunk(async (actions, payload, { injections }) => {
        const { userClient } = injections;
        actions.setLoading(true);

        userClient.fetch()
            .then(response => {
                actions.storeUserList({ users: response });
            })
            .catch(err => {
                console.error('error: fetch user list ', err);
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    addUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { userClient } = injections;
        actions.setLoading(true);

        userClient.create(payload)
            .then(response => {
                actions.storeUser({ mode: 'insert', user: response });
                getStoreActions().audit.notify({ severity: 'info', message: 'User has been sucesfully created.' })
            })
            .catch(err => {
                console.error('error: create a new user', err);
                getStoreActions().audit.notify({ severity: 'error', message: 'Addindg a new user has failed.', payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    updateUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { userClient } = injections;
        actions.setLoading(true);

        userClient.update(payload)
            .then(response => {
                actions.storeUser({ mode: 'update', user: response });
                getStoreActions().audit.notify({ severity: 'info', message: 'User has been sucesfully updated.' })
            })
            .catch(err => {
                console.error('error: update user', err);
                getStoreActions().audit.notify({ severity: 'error', message: 'User update has failed.', payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    deleteUser: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { userClient } = injections;
        actions.setLoading(true);

        userClient.delete(payload)
            .then(response => {
                actions.storeUser({ mode: 'delete', userId: payload.userId });
                getStoreActions().audit.notify({ severity: 'info', message: 'User has been sucesfully deleted.' })
            })
            .catch(err => {
                console.error('error: create a new user', err);
                getStoreActions().audit.notify({ severity: 'error', message: 'User delete has failed.', payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),


    storeUser: action((store, payload) => {
        store.users = storeValue(store.users, 'id', { mode: payload.mode, id: payload.userId, item: payload.user });
    }),

    storeUserList: action((store, payload) => {
        store.users = payload.users;
    }),

    // loading
    setLoading: action((store, payload) => { store.isLoading = payload }),
}


export default userModel;