import { createStore } from 'easy-peasy';
import model from '../model';
import authClient from '../service/authClient';
import userClient from '../service/userClient';
import deviceClient from '../service/deviceClient';
import softwareClient from '../service/softwareClient';
import licenseClient from '../service/licenseClient';
import serviceClient from '../service/serviceOperationClient';
import contactClient from '../service/contactClient';
import deviceFileClient from '../service/deviceFileClient';
import deviceTypeClient from '../service/deviceTypeClient';
import componentTypeClient from '../service/componentTypeClient';
import componentPrototypeClient from '../service/componentPrototypeClient';
import componentClient from '../service/componentClient';
import bugReportClient from '../service/bugReportClient';
import vgnClient from '../service/vgnClient';
import emailClient from '../service/emailClient';
import * as dataContract from '../service/dataContract';

export interface Injections {

    // dto
    dataContract: typeof dataContract;

    // authorization
    authClient: typeof authClient;
    userClient: typeof userClient;

    // device
    deviceClient: typeof deviceClient;
    serviceClient: typeof serviceClient;
    deviceFileClient: typeof deviceFileClient;
    deviceTypeClient: typeof deviceTypeClient;
    contactClient: typeof contactClient;
    
    // software / license
    softwareClient: typeof softwareClient;
    licenseClient: typeof licenseClient;

    // device components
    componentClient: typeof componentClient;
    componentTypeClient: typeof componentTypeClient;
    componentPrototypeClient: typeof componentPrototypeClient;
    vgnClient: typeof vgnClient,

    // bug report
    bugReportClient: typeof bugReportClient;

    // email client
    emailClient: typeof emailClient,
}

// create store
const store = createStore(
    model, 
    {
    injections: {
        dataContract,
        authClient: authClient,
        userClient: userClient,
        deviceClient: deviceClient,
        serviceClient: serviceClient,
        deviceFileClient: deviceFileClient,
        deviceTypeClient: deviceTypeClient,
        contactClient: contactClient,
        softwareClient: softwareClient,
        licenseClient: licenseClient,
        componentClient: componentClient,
        componentTypeClient: componentTypeClient,
        componentPrototypeClient: componentPrototypeClient,
        vgnClient: vgnClient,
        bugReportClient: bugReportClient,
        emailClient: emailClient
    },
});

export default store;

