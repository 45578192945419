import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

// store
import { useStoreActions, useStoreState } from '../../hooks';

// mui
import { Button, FormControl, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// api
import routes from '../../routes';
import { DeviceTypeDto } from '../../service/dataContract';
import { useStateValidator, ValidationRules } from '../../utils/validationHook';
import { validateDefined, validateRx } from '../../utils/validationUtils';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: theme.spacing(2),
        alignItems: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '50%',
        minWidth: 200,
    },
    createButton: {
        margin: theme.spacing(1),
        width: '50%',
        minWidth: 200,
    },
    hint: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },

}));


interface Props { }

// form state
interface State {
    deviceType?: DeviceTypeDto,
    suggestedSn?: string,
    lastUsedSn?: string,
}

// setup validation rules
const rules: ValidationRules<State> = {
    suggestedSn: (value) => validateRx(value, /^([a-zA-Z]*\d+)*$/, 'Enter valid serial number... [opt.prefix]001'),
    deviceType: (value) => validateDefined(value, 'Select device type...'),
}

// device service tool 
export const DeviceCreate: React.FC<Props> = props => {

    const classes = useStyles();
    const history = useHistory();

    const { deviceTypes } = useStoreState(state => state.component);
    const { fetchPrototypes } = useStoreActions(state => state.component);
    const { addDevice, suggestSerialNumber } = useStoreActions(state => state.device);

    // setup validator
    const [hasError, validation, values, setValues] = useStateValidator<State>(
        rules,
        {
            lastUsedSn: '',
            suggestedSn: '',
        });

    useEffect(() => {
        console.log('Fetching device component prototypes...');
        fetchPrototypes();
    }, []);

    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    const handleDeviceTypeChange = (deviceType?: DeviceTypeDto) => {
        if (deviceType) {
            suggestSerialNumber({ deviceType })
                .then(payload => {
                    setValues({
                        ...values,
                        deviceType: deviceType ?? undefined,
                        suggestedSn: payload.suggestedSn,
                        lastUsedSn: payload.lastUsedSn
                    });
                });
        }
        else {
            setValues({ ...values, deviceType: deviceType ?? undefined });
        }
    } 

    const handleCreateDevice = async () => {
        addDevice({
            device: {
                serialNumber: values.suggestedSn,
                deviceTypeId: values.deviceType?.id,
            }
        }).then(device => {
            history.push(routes.deviceDetail.formatPath(device.id));
        });
    }


    return (
        <Paper className={classes.root}>

            <Typography variant='h6'>
                CREATE DEVICE
            </Typography>

            <form className={classes.form} autoComplete='off'>

                <FormControl className={classes.formControl}>
                    <Autocomplete
                        id='device-type'
                        options={deviceTypes}
                        getOptionLabel={(option) => option.name ?? 'N/A'}
                        value={values.deviceType}
                        onChange={(evt, selected) => handleDeviceTypeChange(selected ?? undefined)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                error={!validation.deviceType?.isValid}
                                helperText={validation.deviceType?.helperText}
                                label='Device Type'
                                variant='standard' />}
                    />

                    {values.deviceType && (
                        <Typography
                            variant='caption'
                            color='textSecondary'
                            className={classes.hint}>
                            Last used serial: {values.lastUsedSn}
                        </Typography>
                    )}

                </FormControl>

                <FormControl className={classes.formControl}>
                    <TextField
                        helperText={validation.suggestedSn?.helperText}
                        id='serial-number'
                        label='Serial Number (suggested)'
                        error={!validation.suggestedSn?.isValid}
                        value={values.suggestedSn}
                        onChange={handleInputChange('suggestedSn')}
                        InputLabelProps={{ shrink: true }} >
                    </TextField>
                </FormControl >

                <FormControl className={classes.formControl}>
                    <Button
                        disabled={hasError}
                        onClick={() => handleCreateDevice()}
                        color='primary'
                        variant='contained'>
                        Create
                    </Button>
                </FormControl>

            </form>
        </Paper >
    );
}

export default DeviceCreate;
