import React, { useEffect, useState } from 'react';

// store

// mui
import { Box, FormControlLabel, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Switch, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

// components
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker } from '@material-ui/pickers';
import { DateFormat } from '../../utils/datetime';
import { LicenseModule, SoftwareDto, SoftwareModuleDto } from '../../service/dataContract';
import Software from '../pages/Software';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    info: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 360,
    },
    srcList: {
        width: 500,
        height: 300,
        overflow: 'auto',
        margin: theme.spacing(0),
    },
    dstList: {
        width: '100%',
        height: 300,
        overflow: 'auto',
        marginLeft: theme.spacing(1),
    },
    fab: {
        position: 'absolute',
        top: 50,
        left: 300,
    },
}));

interface Props {
    title: string,
    currentState?: LicenseModule[],
    sourceState: SoftwareModuleDto[],
    selectionChanged?: (selectionState: LicenseModule[]) => void;
}

interface ModuleState extends LicenseModule {
    orderNo: number,
}

const buildInitialSet = (source: SoftwareModuleDto[]) => {
    return source
        .sort((a, b) => (a.order?? 0) - (b.order ?? 0))
        .map<ModuleState>((module, i) => {
            return { ...module, name: module.name ?? '', isEnabled: true, orderNo: i }
        });
}


export const LicenseModuleTransferList: React.FC<Props> = props => {

    // styles
    const classes = useStyles();

    const [srcModules, setSrcModules] = useState<ModuleState[]>([]);
    const [dstModules, setDstModules] = useState<ModuleState[]>([]);
    const [changed, setChanged] = useState(false);

    const hasAnyModules = () => {
        const count = srcModules.length + dstModules.length;
        return count > 0;
    }

    useEffect(() => {
        if (changed) {
            setChanged(false);
            props.selectionChanged?.(dstModules);
        }
    }, [changed]);

    useEffect(() => {

        const findByName = (data: ModuleState[] | LicenseModule[], value: ModuleState) =>
            data.find(self => self.name === value.name);

        const fullSet = buildInitialSet(props.sourceState);
        const currentSet = props.currentState ?? [];

        const src = fullSet.filter(item => !findByName(currentSet, item));
        const dst = fullSet
            .filter(item => findByName(currentSet, item))
            .map(src => {
                return {
                    ...src,
                    isEnabled: findByName(currentSet, src)?.isEnabled,
                    expiresAt: findByName(currentSet, src)?.expiresAt,
                }
            });

        setSrcModules(src);
        setDstModules(dst);
        setChanged(false);
    }, [props.sourceState, props.currentState]);


    const toggle = (value: ModuleState) => () => {
        const currentIndex = dstModules.findIndex(e => e.name === value.name);
        dstModules[currentIndex].isEnabled = !dstModules[currentIndex].isEnabled;
        setDstModules([...dstModules])
        setChanged(true);
    };

    const handleDateChange = (value: ModuleState) => (date: Date | null) => {
        const currentIndex = dstModules.findIndex(e => e.name === value.name);
        dstModules[currentIndex].expiresAt = date ?? undefined;
        setDstModules([...dstModules])
        setChanged(true);
    }

    const include = (value: ModuleState) => () => {
        value.isEnabled = true;
        setSrcModules(srcModules.filter(c => c.name !== value.name));
        setDstModules([...dstModules, value]);
        setChanged(true);
    };

    const exclude = (value: ModuleState) => () => {
        setDstModules(dstModules.filter(c => c.name !== value.name));
        setSrcModules([...srcModules, value]);
        setChanged(true);
    };

    const renderList = (items: ModuleState[], isSource: boolean, showSettings: boolean, className: string) => (
        <Paper variant='outlined' className={className}>
            <List dense>
                {items.sort((a, b) => a.orderNo > b.orderNo ? +1 : -1).map((value: ModuleState) => (
                    <ListItem key={value.name} button>
                        <ListItemIcon>
                            {isSource && (
                                <IconButton color='primary' onClick={include(value)}>
                                    <AddIcon />
                                </IconButton>
                            )}

                            {!isSource && (
                                <IconButton color='secondary' onClick={exclude(value)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </ListItemIcon>

                        <ListItemText
                            primary={value.name}
                            secondary={value.subsystem && value.subsystem}
                        />

                        {showSettings &&
                            <ListItemSecondaryAction>

                                <DatePicker
                                    style={{ marginRight: 20 }}
                                    label="Expiration Date"
                                    size='small'
                                    clearable={true}
                                    emptyLabel='never'
                                    format={DateFormat}
                                    value={value.expiresAt ?? null}
                                    onChange={handleDateChange(value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: false }} />

                                <FormControlLabel
                                    control={<Switch
                                        checked={value.isEnabled}
                                        onChange={toggle(value)}
                                        color='secondary' />}
                                    label={value.isEnabled ? 'Enabled ' : 'Disabled'} />

                            </ListItemSecondaryAction>}

                    </ListItem>
                ))}
                <ListItem />
            </List>
        </Paper>
    );


    return (
        <div className={classes.root}>

            <Typography
                variant='subtitle2'
                component='span'
                align='left'>{props.title}</Typography>

            {hasAnyModules() && <Box display="flex" flexDirection="row">
                {renderList(srcModules, true, false, classes.srcList)}
                {renderList(dstModules, false, true, classes.dstList)}
            </Box>}

            {!hasAnyModules() &&
                <Paper className={classes.info}>
                    <Typography variant='body1' color='textSecondary'>Loading ...</Typography>
                </Paper>
            }

        </div >
    );
}

export default LicenseModuleTransferList;

