import { AuthResponse, UserRoleValue } from '../service/dataContract';
import pstore, { Key } from '../store/localStore';

export interface AuthStore {
    store(auth: AuthResponse): void,
    clear(): void,

    token(): string | undefined,
    refreshToken(): string | undefined,
    userName(): string | undefined,
    fullName(): string | undefined,
    userRole(): string | undefined,
}

const authStore: AuthStore = {

    store(auth: AuthResponse) {
        pstore.set(Key.UserId, auth.id);
        pstore.set(Key.UserName, auth.userName);
        pstore.set(Key.FullName, `${auth.firstName} ${auth.lastName}`);
        pstore.set(Key.UserRole, auth.role);
        pstore.set(Key.RefteshToken, auth.refreshToken);
        pstore.set(Key.Token, auth.jwtToken);
    },

    clear() {
        pstore.reset(Key.UserId);
        pstore.reset(Key.UserName);
        pstore.reset(Key.FullName);
        pstore.reset(Key.UserRole);
        pstore.reset(Key.RefteshToken);
        pstore.reset(Key.Token);
    },

    token: () => pstore.get(Key.Token),
    refreshToken: () => pstore.get(Key.RefteshToken),
    userName: () => pstore.get(Key.UserName),
    fullName: () => pstore.get(Key.FullName),
    userRole: () => pstore.get(Key.UserRole),
}

export default authStore;