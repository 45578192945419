import { createStore, Action, action, Thunk, thunk, useStoreState } from 'easy-peasy';
import storeContext, { StoreContext } from '.';
import store, { Injections } from '../store';


// device store interface
export interface ContactState {

    // loading indication
    isLoading: boolean,

    

    
    // set loading flag action
    setLoading: Action<ContactState, boolean>,
};

const devState: ContactState = {

    isLoading: false,

    setLoading: action((store, payload) => {
        store.isLoading = payload;
    }),

}

export default devState;
