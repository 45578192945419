import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

// store
import { useStoreState, useStoreActions } from '../../hooks'

// mui
import { Button, FormControl, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

// api
import routes from '../../routes';
import { validateRx } from '../../utils/validationUtils';
import { useStateValidator, ValidationRules } from '../../utils/validationHook';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: theme.spacing(2),
        alignItems: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '50%',
        minWidth: 200,
    },
    createButton: {
        margin: theme.spacing(1),
        width: '50%',
        minWidth: 200,
    },
}));


// form state
interface State {
    suggestedSn?: string,
    lastUsedSn?: string,
}

// setup validation rules
const rules: ValidationRules<State> = {
    suggestedSn: (value) => validateRx(value, /^([a-zA-Z]*\d+)*$/, 'Enter valid serial number... (prefix)001'),
}

// url params
interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams> { }

// device service tool 
export const DeviceClone: React.FC<Props> = props => {

    const classes = useStyles();
    const history = useHistory();

    const { cloneDevice, suggestSerialNumber } = useStoreActions(state => state.device);

    const { deviceDetail } = useStoreState(state => state.device);
    const { getDeviceById } = useStoreActions(state => state.device);

    useEffect(() => {
        console.log('UseEffect: getDeviceById');
        getDeviceById({ deviceId: props.match.params.id });
    }, []);

    // setup validator
    const [hasError, validation, values, setValues] = useStateValidator<State>(
        rules,
        {
            suggestedSn: '',
        });

    useEffect(() => {
        if (deviceDetail?.deviceType) {
            suggestSerialNumber({ deviceType: deviceDetail?.deviceType })
                .then(payload => {
                    setValues({ 
                        ...values, 
                        suggestedSn: payload.suggestedSn,
                        lastUsedSn: payload.lastUsedSn,
                     });
                });
        }
    }, [deviceDetail]);


    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCloneDevice = async () => {

        /// history.push(routes.deviceDetail.formatPath("2bd3d9a1-bcdc-4bbc-94b4-2043b225b05c"));

        cloneDevice({
            deviceId: deviceDetail?.id ?? '',
            serialNumber: values.suggestedSn ?? '',
        }).then(device => {
            history.push(routes.deviceDetail.formatPath(device.id));
        });
    }

    return (
        <Paper className={classes.root}>

            <Typography variant='h6'>
                Clone Device: {deviceDetail?.deviceType?.name ?? 'N/A'} SN: {deviceDetail?.serialNumber}
            </Typography>

            <form className={classes.form} autoComplete='off'>

                <FormControl className={classes.formControl}>
                    <TextField
                        helperText={validation.suggestedSn?.helperText}
                        id='serial-number'
                        label='Serial Number (suggested)'
                        error={!validation.suggestedSn?.isValid}
                        value={values.suggestedSn}
                        onChange={handleInputChange('suggestedSn')}
                        InputLabelProps={{ shrink: true }} >
                    </TextField>
                </FormControl >

                <FormControl className={classes.formControl}>
                    <Button
                        disabled={hasError}
                        onClick={() => handleCloneDevice()}
                        color='primary'
                        variant='contained'>
                        Clone
                        </Button>
                </FormControl>

            </form>
        </Paper >
    );
}

export default DeviceClone;
