import React, { useEffect, useState } from 'react';

// mui
import { Fab, IconButton, List, ListItem, TextField, Typography, } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

// dto
import { ComponentDto, KeyValuesDto } from '../service/dataContract';
import { useStoreActions, useStoreState } from '../hooks';
import { buildDictionary, enumerateParameters } from '../utils/componnetParametrs'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.paper,        
    },
    parameter: {
        display: 'flex',
        flexDirection: 'row',
    },
    textField: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: theme.spacing(1),
    },
}));

export interface Props {
    readOnly: boolean,
    isVgn: boolean,
    dictionary?: KeyValuesDto
    onSave: (dictionary: KeyValuesDto) => void
}

interface Parameter {
    id: number,
    key: string,
    value: string,
}

interface State {
    changed: boolean,
    paramList: Parameter[],
}

export const ComponentParamsEditor: React.FC<Props> = props => {

    const classes = useStyles();
    const vgnMandatoryField = 'Radio MAC';

    const [values, setValues] = useState<State>({
        changed: false,
        paramList: getPrototypeList(props.dictionary),
    });

    function getPrototypeList(dictionary?: KeyValuesDto) {
        return enumerateParameters(dictionary);
    }

    function isVgnMandatory(parameter: Parameter): boolean {
        return props.isVgn && parameter.key === vgnMandatoryField;
    }

    const addEntry = (key: string, value: string) => {
        const paramList = values.paramList;
        const someEmpty = paramList.find(p => p.key == '');
        if (!someEmpty) {
            paramList.push({
                id: paramList.length + 1,
                key: '',
                value: '',
            });
            setValues({ ...values, paramList: paramList, changed: true });
        }
    }

    const removeEntry = (id: number) => {
        let paramList = values.paramList.filter(p => p.id !== id);
        setValues({ ...values, paramList: paramList, changed: true });
    }

    const cancelChange = (id: number) => {
        const paramList = values.paramList;
        let item = paramList.find(p => p.id == id);
        let original = getPrototypeList(props.dictionary).find(p => p.id == id);

        if (item && original) {
            item.key = original.key;
            item.value = original.value;
            setValues({ ...values, paramList: paramList });
        }
    }

    const changeParameter = (id: number, prop: keyof Parameter) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const paramList = values.paramList;
        let item = paramList.find(p => p.id == id);
        if (item) {
            // @ts-ignore (allow access by prop key)
            item[prop] = event.target.value;
            setValues({ ...values, paramList: paramList, changed: true });
        }
    }

    const saveChanges = () => {

        if (props.onSave) {
            props.onSave(buildDictionary(values.paramList));
        }

        setValues({ ...values, changed: false });
    }

    return (
        <div className={classes.root}>

            { !props.readOnly && (
                <div>
                    <Fab className={classes.fab}
                        disabled={props.readOnly}
                        onClick={() => { addEntry('', '') }}
                        size='small'
                        color='primary'>
                        <AddIcon />
                    </Fab>

                    <Fab className={classes.fab}
                        disabled={props.readOnly || !values.changed}
                        onClick={() => { saveChanges() }}
                        size='small'
                        color='secondary'>
                        <DoneIcon />
                    </Fab>
                </div>)}


            <List>

                {values.paramList.map(item => (
                    <ListItem >
                        <div key={item.id} className={classes.parameter} >

                            <TextField
                                size='small'
                                className={classes.textField}
                                onChange={changeParameter(item.id, 'key')}
                                variant='outlined'
                                label='Parameter'
                                autoFocus
                                value={item.key}
                                disabled={isVgnMandatory(item)}
                                inputProps={{ readOnly: props.readOnly }} />

                            <TextField
                                size='small'
                                className={classes.textField}
                                onChange={changeParameter(item.id, 'value')}
                                variant='outlined'
                                label='Value'
                                value={item.value}
                                inputProps={{ readOnly: props.readOnly }} />


                            <IconButton
                                size='small'
                                color='secondary'
                                disabled={props.readOnly}
                                onClick={() => cancelChange(item.id)}>
                                <ClearIcon />
                            </IconButton>

                            <IconButton
                                size='small'
                                color='secondary'
                                disabled={props.readOnly || isVgnMandatory(item)}
                                onClick={() => removeEntry(item.id)}>
                                <DeleteIcon />
                            </IconButton>

                        </div>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default ComponentParamsEditor;