import React, { useEffect } from 'react';

// store
import { useStoreActions, useStoreState } from '../../hooks';

// mui
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    backgroundColor: 'yellow',
  },
}));

interface Props {
}

interface State {
}


export const Test: React.FC<Props> = props => {

  const classes = useStyles();
  const { getDeviceById } = useStoreActions(state => state.device);
  const { deviceDetail, maintainers } = useStoreState(state => state.device);

  useEffect(() => {
    console.log('UseEffect: getDeviceById');
  }, []);


  function getDevice(devId: string) {
    getDeviceById({ deviceId: devId });
  }


  return (
    <div className={classes.root} >

    </div>
  );
}

export default Test;