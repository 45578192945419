import { useEffect, useState } from "react";

// validation result for material table
export type ValidationResult = { isValid: boolean; helperText?: string };// | string | boolean;

// validation fields (partial of T)
export type ValidationFields<T> = { [P in keyof T]?: ValidationResult };

// validation rules fields (partial of T)
export type ValidationRule<T> = ((value: T[keyof T], originalState: T) => ValidationResult)
export type ValidationRules<T> = { [P in keyof T]?: ValidationRule<T> };

// validation hook
export const useValidator = <T extends {}>(rules: ValidationRules<T>, initialState: T) => {

    // resolve initial state
    let initialFields: ValidationFields<T> = {}
    for (let [prop, rule] of Object.entries(rules)) {
        if (isValidationRule<T>(rule)) {
            initialFields[prop as keyof T] = rule(initialState[prop as keyof T], initialState);
        }
    }

    // set initial state
    const [fields, setFields] = useState(initialFields);
    const [isValid, setIsValid] = useState(checkState(fields));

    useEffect(() => {
        setIsValid(checkState(fields));
    }, [fields])

    // define validation handler
    const validateField = (originalState: T, prop: keyof T, value: T[keyof T]) => {
        setFields(resolveChange(originalState, fields, rules, prop, value));
    };

    return [isValid, fields, validateField] as const;
}

// validation result guard
function isValidationResult(vr: any): vr is ValidationResult {
    return (vr as ValidationResult) !== undefined
}

// validation rule guard
function isValidationRule<T>(vr: any): vr is ValidationRule<T> {
    return (vr as ValidationRule<T>) !== undefined
}


// validates all fields, terurns true if all is valid, otherwise false
function checkState<T>(fields: ValidationFields<T>): boolean {

    for (let value of Object.values(fields)) {
        if (!isValidationResult(value)) {
            return false;
        }
        else if (!value.isValid) {
            return false;
        }
    }

    return true;
}

// default validation solver
function resolveChange<T>(
    originalState: T,
    fields: ValidationFields<T>,
    rules: ValidationRules<T>,
    prop: keyof T,
    value: T[keyof T] ,
): ValidationFields<T> {

    // if no validation rule for prop then skip validation of field
    const rule = rules[prop];
    return rule
        ? { ...fields, [prop]: rule(value, originalState) }
        : fields;
}
