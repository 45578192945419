// react
import React from 'react';
import { useHistory, } from 'react-router';

// store
import { useStoreState, useStoreActions, } from '../../hooks'

// routes
import routes from '../../routes';

// mui
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Badge, Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Snackbar, SnackbarCloseReason, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// icons
import MenuIcon from '@material-ui/icons/Menu';
import BugReportIcon from '@material-ui/icons/BugReport';
import HomeIcon from '@material-ui/icons/Home';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import GroupIcon from '@material-ui/icons/Group';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import PhonelinkSetupOutlinedIcon from '@material-ui/icons/PhonelinkSetupOutlined';

// css
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AccountCircle } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

interface Props { }

const drawerWidth = 240;

const useStyles = makeStyles(theme =>
    createStyles({   
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            // this causes compilation problem - should be fixed in next mui release
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        alert: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
    }));


export const MainLayout: React.FC<Props> = props => {

    // @ts-ignore
    const classes = useStyles();

    let history = useHistory();

    const { logout } = useStoreActions(state => state.auth)
    const { clear: clearDevices } = useStoreActions(state => state.deviceFilter);
    const { isLogged, userName, role, isAdmin, isKzv, isExtern } = useStoreState(state => state.auth);    
    const { notification, isOpen } = useStoreState(state => state.audit);
    const { notify, close } = useStoreActions(state => state.audit);

    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleLogin = () => {
        clearDevices();
        history.push(routes.login);
    }

    const handleLogout = () => 
    {
        clearDevices();
        logout();
        history.push(routes.login);
    }

    const handleManageAccount = () => {
        history.push(routes.users);
    }

    const handleCloseAlert = (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason !== 'clickaway') {
            close();
        }
    }

    return (
        <div className={classes.root}>

            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: openDrawer,
                })}
            >
                <Toolbar>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: openDrawer,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>

                    <div className={classes.content}>
                        <Typography variant="h6">
                            KZV Service 
                            {process.env.REACT_APP_SHOW_ENV?  (`   ${process.env.NODE_ENV.toUpperCase()}:  ${process.env.REACT_APP_DEV_URL}`) : ''}
                        </Typography>

                        <Typography variant="caption">
                            {isLogged && (userName)}
                            {isLogged && (`/${role}`)}
                        </Typography>
                    </div>

                    {isLogged && (
                        <div>
                            <Tooltip title='Reported bugs'>
                                <IconButton>
                                    <Badge variant='standard' color='secondary' badgeContent=''>
                                        <BugReportIcon color='action' />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Button color="inherit" onClick={handleLogout}>Logout</Button>
                        </div>
                    )}

                    {!isLogged && (
                        <Button color="inherit" onClick={handleLogin} >Login</Button>
                    )}

                    {isLogged && (
                        <Tooltip title='Manage Account'>
                            <IconButton color="primary" onClick={handleManageAccount} >
                                <AccountCircle color='action' />
                            </IconButton>
                        </Tooltip>
                    )}

                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: openDrawer,
                    [classes.drawerClose]: !openDrawer,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: openDrawer,
                        [classes.drawerClose]: !openDrawer,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                      <ChevronLeftIcon />
                    </IconButton>
                </div>

                <List>

                    <Tooltip title='Home'>
                        <ListItem button key='menu_home' onClick={() => { history.push(routes.home); }} >
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary='Home/Search' />
                        </ListItem>
                    </Tooltip>

                    {isKzv && (
                        <>
                            <Tooltip title='Manage Software and Licenses'>
                                <ListItem button key='menu_software' onClick={() => history.push(routes.softwate)} >
                                    <ListItemIcon>
                                        <PhonelinkSetupOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Software / License' />
                                </ListItem>
                            </Tooltip>

                            <Tooltip title='Create Device'>
                                <ListItem button key='menu_create' onClick={() => history.push(routes.deviceCreate)} >
                                    <ListItemIcon>
                                        <AddIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Create Device' />
                                </ListItem>
                            </Tooltip>

                            <Tooltip title='Manage Device Components'>
                                <ListItem button key='menu_components' onClick={() => history.push(routes.components)} >
                                    <ListItemIcon>
                                        <SettingsInputComponentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Device Components' />
                                </ListItem>
                            </Tooltip>
                        </>
                    )}

                    {(isKzv || userName ==='vojtech.vigner@gmail.com') && (
                        <>
                            <Tooltip title='Manage VGN Records'>
                                <ListItem button key='menu_vgn_components' onClick={() => history.push(routes.vgnImport)} >
                                    <ListItemIcon>
                                        <DeveloperBoardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Manage VGN' />
                                </ListItem>
                            </Tooltip>

                            <Divider />
                        </>
                    )}

                    {isKzv && (

                        <Tooltip title='Manage Accounts'>
                            <ListItem button key='menu_users' onClick={() => history.push(routes.users)} >
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary='Manage Users' />
                            </ListItem>
                        </Tooltip>
                    )}

                </List>

            </Drawer>

            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div>
                    {(props.children)}
                </div>
            </main>

            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
                <div className={classes.alert}>
                    <Alert onClose={() => close()} severity={notification.severity}>
                        {notification?.message}
                    </Alert>
                </div>
            </Snackbar>
        </div >
    );
}

export default MainLayout;