import { Action, action, Thunk, thunk } from 'easy-peasy';
import { StoreContext } from '.';
import { Injections } from '../store';
import { ComponentDto, ComponentTypeDto, ComponentPrototypeDto, CreateComponentPrototypeDto, CreateComponentTypeDto, DeviceTypeDto, CreateDeviceTypeDto, CreateComponentDto } from '../service/dataContract'
import { StoreMode, storeValue } from '../utils/storageUtils';

// device store interface
export interface DeviceComponentState {

    // loading indication
    isLoading: boolean,

    // all possible device types
    deviceTypes: DeviceTypeDto[],

    // all possible component types with category
    componentTypes: ComponentTypeDto[],

    // all possible component prototypes
    componentPrototypes: ComponentPrototypeDto[],

    // thunks definitions

    // fetches all model data
    fetchPrototypes: Thunk<DeviceComponentState, void, Injections, StoreContext>,

    // creates a new component prototype 
    addComponentPrototype: Thunk<DeviceComponentState, { prototype: CreateComponentPrototypeDto }, Injections, StoreContext>,

    // updates an existing component prototype 
    updateComponentPrototype: Thunk<DeviceComponentState, { prototypeId: string, prototype: CreateComponentPrototypeDto }, Injections, StoreContext>,

    // deletes an existing component prototype 
    deleteComponentPrototype: Thunk<DeviceComponentState, { prototypeId: string }, Injections, StoreContext>,



    // creates a new component type 
    addComponentType: Thunk<DeviceComponentState, { componentType: CreateComponentTypeDto }, Injections, StoreContext>,

    // updates an existing component type 
    updateComponentType: Thunk<DeviceComponentState, { typeId: string, componentType: CreateComponentTypeDto }, Injections, StoreContext>,

    // deletes an existing component type 
    deleteComponentType: Thunk<DeviceComponentState, { typeId: string }, Injections, StoreContext>,



    // creates a new device type 
    addDeviceType: Thunk<DeviceComponentState, { deviceType: CreateDeviceTypeDto }, Injections, StoreContext>,

    // updates an existing device type 
    updateDeviceType: Thunk<DeviceComponentState, { typeId: string, deviceType: CreateDeviceTypeDto }, Injections, StoreContext>,

    // deletes an existing device type 
    deleteDeviceType: Thunk<DeviceComponentState, { typeId: string }, Injections, StoreContext>,

    // action definitions

    // stores component prototypes
    storeDeviceType: Action<DeviceComponentState, { mode: StoreMode, deviceType?: DeviceTypeDto, typeId?: string }>,
    storeDeviceTypeList: Action<DeviceComponentState, { deviceTypes: DeviceTypeDto[] }>,

    // stores component prototypes
    storeComponentPrototype: Action<DeviceComponentState, { mode: StoreMode, prototype?: ComponentPrototypeDto, prototypeId?: string }>,
    storeComponentPrototypeList: Action<DeviceComponentState, { prototypes: ComponentPrototypeDto[] }>,

    // stores component types
    storeComponentType: Action<DeviceComponentState, { mode: StoreMode, type?: ComponentTypeDto, typeId?: string }>,
    storeComponentTypeList: Action<DeviceComponentState, { types: ComponentTypeDto[] }>,

    // set loading flag action
    setLoading: Action<DeviceComponentState, boolean>,
};

const componentState: DeviceComponentState = {

    isLoading: false,

    deviceTypes: [],
    componentTypes: [],
    componentPrototypes: [],

    // thunks

    // fetches all component prototypes / types
    fetchPrototypes: thunk(async (actions, payload, { injections }) => {

        const { componentPrototypeClient, componentTypeClient, deviceTypeClient } = injections;

        actions.setLoading(true);

        const protoPromise = componentPrototypeClient.fetch()
            .then(response => {
                actions.storeComponentPrototypeList({ prototypes: response });
            });

        const typePromise = componentTypeClient.fetch()
            .then(response => {
                actions.storeComponentTypeList({ types: response });
            });

        const deviceTypePromise = deviceTypeClient.fetch()
            .then(response => {
                actions.storeDeviceTypeList({ deviceTypes: response });
            });

        await Promise.all([protoPromise, typePromise, deviceTypePromise]);

        actions.setLoading(false);
    }),

    // prototype thunks

    addComponentPrototype: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { componentPrototypeClient } = injections;

        actions.setLoading(true);

        await componentPrototypeClient.create(payload)
            .then(response => {
                actions.storeComponentPrototype({ mode: 'insert', prototype: response });
                getStoreActions().audit.notify({ severity: 'info', message: `Component prototype has been sucessfully created.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create component prototype: ${payload.prototype.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    updateComponentPrototype: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { componentPrototypeClient } = injections;

        actions.setLoading(true);

        await componentPrototypeClient.update(payload)
            .then(response => {
                actions.storeComponentPrototype({ mode: 'update', prototype: response });
                getStoreActions().audit.notify({ severity: 'info', message: `Component prototype has been sucessfully updated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update component prototype: ${payload.prototype.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    deleteComponentPrototype: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { componentPrototypeClient } = injections;

        actions.setLoading(true);

        await componentPrototypeClient.delete(payload)
            .then(response => {
                actions.storeComponentPrototype({ mode: 'delete', prototypeId: payload.prototypeId });
                getStoreActions().audit.notify({ severity: 'info', message: `Component prototype has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete component prototype.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    // component type thunks
    addComponentType: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { componentTypeClient } = injections;

        actions.setLoading(true);

        await componentTypeClient.create(payload)
            .then(response => {
                actions.storeComponentType({ mode: 'insert', type: response });
                getStoreActions().audit.notify({ severity: 'info', message: `Component type has been sucessfully created.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create component type: ${payload.componentType.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    updateComponentType: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { componentTypeClient } = injections;
        const { fetchPrototypes } = getStoreActions().component;
        actions.setLoading(true);

        await componentTypeClient.update(payload)
            .then(response => {
                actions.storeComponentType({ mode: 'update', type: response });
                getStoreActions().audit.notify({ severity: 'info', message: `Component type has been sucessfully updated.` })
                fetchPrototypes();
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update component type: ${payload.componentType.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    deleteComponentType: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { componentTypeClient } = injections;
        actions.setLoading(true);

        await componentTypeClient.delete(payload)
            .then(() => {
                actions.storeComponentType({ mode: 'delete', typeId: payload.typeId });
                getStoreActions().audit.notify({ severity: 'info', message: `Component type has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete component type.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    // device type thunks
    addDeviceType: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceTypeClient } = injections;
        actions.setLoading(true);

        await deviceTypeClient.create(payload)
            .then(response => {
                actions.storeDeviceType({ mode: 'insert', deviceType: response });
                getStoreActions().audit.notify({ severity: 'info', message: `Device type has been sucessfully created.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create device type: ${payload.deviceType.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    updateDeviceType: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceTypeClient } = injections;
        const { fetchPrototypes } = getStoreActions().component;
        actions.setLoading(true);

        await deviceTypeClient.update(payload)
            .then(response => {
                actions.storeDeviceType({ mode: 'update', deviceType: response });
                getStoreActions().audit.notify({ severity: 'info', message: `Device type has been sucessfully updated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update device type: ${payload.deviceType.name}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    deleteDeviceType: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceTypeClient } = injections;
        actions.setLoading(true);

        await deviceTypeClient.delete(payload)
            .then(() => {
                actions.storeDeviceType({ mode: 'delete', typeId: payload.typeId });
                getStoreActions().audit.notify({ severity: 'info', message: `Device type has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete device type.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    // device type actions
    storeDeviceType: action((store, payload) => {
        store.deviceTypes = storeValue(store.deviceTypes, 'id', { mode: payload.mode, id: payload.typeId, item: payload.deviceType });
    }),

    storeDeviceTypeList: action((store, payload) => {
        store.deviceTypes = payload.deviceTypes;
    }),

    // prototype actions
    storeComponentPrototype: action((store, payload) => {
        store.componentPrototypes = storeValue(store.componentPrototypes, 'id', { mode: payload.mode, id: payload.prototypeId, item: payload.prototype });
    }),

    storeComponentPrototypeList: action((store, payload) => {
        store.componentPrototypes = payload.prototypes;
    }),

    // component type  actions
    storeComponentType: action((store, payload) => {
        store.componentTypes = storeValue(store.componentTypes, 'id', { mode: payload.mode, id: payload.typeId, item: payload.type });
    }),

    storeComponentTypeList: action((store, payload) => {
        store.componentTypes = payload.types;
    }),

    // loading actions
    setLoading: action((store, payload) => { store.isLoading = payload }),

}


export default componentState;
