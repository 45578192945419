import React, { useEffect } from 'react';

// mui
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            },     
    }),
);

interface Props { }

export const NotFound: React.FC<Props> = props => {

    const classes = useStyles();

    return (

        <div className={classes.root}>
            <img src='/err404.png' />
            <Typography variant='h6'>Page not found 404</Typography>            
        </div>);
}

export default NotFound;
