// dto
import { ComponentDto, KeyValuesDto } from '../service/dataContract';
import { formatDate } from './datetime';
import { isType } from './typeUtils';

const splitPattern: RegExp = /\[(.*?)\]/

export type ParameterDict = { [key: string]: string };

// parameter
export interface Parameter {
    id: number,
    key: string,
    value: string,

    keyUnit?: string,
    keyName?: string,
}

export function parseParameter(id: number, key: string, value: string): Parameter {
    const splits = key.split(splitPattern);
    return splits.length == 3
        ? { id: id, key: key, value: value, keyUnit: splits[1], keyName: splits[0] }
        : { id: id, key: key, value: value }
}

// gets the parameter list from source
export function enumerateParameters(keyValues?: KeyValuesDto) {

    // get params
    let params = keyValues?.keyValues ?? {};
    let list: Parameter[] = [];

    // enumerate list
    Object.entries(params).map(([key, value], id) => {
        list.push(parseParameter(id, key, value));
    })

    return list;
}

// builds dictionary object from parameter list.
export function buildDictionary(list: Parameter[]): KeyValuesDto {

    let value: ParameterDict = {};
    list.map(param => {
        value[param.key] = param.value;
    })

    return { keyValues: value };
}


// checks if copmpnent params are templated or not
export function getIsTemplate(component: ComponentDto) {
    return component.componentPrototype?.isTemplate ?? false;
}

// checks if copmpnent params are of VGN type
export function getIsVgn(component: ComponentDto) {
    return component.componentPrototype?.isVgn ?? false;
}

// ges the params dictionary by template flag
export function getParamsDictionary(component: ComponentDto) {

    const isTemplate = getIsTemplate(component)

    // get payload for template or prototype data if payload is not set
    return isTemplate && component.payload !== null
        ? component.payload
        : component.componentPrototype?.payload;
}

// ges the params dictionary for VGN
export function getVgnParamsDictionary(component: ComponentDto): KeyValuesDto {

    let params: ParameterDict = {};

    params["Device Name"] = component.vgnPayload?.deviceName ?? 'N/A';
    params["Device Version"] = component.vgnPayload?.deviceVersion ?? 'N/A';
    params["Device Assembly"] = component.vgnPayload?.deviceAssembly ?? 'N/A';
    params["Firmware Version"] = component.vgnPayload?.firmwareVersion ?? 'N/A';
    params["Device Expansion"] = component.vgnPayload?.deviceExpansion ?? 'N/A';
    params["Serial Number"] = component.vgnPayload?.serialNumber ?? 'N/A';
    params["Manufacture Date"] = formatDate(component.vgnPayload?.manufactureDate);
    params["Comment"] = component.vgnPayload?.comment ?? '';

    return { keyValues: params }
}

export function enumerateComponentParams(
    component: ComponentDto,
    options?: {
        excludeParams?: string[],
        includeVgn?: boolean
    }
): Parameter[] {

    let vgnParams = options?.includeVgn && getIsVgn(component)
        ? enumerateParameters(getVgnParamsDictionary(component))
        : [];

    let params = enumerateParameters(getParamsDictionary(component));

    return [...params, ...vgnParams];
}
