import { Action, action, } from 'easy-peasy';

export type Severity = "info" | "success" | "error" | "warning" | undefined;

const consoleLog = (payload: { severity: Severity, message: string, payload?: object }) => {
    switch (payload.severity) {

        default:
        case 'info':
            console.log(`Audit ${payload.severity}: `, payload.message, payload.payload ?? '');
            break;

        case 'error':
            console.error(`Audit ${payload.severity}: `, payload.message, payload.payload ?? '');
            break;

        case 'warning':
            console.warn(`Audit ${payload.severity}: `, payload.message, payload.payload ?? '');
            break;
    }
}

export interface AuditLog {
    severity: Severity,
    message: string,
    payload?: object
}

// device store interface
export interface AuditModel {

    notification: AuditLog,
    isOpen: boolean,

    // audit log
    log: Action<AuditModel, { severity: Severity, message: string, payload?: object }>;

    // audit notofication
    notify: Action<AuditModel, { severity: Severity, message: string, payload?: object }>;
    close: Action<AuditModel, void>;

};

const auditModel: AuditModel = {

    notification: { severity: undefined, message: '' },
    isOpen: false,

    log: action((store, payload) => {
        consoleLog(payload);
    }),

    notify: action((store, payload) => {
        store.notification = { ...payload };
        store.isOpen = true;
        consoleLog(payload);
    }),

    close: action((store, payload) => {
        store.isOpen = false;
    }),
}

export default auditModel;
