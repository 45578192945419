// store mode
export type StoreMode = 'insert' | 'update' | 'delete' | 'merge';

// store value into an array by key
export function storeValue<T, K extends keyof T>(
    source: T[],
    key: K,
    payload: { mode: StoreMode, item?: T, id?: T[K] }): T[] {

    switch (payload.mode) {

        case 'insert':
            return (payload.item)
                ? [payload.item, ...source]
                : source;

        case 'update':
            if (payload.item) {
                const item = payload.item;
                const index = source.findIndex(p => p[key] === item[key]);
                if (index >= 0) {                    
                    const target = source[index]?? {};
                    Object.assign(target, payload.item);
                }
            }
            return source;

        case 'merge':
            if (payload.item) {
                const item = payload.item;
                const index = source.findIndex(p => p[key] === item[key]);
                if (index >= 0) {            
                    const target = source[index]?? {};        
                    Object.assign(target, payload.item);
                }
                else {
                   source = [payload.item, ...source]
                }
            }

            return source;

        case 'delete':
            return source.filter(p => p[key] !== payload.id);
    }
}
