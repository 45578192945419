import React, { useEffect, useState } from 'react';

// mui
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Badge, Button, Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';

// utils
import { formatDate, formatDateTime } from '../../utils/datetime';

// dto
import { ChecklistPayloadKeys } from '../../service/dataContract';
import { useStoreActions, useStoreState } from '../../hooks';
import { isNullOrWhitespace } from '../../utils/typeUtils';


// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        verticalContent: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        horizontalContent: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
        },
        margin: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
        },
        separator: {
            marginTop: theme.spacing(3),
        },
        actions: {
            marginRight: theme.spacing(2),
        },
        heading: {
            flexBasis: '33%',
            flexShrink: 0,
        },
    }),
);

export interface Props {
    readonly: boolean,
}


type StateType = { [key in ChecklistPayloadKeys]: any }
interface State extends StateType { }


export const ChecklistCard: React.FC<Props> = (props) => {

    const classes = useStyles();

    const { updateDevice } = useStoreActions(state => state.device);
    const { deviceDetail, isLoading } = useStoreState(state => state.device);
    const { isAdmin, userName } = useStoreState(state => state.auth);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [changed, setChanged] = useState(false);
    const [state, setState] = useState<State>({
        smoothWheelsTurning: false,
        correctWheelsRamp: false,
        smoothMechanicsGauge: false,
        spreadingForceValue: 0,
        smoothMechanicsAlign: false,
        smoothMechanicsTop: false,
        sensorsTightening: false,
        sensorsInMeasuredRanges: false,
        testedInTrack: false,
        notes: '',
        technicianName: '',
        completed: false,
        completedDate: '',
    });

    useEffect(() => {

        const values = deviceDetail?.checklist?.keyValues;

        setState({
            smoothWheelsTurning: values?.smoothWheelsTurning ?? false,
            correctWheelsRamp: values?.correctWheelsRamp ?? false,
            smoothMechanicsGauge: values?.smoothMechanicsGauge ?? false,
            spreadingForceValue: values?.spreadingForceValue ?? 0,
            smoothMechanicsAlign: values?.smoothMechanicsAlign ?? false,
            smoothMechanicsTop: values?.smoothMechanicsTop ?? false,
            sensorsTightening: values?.sensorsTightening ?? false,
            sensorsInMeasuredRanges: values?.sensorsInMeasuredRanges ?? false,
            testedInTrack: values?.testedInTrack ?? false,
            notes: values?.notes ?? '',
            technicianName: values?.technicianName ?? '',
            completed: values?.completed ?? false,
            completedDate: values?.completedDate ?? ''
        });
    }, [deviceDetail])

    const handleExpandedChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleValueInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [prop]: event.target.value });
        setChanged(true);
    };

    const handleChecledInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.readOnly) {
            setState({ ...state, [prop]: event.target.checked });
            setChanged(true);
        }
    };


    const isChecklistDone = () => !isNullOrWhitespace(state.technicianName);

    const handleSetUnfinished = () => {
        state.technicianName = "";
        state.completedDate = "";
        state.completed = false;
        handleChecklistSave(false);
    }

    const handleChecklistSave = (setDone: boolean) => {


        if (setDone) {
            state.technicianName = userName;
            state.completedDate = formatDateTime(new Date());
        }

        updateDevice({
            deviceId: deviceDetail?.id ?? '',
            device: {
                checklist: {
                    keyValues: {
                        ...state, completed: isChecklistDone()
                    }
                }
            },
        });

        setChanged(false);
    }
    return (
        <div className={classes.root}>

            <Accordion
                expanded={expanded === 'cardpanel'}
                onChange={handleExpandedChange('cardpanel')}>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="cardpanel-header">

                    <Typography
                        display='inline'
                        variant='h6'
                        className={classes.heading}>
                        Checklist:
                    </Typography>

                    {deviceDetail?.checklist == null && (
                        <Chip
                            size="small"
                            color="primary"
                            variant='outlined'
                            label="Undefined"
                            icon={<ClearIcon />} />
                    )}

                    {deviceDetail?.checklist != null && (
                        <Chip
                            size="small"
                            color={state.completed ? "primary" : "secondary"}
                            variant='outlined'
                            label={state.completed ? "Done " + formatDate(state.completedDate) + " by " + state.technicianName  : "Unfinished" }
                            icon={state.completed ? <DoneIcon /> : <ClearIcon />}
                        />
                    )}


                </AccordionSummary>

                <AccordionDetails>

                    <div className={classes.form}>

                        <div className={classes.horizontalContent}>

                            <div className={classes.verticalContent}>

                                <FormControlLabel
                                    label="Hladké otáčení plášťů a okolků"
                                    control={
                                        <Checkbox
                                            readOnly={isChecklistDone()}
                                            checked={state.smoothWheelsTurning}
                                            onChange={handleChecledInputChange('smoothWheelsTurning')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Nabíhavost kol"
                                    control={
                                        <Checkbox
                                            readOnly={isChecklistDone()}    
                                            checked={state.correctWheelsRamp}
                                            onChange={handleChecledInputChange('correctWheelsRamp')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Hladký chod mechaniky rozchodu"
                                    control={
                                        <Checkbox
                                            readOnly={isChecklistDone()}
                                            checked={state.smoothMechanicsGauge}
                                            onChange={handleChecledInputChange('smoothMechanicsGauge')} />
                                    }
                                />

                                <TextField
                                    value={state.spreadingForceValue}
                                    type='number'                                    
                                    onChange={handleValueInputChange('spreadingForceValue')}
                                    InputProps={{
                                        readOnly: isChecklistDone(),
                                        startAdornment: <InputAdornment position="start">Nastavená síla rozpírání:</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">N</InputAdornment>,
                                    }} />

                            </div>

                            <div className={classes.verticalContent}>

                                <FormControlLabel
                                    label="Hladký chod mechaniky směru"
                                    control={
                                        <Checkbox
                                        readOnly={isChecklistDone()}
                                            checked={state.smoothMechanicsAlign}
                                            onChange={handleChecledInputChange('smoothMechanicsAlign')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Hladký chod mechaniky výšky"
                                    control={
                                        <Checkbox
                                            readOnly={isChecklistDone()}
                                            checked={state.smoothMechanicsTop}
                                            onChange={handleChecledInputChange('smoothMechanicsTop')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Upěvnění snímačů"
                                    control={
                                        <Checkbox
                                            readOnly={isChecklistDone()}
                                            checked={state.sensorsTightening}
                                            onChange={handleChecledInputChange('sensorsTightening')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Rozsah snímačů v měřícím rozsahu"
                                    control={
                                        <Checkbox
                                            readOnly={isChecklistDone()}
                                            checked={state.sensorsInMeasuredRanges}
                                            onChange={handleChecledInputChange('sensorsInMeasuredRanges')} />
                                    }
                                />

                            </div>

                            <div className={classes.verticalContent}>

                                <FormControlLabel
                                    label="Testováno v trati"
                                    control={
                                        <Checkbox
                                            readOnly={isChecklistDone()}
                                            checked={state.testedInTrack}
                                            onChange={handleChecledInputChange('testedInTrack')} />
                                    }
                                />

                                <div className={classes.separator} />
                                <TextField
                                    value={state.technicianName}                                    
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start">Podpis zodpovědného technika:</InputAdornment>,
                                        endAdornment: <InputAdornment position='end' >
                                            { isAdmin && (<IconButton color="secondary" onClick={()=>handleSetUnfinished()}><DeleteIcon /></IconButton>)}
                                        </InputAdornment>
                                    }} />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isChecklistDone()}
                                    onClick={() => handleChecklistSave(true)}>
                                    {isChecklistDone() ?  "Podepsáno: " + formatDate(state.completedDate) : "Podepsat"}
                                </Button>
                            </div>

                        </div>

                        <div className={classes.verticalContent}>

                            <div className={classes.separator} />

                            <TextField
                                label="Poznámky:"
                                multiline
                                variant='outlined'
                                value={state.notes ?? ''}
                                onChange={handleValueInputChange('notes')}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: isChecklistDone(), }}
                            />
                        </div>
                    </div>

                </AccordionDetails>

                {!props.readonly && (
                    <AccordionActions>
                        <div className={classes.actions}>
                            <Button
                                autoFocus
                                color='primary'
                                startIcon={<DoneIcon />}
                                disabled={!changed}
                                onClick={() => handleChecklistSave(false)}>
                                Save
                            </Button>
                        </div>
                    </AccordionActions>
                )}

            </Accordion>


        </div >
    )
}

export default ChecklistCard;