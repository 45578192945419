import React, { useEffect } from 'react';
import { useHistory, RouteComponentProps } from 'react-router';
import email from '../../service/emailClient';
// store
import { useStoreState, useStoreActions } from '../../hooks'
import routes from '../../routes';

// mui
import { Box, Button, Fab, IconButton, Toolbar, Tooltip } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';

// components
import ConfirmDialog from '../ConfirmDialog';
import LicenseRequestDialog from '../software/LicenseRequestDialog';
import { EmailMessageDto, EntityState } from '../../service/dataContract';
import { getLookupKey } from '../../model/codelist';
import { env } from 'process';

const useStyles = makeStyles((theme: Theme) => createStyles({
    toolbar: {
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'right',
    },
    navButton: {
    },
    toolButton: {
        margin: theme.spacing(0, 0, 0, 2),
    }
}));

interface Props {
}

export const DeviceToolbar: React.FC<Props> = props => {

    const classes = useStyles();
    let history = useHistory();

    const { deviceDetail } = useStoreState(state => state.device);
    const { notify } = useStoreActions(state => state.audit);
    const { deleteDevice, restoreDevice } = useStoreActions(state => state.device);
    const { isExtern, isKzv, isAdmin, isOwner, userName } = useStoreState(state => state.auth);

    // todo: replace by new routes
    const isDeviceDetail = history.location.pathname.match('/detail') !== null;
    const isDeviceService = history.location.pathname.match('/service') !== null;
    const isActive = deviceDetail?.systemState === EntityState.Active;

    const [deleteDialogOpen, setDeleteDevOpen] = React.useState(false);
    const [licenseDialogOpen, setLicenseDialogOpen] = React.useState(false);

    const handleDeviceDelete = () => {
        deleteDevice({deviceId: deviceDetail?.id ?? ''})
        .then(() => {
            history.push(routes.home);
        })
    }

    const handleDeviceRestore = () => {
        restoreDevice({deviceId: deviceDetail?.id ?? ''})
        .then(() => {
            window.location.reload();
        })
    }

    const handleSendLicenseRequest = (payload: { isNew: boolean, message: string }) => {

        // close license dialog
        setLicenseDialogOpen(false);

        const fromEmail = 'service@kzv.cz';
        const toEmail = process.env.NODE_ENV === 'development'?  'l.bellada@gmail.com' : 'josef.dodek@centrum.cz';
        const subject = `Krab License Request (Device SN: ${deviceDetail?.serialNumber ?? 'N/A'})`;
        const deviceLink = `<a href=${window.location.href}>Open Here</a>`;
        const deviceSN = `<strong>Device SN: ${deviceDetail?.serialNumber ?? 'N/A'}</strong>`;
        const licenseType = `License Type: <strong>${payload.isNew? "New" : "Upgrade"}</strong>`;
        const notes = `Notes: ${payload.message?? 'N/A'}`;
        
        // message for license issuer
        const message: EmailMessageDto = {
            from: fromEmail,
            to: toEmail,
            subject: subject,
            htmlBody: `License is requested for ${deviceSN} by ${userName}<br>${licenseType}<br>${notes}<br>${deviceLink}<br>`
        };

        // message for license requestor
        const confirmMessage: EmailMessageDto = {
            from: fromEmail,
            to: userName ?? '',
            subject: subject,
            htmlBody: `License for ${deviceSN} has been requested by you.<br>${licenseType}<br>${notes}<br>${deviceLink}`
        };

        email.send({ message })
            .then(_ => {
                notify({ severity: 'success', message: `License request has been successfully send to: ${message.to}.` });
                email.send({ message: confirmMessage });
            })
            .catch(err => {
                notify({ severity: 'error', message: 'Unable to send license request.', payload: err })
            });
    }

    return (
        <Toolbar className={classes.toolbar}>

            <ConfirmDialog
                isOpen={deleteDialogOpen}
                title='Delete Device'
                message='Do you really want to (SOFT) delete this device?'
                onCancel={() => { setDeleteDevOpen(false) }}
                onConfirm={() => { handleDeviceDelete() }}>
            </ConfirmDialog>

            <LicenseRequestDialog
                isOpen={licenseDialogOpen}
                title={`Request License (SN: ${deviceDetail?.serialNumber})`}
                message='Submit a License Request for this device?'
                onCancel={() => { setLicenseDialogOpen(false) }}
                onConfirm={handleSendLicenseRequest}>
            </LicenseRequestDialog>

            <Box flexGrow={1}>

                {isDeviceDetail && (
                    <Button
                        className={classes.navButton}
                        color='primary'
                        onClick={() => { history.push(routes.deviceService.formatPath(deviceDetail?.id ?? '')) }}
                        startIcon={<BuildIcon />}>
                        To Service
                    </Button>)}

                {isDeviceService && (
                    <Button
                        className={classes.navButton}
                        size='small'
                        color='primary'
                        onClick={() => { history.push(routes.deviceDetail.formatPath(deviceDetail?.id ?? '')) }}
                        startIcon={<SettingsIcon />}>
                        To Detail
                    </Button>)}
            </Box>

            <Box alignContent='right'>

                {isDeviceDetail && (
                    <Button
                        className={classes.toolButton}
                        variant='contained'
                        color='primary'
                        onClick={() => { setLicenseDialogOpen(true) }}
                        startIcon={<EmailIcon />}>
                        License Request
                    </Button>)}

                {isKzv && (
                    <Button
                        className={classes.toolButton}
                        variant='contained'
                        color='primary'
                        startIcon={<ControlPointDuplicateIcon />}
                        onClick={() => { history.push(routes.deviceClone.formatPath(deviceDetail?.id ?? '')) }}>
                        Clone
                    </Button>)}


                    {isAdmin && isActive && (
                    <Button
                        className={classes.toolButton}
                        variant='contained'
                        color='secondary'
                        startIcon={<DeleteIcon />}
                        onClick={() => { setDeleteDevOpen(true) }}>
                        Discard
                    </Button>)}

                    {isAdmin && !isActive && (
                    <Button
                        className={classes.toolButton}
                        variant='contained'
                        color='secondary'
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeviceRestore()}>
                        Restore
                    </Button>)}

            </Box>
        </Toolbar >
    )
}

export default DeviceToolbar;