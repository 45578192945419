import { http } from './httpClient';
import { ComponentPrototypeDto, CreateComponentPrototypeDto } from './dataContract'

const baseRoute = '/devices/components/prototypes';

const routes = {
    fetch: baseRoute,
    create: baseRoute,
    update: (prototypeId: string) => `${baseRoute}/${prototypeId}`,
    delete: (prototypeId: string) => `${baseRoute}/${prototypeId}`,
}

export interface ComponentPrototypeClient {
    fetch(): Promise<ComponentPrototypeDto[]>,
    create(payload: { prototype: CreateComponentPrototypeDto }): Promise<ComponentPrototypeDto>,
    update(paylaod: { prototypeId: string, prototype: CreateComponentPrototypeDto }): Promise<ComponentPrototypeDto>,
    delete(payload: { prototypeId: string }): Promise<boolean>,
}

const componentPrototypeClient: ComponentPrototypeClient = {
    fetch: () => http.get(routes.fetch),
    create: (payload) => http.post(routes.create, payload.prototype),
    update: (payload) => http.put(routes.update(payload.prototypeId), payload.prototype),
    delete: (payload) => http.delete(routes.delete(payload.prototypeId)),
}

export default componentPrototypeClient;